import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Component({
  selector:    'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles:      [],
})
export class ForgotPasswordComponent implements OnInit {
  public form: UntypedFormGroup;
  public sendingRequest = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public translateService: TranslateService,
    public authService: AuthService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      id_card: [null, [Validators.required]],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    this.sendingRequest = true;
    this.authService.forgotPassword(form.value)
      .pipe(finalize(() => this.sendingRequest = false))
      .subscribe(
        res => {
          this.router.navigateByUrl('/');
          this.toastr.success(this.translateService.instant('AUTH.email-sent'));
        },
        err => {
          const errorMessage = err.error?.errors?.email || this.translateService.instant('SHARED.went-wrong');
          this.toastr.error(errorMessage);
        },
      );
  }
}
