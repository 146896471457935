<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1>{{ 'DISTRIBUTION.provider.list.heading' | translate }}</h1>
    </mat-card-title>
    <div class="header-actions">
      <a mat-raised-button color="primary" [routerLink]="['/distribution', 'providers', 'create']">
        + {{ 'SHARED.create' | translate }}
      </a>
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    <!--<mat-expansion-panel class="mb-3">-->
    <!--  <mat-expansion-panel-header>-->
    <!--    <mat-panel-title>-->
    <!--      {{ "PAYMENTS.advanced-filters" | translate }}-->
    <!--    </mat-panel-title>-->
    <!--  </mat-expansion-panel-header>-->
    <!--  <div class="row">-->
    <!--  &lt;!&ndash; TODO &ndash;&gt;-->
    <!--  </div>-->
    <!--</mat-expansion-panel>-->
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <!-- Table List -->
        <mat-table [dataSource]="distributionProviders" matSort (matSortChange)="sortData($event)">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!-- Company Name -->
          <ng-container matColumnDef="company_name">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.provider.model.company_name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.company_name }} </td>
          </ng-container>
          <!-- Min Contract Value -->
          <ng-container matColumnDef="min_contract_value">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.provider.model.min_contract_value' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.min_contract_value }} </td>
          </ng-container>
          <!-- Min monthly payment -->
          <ng-container matColumnDef="min_monthly_payment">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.provider.model.min_monthly_payment' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.min_monthly_payment }} </td>
          </ng-container>
          <!-- Max installments -->
          <ng-container matColumnDef="max_installments">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.provider.model.max_installments' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.max_installments }} </td>
          </ng-container>
          <!-- Funding amount percentage -->
          <ng-container matColumnDef="funding_amount_percentage">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.provider.model.funding_amount_percentage' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.funding_amount_percentage }} </td>
          </ng-container>
          <!-- Cash hurdle percentage -->
          <ng-container matColumnDef="cash_hurdle_percentage">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.provider.model.cash_hurdle_percentage' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.cash_hurdle_percentage }} </td>
          </ng-container>
          <!-- Actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">{{ "SHARED.actions" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="pt-2 pb-2" style="display: flex">
                <!--<a mat-stroked-button [routerLink]="[element.id]">-->
                <!--  {{ 'SHARED.view' | translate }}-->
                <!--</a>-->
                <a class="ml-3" mat-stroked-button [routerLink]="[element.id, 'edit']">
                  {{ 'SHARED.edit' | translate }}
                </a>
              </div>
            </td>
          </ng-container>
        </mat-table>
        <!-- No Data -->
        <div *ngIf="isLoading < 1 && distributionProviders && distributionProviders.data.length < 1"
             style="padding: 50px">
          <h3 class="text-center">{{ 'SHARED.no_data' | translate:{models: 'DISTRIBUTION.provider.model_name.plural' | translate} }}</h3>
        </div>
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div class="footer-actions ml-3"></div>
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                       [pageSizeOptions]="[5, 10, 20]" [pageIndex]="paginatorConfig.pageIndex"
                       (page)="paginatorChange($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>
