import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector:    'app-dropdown',
  templateUrl: './app-dropdown.component.html',
  styleUrls:   ['./app-dropdown.component.scss'],
})
export class AppDropdownComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('customDropdown', {static: true}) customDropdown: MatSelect;
  //  Input values
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() flexClass: string;

  @Input() data;
  @Input() isMultiple           = false;
  @Input() showRemoveButton     = true;
  @Input() selectAll            = false;
  @Input() matOptionValue       = 'id'; // Optional attr to use on mat-option value property
  @Input() matOptionLabel       = 'name'; // Optional attr to use on mat-option label property
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() parentFormControl: UntypedFormControl;
  @Input() parentFormControlName: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() showLabel            = true;
  @Input() showExtraLabel       = false;
  //  Option group values
  @Input() optionGroup          = false;
  @Input() optionGroupData      = 'statuses';
  @Input() optionGroupLabel     = 'name';
  @Input() optionGroupDataLabel = 'name';
  @Input() optionGroupDataValue = 'id';

  @Output() selectChange                  = new EventEmitter<MatSelectChange>();
  @Output() clearSelect                   = new EventEmitter<MouseEvent>();
  //  Data used in dropdown
  public filteredData: ReplaySubject<any> = new ReplaySubject<any>(1);
  //  Search input control
  public filterCtrl: UntypedFormControl          = new UntypedFormControl();
  public selectAllForm: UntypedFormControl       = new UntypedFormControl(false);

  protected _onDestroy = new Subject<void>();

  constructor(public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.filterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterOptions();
        });

    this.selectAllForm.valueChanges
        .subscribe((next) => {
          this.toggleSelectAll(next);
        });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.filteredData.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //  Update filteredData when Input `data` changes
    if (changes.data?.currentValue) {
      this.filteredData.next(changes.data.currentValue);
    }
  }

  toggleSelectAll(value: boolean) {
    //  Get data from `ReplaySubject`
    let data = [];
    this.filteredData.pipe(take(1)).subscribe(next => {
      data = next;
    });

    const newData = []; //  Used for option groups
    if (this.optionGroup) { //  If there are option groups
      data.map(obj => {
        obj[this.optionGroupData].map(stat => {
          newData.push(stat[this.matOptionValue]);
        });
      });
    }

    if (value) {  // If checkbox is checked
      if (this.parentFormGroup) { //  Check if `formGroup` or `formControl` is passed to component
        if (this.optionGroup) { //  If there are option groups
          //  Set new data
          this.parentFormGroup.get(this.parentFormControlName).patchValue([...newData]);
          if (this.selectChange) { // Trigger on change function if there is one
            this.onSelectionChange({value: [...newData]});
          }
        } else {  //  If there is no option group
          //  Set new data
          this.parentFormGroup.get(this.parentFormControlName)
              .patchValue([...data.map(obj => obj[this.optionGroupDataValue])]);
          if (this.selectChange) { // Trigger on change function if there is one
            this.onSelectionChange({value: [...data.map(obj => obj[this.optionGroupDataValue])]});
          }
        }
      } else {
        if (this.optionGroup) { //  If there are option groups
          this.parentFormControl.patchValue([...newData]);
          if (this.selectChange) { // Trigger on change function if there is one
            this.onSelectionChange({value: [...newData]});
          }
        } else {
          this.parentFormControl.patchValue([...data.map(obj => obj[this.optionGroupDataValue])]);
          if (this.selectChange) { // Trigger on change function if there is one
            this.onSelectionChange({value: [...data.map(obj => obj[this.optionGroupDataValue])]});
          }
        }
      }
    } else {
      this.resetSelection();
      if (this.selectChange) { // Trigger on change function if there is one
        this.onSelectionChange({value: []});
      }
    }
  }

  resetSelection() {
    if (this.parentFormGroup) {
      this.parentFormGroup.get(this.parentFormControlName).patchValue([]);
    } else {
      this.parentFormControl.patchValue([]);
    }
  }

  onSelectionChange($event) {
    this.selectChange.emit($event);
  }

  onClearSelect($event: MouseEvent, formControl) {
    $event.stopPropagation();

    if (this.selectAll) { //  Uncheck select all checkbox
      this.selectAllForm.patchValue(false);
    }
    //  Check if output property `clearSelect` is not passed
    if (!this.clearSelect.observers.length) { // If it is not passed clear form control
      formControl.patchValue('');
    }

    // Emit clear select
    this.clearSelect.emit($event);

  }

  protected filterOptions() {
    if (!this.data) {
      return;
    }
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filteredData.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter data
    if (this.optionGroup) { //  If there are option groups
      const filteredData = this.data.map(obj => {
        obj = Object.assign({}, obj); // Clone object so the original array stays intact
        //  Filter data
        const filter              = obj[this.optionGroupData].filter(child => {
          return child.name.toLowerCase().indexOf(search) > -1;
        });
        obj[this.optionGroupData] = filter;
        return obj;
      });
      //  Filter out empty arrays
      this.filteredData.next(filteredData.filter(obj => obj[this.optionGroupData].length > 0));
    } else {
      console.log(this.parentFormGroup);
      this.filteredData.next(
          this.data.filter(obj => obj.name.toLowerCase().indexOf(search) > -1),
      );
    }
  }

}
