import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { TaskService } from '../../task/task.service';

@Component({
  selector: 'app-case-dashboard',
  templateUrl: './case-dashboard.component.html',
  styles: [],
})
export class CaseDashboardComponent implements OnInit, OnDestroy {
  public authUser: User;
  public isLoading: number;
  public overdueTasks: number;
  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private globalEventsService: MainGlobalEventService,
              private taskService: TaskService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.fetchOverdueTasks();
    }));
    this.subscriptions.push(this.globalEventsService.overdueTasks$.subscribe(next => {
      this.overdueTasks = next;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public isActive(route: string): boolean {
    return this.router.isActive('case-dashboard/' + route, false);
  }

  private fetchOverdueTasks() {
    const requestData = {
      select_all: 1,
      only_count: 1,
      only_related: 1,
      time: 'overdue',
      with: ['case', 'assigned_users', 'assigned_departments'],
    };
    this.isLoading++;
    this.subscriptions.push(
      this.taskService.indexUserTasks(this.authUser.id, requestData).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.globalEventsService.setOverdueTasksCount(result.data?.length || 0),
        err => console.error(err),
      ),
    );
  }
}
