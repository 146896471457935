import { User } from '../User/User';
import { PaymentMethod } from './PaymentMethod';

export class BankAccount {
  id: number;
  payment_method_id: number;
  user_id: number;
  iban: string;
  account_number: string;
  bank_name: string;
  bank_address: string;
  default: boolean | 0 | 1;

  // Relations
  user?: User;
  payment_method?: PaymentMethod;
}
