import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector:    'app-custom-notification-toast',
  templateUrl: './custom-notification-toast.component.html',
  styleUrls:   ['custom-notification-toast.component.scss'],
  animations:  [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity:   0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity:   1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity:   1,
        }),
        style({
          transform: 'none',
          opacity:   1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity:   0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class CustomNotificationToastComponent extends Toast {
  @Input() customActions: Array<{ label: string, value: string }> = [];
  @Input() showCloseButton                                        = true;

  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  public action(event: Event, action: string) {
    event.stopPropagation();
    this.toastPackage.triggerAction(action);

    return false;
  }

  public closeToast() {
    this.remove();
  }
}
