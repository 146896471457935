<div class="container">
  <div *ngIf="isLoading" class="pt-4 pl-4">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
  <div *ngIf="serverResponse" class="row">
    <div class="col-12">
      <app-server-response [response]="serverResponse"></app-server-response>
    </div>
  </div>
  <div class="row">
    <div *ngIf="packagerSnapshotData?.general" class="col-12 mt-2">
      <app-general-snapshot-list [generalSnapshot]="packagerSnapshotData?.general"></app-general-snapshot-list>
    </div>
    <div *ngIf="packagerSnapshotData?.client" class="col-12 mt-2">
      <app-client-snapshot-list [clientSnapshot]="packagerSnapshotData?.client" [clientRole]="'client'"></app-client-snapshot-list>
    </div>
    <div *ngIf="case?.joint_application" class="col-12 mt-2">
      <app-client-snapshot-list [clientSnapshot]="packagerSnapshotData?.partner" [clientRole]="'partner'"></app-client-snapshot-list>
    </div>
    <div *ngIf="packagerSnapshotData?.creditors" class="col-12 mt-2">
      <app-creditor-snapshot-list [creditors]="packagerSnapshotData.creditors" [publicDebts]="packagerSnapshotData?.public_debts"></app-creditor-snapshot-list>
    </div>
    <div class="col-12 mb-3">
      <div class="row mt-2">
        <div class="col-6" *ngIf="packagerSnapshotData?.income">
          <app-incomes-snapshot-list [incomes]="packagerSnapshotData?.income"></app-incomes-snapshot-list>
        </div>
        <div class="col-6" *ngIf="packagerSnapshotData?.expense">
          <app-expances-snapshot-list [expenses]="packagerSnapshotData?.expense" ></app-expances-snapshot-list>
        </div>
      </div>
    </div>
    <div *ngIf="packagerSnapshotData?.assets" class="col-12 mt-2">
      <app-assets-snapshot-list [assets]="packagerSnapshotData.assets" ></app-assets-snapshot-list>
    </div>
  </div>
</div>
