import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { Proposal } from '../../../../../../../../../_base-shared/models/CaseDocument/Proposal';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-case-proposal-list',
  templateUrl: './case-proposal-list.component.html',
  styles:      [],
})
export class CaseProposalListComponent implements OnInit {
  @Input() case: Case;
  public proposals: Array<Proposal | any> = []; // TODO: use proposals instead of propuesto logs
  public isLoading                        = 0;
  public storageUrl                       = environment.STORAGE_URL + '/';

  constructor(private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.loadCaseProposals(this.case);
  }

  private loadCaseProposals(clientCase: Case) {
    this.isLoading++;
    this.caseService.getPropuestaLogs(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.proposals = result.data);
  }
}
