<mat-spinner *ngIf="isLoading" class="m-0 m-auto"></mat-spinner>
<ng-container *ngIf="!isLoading">
  <app-nav [notifications]="allNotifications"></app-nav>
  <div style="background-color: #FAFBFB">
    <!-- <app-header *ngIf="authUser"></app-header>-->
    <main id="js-page-content" role="main" class="page-content">
      <router-outlet></router-outlet>
    </main>
    <!--<app-mobile-nav [selectedCase]="clientSelectedCase" *ngIf="authUser"></app-mobile-nav>-->
  </div>
</ng-container>
