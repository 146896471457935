import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-user-security',
  templateUrl: './user-security.component.html',
  styles:      [],
})
export class UserSecurityComponent implements OnInit {
  public authUser: User;

  constructor(private globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
  }

}
