import { ScheduledTaskNotification } from './ScheduledTaskNotification';
import { Task } from './Task';
import { TaskTemplate } from './TaskTemplate';

export type TemplateNotificationNotifyType =
    'assignment'
    | 'before_due_date'
    | 'after_due_date'
    | 'priority_update';

export class TemplateNotification {
  id?: number;
  // task_template_id?: number; // removed
  notifiable_type: 'task_template' | 'task';
  notifiable_id: number;
  channel: 'email' | 'sms' | 'app';
  notify_on: TemplateNotificationNotifyType;
  delay: number;
  delay_unit: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month';

  created_at: string | Date;
  updated_at: string | Date;

  // task_template?: TaskTemplate; // removed
  notifiable?: TaskTemplate | Task;
  scheduled_task_notifications?: Array<ScheduledTaskNotification>;

}
