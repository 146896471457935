import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class MiscConfigService extends MainBaseApiService {

  index(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/configs`, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  getConfigData(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/configs`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getMinDisposableIncome(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/configs/min_disposable_income`)
      .pipe(catchError(response => this.handleError(response)));
  }

}
