import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TownHallEditorComponent } from './town-hall-editor/town-hall-editor.component';
import { TownHallListComponent } from './town-hall-list/town-hall-list.component';

const routes: Routes = [
  {path: 'town-halls', component: TownHallListComponent},
  {path: 'town-halls/create', component: TownHallEditorComponent, data: {editorType: 'create'}},
  {path: 'town-halls/:id/edit', component: TownHallEditorComponent, data: {editorType: 'edit'}},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class TownHallRoutingModule {
}
