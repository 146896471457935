import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { CreateFormComponent } from './_shared/create-form/create-form.component';
import { CourtRoutingModule } from './court/court-routing.module';
import { CourtComponent } from './court/court.component';
import { AdministratorsRoutingModule } from './administrators/administrators-routing.module';
import { AdministratorsComponent } from './administrators/administrators.component';
import { NotariesRoutingModule } from './notaries/notaries-routing.module';
import { NotariesComponent } from './notaries/notaries.component';
import { EditorComponent } from './solicitors/editor/editor.component';
import { SolicitorsRoutingModule } from './solicitors/solicitors-routing.module';
import { SolicitorsComponent } from './solicitors/solicitors.component';

@NgModule({
  imports:      [
    SharedModule,
    CourtRoutingModule,
    AdministratorsRoutingModule,
    NotariesRoutingModule,
    SolicitorsRoutingModule,
  ],
  declarations: [
    AdministratorsComponent,
    CourtComponent,
    NotariesComponent,
    CreateFormComponent,
    SolicitorsComponent,
    EditorComponent
  ],
})
export class AddressBookModule {
}
