import { User } from './User';

export class UserSaleTarget {
  id?: number;
  user_id?: number;

  monthly_lso?: number;
  monthly_lso2?: number;
  monthly_dm?: number;
  monthly_bdm: number;
  monthly_dgs: number;
  monthly_cajaplus: number;
  monthly_recovered_frozen_cases?: number;
  monthly_completed_documentations?: number;
  monthly_paid?: number;
  monthly_signed?: number;

  created_at?: Date | string;
  updated_at?: Date | string;

  user?: User;
}
