import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { CreditorEditorComponent } from './creditor-editor/creditor-editor.component';
import { CreditorReassignComponent } from './creditor-editor/creditor-reassign/creditor-reassign.component';
import { CreditorListComponent } from './creditor-list/creditor-list.component';
import { CreditorRoutingModule } from './creditor-routing.module';

@NgModule({
    declarations: [
        CreditorListComponent,
        CreditorEditorComponent,
        CreditorReassignComponent,
    ],
    imports: [
        SharedModule,
        CreditorRoutingModule,
    ]
})
export class CreditorModule {
}
