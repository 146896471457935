import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { PaymentListComponent } from './payment-list/payment-list.component';

const routes: Routes = [
  {
    path:        'payment',
    children:    [
      {path: '', component: PaymentListComponent, canActivate: [MainPermissionGuard, MainAuthGuard]},
      // {path: 'upcoming', component: PaymentListComponent, canActivate: [PermissionGuard, AuthGuard]},
      // {path: 'history', component: PaymentHistoryComponent, canActivate: [PermissionGuard, AuthGuard]},
    ],
    canActivate: [MainPermissionGuard, MainAuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class PaymentRoutingModule {
}
