import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-user-profile-settings',
  templateUrl: './user-profile-settings.component.html',
  styles:      [],
})
export class UserProfileSettingsComponent implements OnInit {
  public authUser: User;
  public isLoading         = 0;

  constructor(private globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
  }

}
