import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { CreditorEditorComponent } from './creditor-editor/creditor-editor.component';
import { CreditorListComponent } from './creditor-list/creditor-list.component';

const routes: Routes = [
  {
    path:     'creditor',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: CreditorListComponent, data: {allowManager: true}},
      {
        path:        'create',
        canActivate: [MainPermissionGuard],
        component:   CreditorEditorComponent,
        data:        {editorType: 'create', allowManager: true},
      },
      {
        path:        ':id/edit',
        canActivate: [MainPermissionGuard],
        component:   CreditorEditorComponent,
        data:        {editorType: 'edit', allowManager: true},
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class CreditorRoutingModule {
}
