import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-email-shadow-dom',
  templateUrl: './email-shadow-dom.component.html',
  styleUrls: ['./email-shadow-dom.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EmailShadowDomComponent implements OnInit{
  @Input() emailContent: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
