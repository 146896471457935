import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileDetailsComponent } from './user-profile-settings/profile-details/profile-details.component';
import { UserConnectionsComponent } from './user-profile-settings/user-connections/user-connections.component';
import { UserProfileSettingsComponent } from './user-profile-settings/user-profile-settings.component';
import { UserSecurityComponent } from './user-profile-settings/user-security/user-security.component';

const routes: Routes = [
  {
    path:      'profile',
    component: UserProfileSettingsComponent,
    children:  [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {path: 'details', component: ProfileDetailsComponent},
      {path: 'security', component: UserSecurityComponent},
      {path: 'connections', component: UserConnectionsComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class UserRoutingModule {
}
