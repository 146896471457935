import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../../_shared/guards/main-permission-guard.service';
import { NotificationConfiguratorComponent } from './notification-configurator/notification-configurator.component';
import { NotificationEditorComponent } from './notification-editor/notification-editor.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationTriggersListComponent } from './notification-triggers-list/notification-triggers-list.component';

const routes: Routes = [
  {
    path:     'notification',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: NotificationListComponent},
      {
        path:        'create',
        canActivate: [MainPermissionGuard],
        component:   NotificationEditorComponent,
        data:        {editorType: 'create'},
      },
      {
        path:        ':id/edit',
        canActivate: [MainPermissionGuard],
        component:   NotificationEditorComponent,
        data:        {editorType: 'edit'},
      },
      {path: 'trigger', canActivate: [MainPermissionGuard], component: NotificationTriggersListComponent},
      {
        path:        'trigger/configure',
        canActivate: [MainPermissionGuard],
        component:   NotificationConfiguratorComponent,
        data:        {editorType: 'create'},
      },
      {
        path:        'trigger/:id/edit',
        canActivate: [MainPermissionGuard],
        component:   NotificationConfiguratorComponent,
        data:        {editorType: 'edit'},
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class NotificationRoutingModule {
}
