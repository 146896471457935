<div class="row">
  <div class="offset-md-3 col-md-6">
    <div class="card">
      <div class="card-header">
        <h1>{{"AUTH.forgot-password" | translate}}</h1>
      </div>
      <div class="card-body">
        <mat-spinner *ngIf="sendingRequest" diameter="25" class="ml-3"></mat-spinner>
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <!-- ID Card -->
          <app-input type="text" formControlName="id_card" [label]="'SHARED.dni_number' | translate"
                     [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true"
                     [requiredStar]="true">
          </app-input>
          <!-- Submit -->
          <button mat-raised-button color="primary" *ngIf="!sendingRequest" type="submit">
            {{ "SHARED.submit" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
