import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ConfigurationPackager } from '../../../../../../_base-shared/models/Packager/ConfigurationPackager';
import { Packager } from '../../../../../../_base-shared/models/Packager/Packager';
import { Configuration } from '../../../../../../_base-shared/models/System/Configuration';
import { ConfigurationCategory } from '../../../../../../_base-shared/models/System/ConfigurationCategory';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ConfigurationService } from '../../../admin/system/configuration.service';
import { PackagerService } from '../../packager.service';

@Component({
  selector:    'app-packager-configuration',
  templateUrl: './packager-configuration.component.html',
  styles:      []
})
export class PackagerConfigurationComponent implements OnInit {
  public authUser: User;
  public isLoading                                             = 0;
  public configurationCategories: Array<ConfigurationCategory> = [];
  public packager: Packager;
  public packagerConfigurations: Array<ConfigurationPackager>;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventService: MainGlobalEventService,
    private packagerService: PackagerService,
    private configurationService: ConfigurationService
  ) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
    });

    this.route.parent.paramMap.subscribe(params => {
      const packagerId = +params.get('id');
      this.fetchPackager(packagerId);
    });
  }

  public configurationsArray(arrayName) {
    return this.form.get(arrayName) as UntypedFormArray;
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return false;
    }

    this.isSubmitting = true;
    this.packagerService.upsertConfigurations(this.packager.id, form.value).pipe(finalize(() => this.isSubmitting = false)).subscribe(
      result => {
        this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
          {model: this.translate.instant('PACKAGER.model_name.singular')}));
      },
      error => {
        this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
          {model: this.translate.instant('PACKAGER.model_name.singular')}));
      }
    );
  }

  private fetchPackager(packagerId: number) {
    this.isLoading++;
    this.packagerService.show(packagerId, ['users']).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.packager = result.data;
        this.fetchConfigurations(this.packager);
      },
      () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
    );
  }

  private fetchConfigurations(packager: Packager) {
    this.isLoading++;
    this.configurationService.indexCategories({select_all: 1, is_system: false}, ['configurations'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.configurationCategories = result.data;
        this.fetchPackagerConfigurations(this.configurationCategories, packager);
      });
  }

  private fetchPackagerConfigurations(configurationCategories: Array<ConfigurationCategory>, packager: Packager) {
    this.isLoading++;
    this.packagerService.indexConfigurations(packager.id, {select_all: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        console.log(result.data);
        this.packagerConfigurations = result.data;
        // console.log(this.packagerConfigurations);
        this.buildForm(configurationCategories, this.packagerConfigurations);
      });
  }

  private buildForm(configurationCategories: Array<ConfigurationCategory>, packagerConfigurations: Array<ConfigurationPackager>) {
    console.log(packagerConfigurations);
    this.form = this.fb.group({});

    configurationCategories.forEach(category => {
      this.form.addControl(category.slug, this.fb.array([]));

      const categoryConfigArray = this.form.get(category.slug) as UntypedFormArray;

      category.configurations.forEach(configuration => {
        const packagerConfig = packagerConfigurations.find(pC => pC.configuration_id === configuration.id);
        categoryConfigArray.push(this.initPackagerConfiguration(configuration, packagerConfig));
      });
    });

    console.log(this.form.value);
  }

  private initPackagerConfiguration(config: Configuration, packagerConfig: ConfigurationPackager) {
    // console.log(config.id);
    // console.log(packagerConfig);
    return this.fb.group({
      slug:              [config.slug],
      name_en:           [config.name_en],
      input_type:        [config.input_type],
      available_options: [config.available_options],
      type:              [config.type],
      value:             [packagerConfig !== undefined ? packagerConfig.value : config.default_value],
      visible:           [config.visible]
    });
  }
}
