import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionProvider } from '../../../../../_base-shared/models/Distribution/DistributionProvider';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DistributionProviderService extends MainBaseApiService {

  public index(requestData = {}, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest(
        {...requestData, with: relations, with_count: relationsCount}, 'get',
    );

    return this.http.get<LaravelResourceResponse<Array<DistributionProvider>>>(
        this.apiUrl + '/distribution-providers', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public show(modelId: number, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations, with_count: relationsCount}, 'get');

    return this.http.get<LaravelResourceResponse<DistributionProvider>>(
        this.apiUrl + '/distribution-providers/' + modelId, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public store(model: DistributionProvider) {
    return this.http.post<LaravelResourceResponse<DistributionProvider>>(this.apiUrl + '/distribution-providers', model)
        .pipe(catchError(response => this.handleError(response)));
  }

  public update(modelId: number, updatedModel: DistributionProvider, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');

    return this.http.put<LaravelResourceResponse<DistributionProvider>>(
        this.apiUrl + '/distribution-providers/' + modelId, updatedModel, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

}
