<div>
  First name: {{ authUser.first_name }}
</div>
<div>
  Last name: {{ authUser.last_name }}
</div>
<div>
  DNI: {{ authUser.id_card }}
</div>
<div>
  Email: {{ authUser.email }}
</div>
<div>
  Phone: <span *ngIf="authUser.phone">+</span>{{ authUser.phone_country_code + ' ' + authUser.phone }}
</div>
<div>
  Address:
  <span *ngIf="authUser?.address?.country">{{ authUser.address.country + ', ' }}</span>
  <span *ngIf="authUser?.address?.region">{{ authUser.address.region + ', ' }}</span>
  <span *ngIf="authUser?.address?.city">{{ authUser.address.city + ', ' }}</span>
  <span *ngIf="authUser?.address?.postcode">{{ authUser.address.postcode + ', ' }}</span>
  <span *ngIf="authUser?.address?.address_1">{{ authUser.address.address_1 + ', ' }}</span>
  <span *ngIf="authUser?.address?.address_2">{{ authUser.address.address_2 + ', ' }}</span>
</div>
