<div class="row">
  <div class="col-12">
    <h2>{{'NAVBAR.solicitors' | translate}}</h2>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      {{'ADDRESS-BOOK.add-solicitor' | translate}}
    </button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5 pb-3">
    <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
      <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-right-0">
              <i class="fal fa-search"></i>
          </span>
      </div>
      <input [formControl]="search"
             type="text"
             class="form-control border-left-0 bg-transparent pl-0"
             (focus)="toggleFocus(true)"
             (blur)="toggleFocus(false)"
             [placeholder]="'SHARED.search' | translate">
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource" style="width: 100%">

    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.name" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- address -->
    <ng-container matColumnDef="address">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.address" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <!-- email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.email" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- phone -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.phone" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>

    <!-- city -->
    <ng-container matColumnDef="city">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.city" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.city}} </td>
    </ng-container>

    <!-- city -->
    <ng-container matColumnDef="region">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.region" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.region}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 20%">{{"SHARED.actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
          <a class="pr-3" routerLink="{{element.id}}/edit">{{"SHARED.edit" | translate}}</a>
          <a href="#" (click)="openDeleteDialog(element.id, $event)">{{"SHARED.delete" | translate}}</a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1"  style="padding: 50px">
    <h3 class="text-center">{{ "ADDRESS-BOOK.no-solicitors" | translate }}</h3>
  </div>

  <mat-paginator
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="paginatorChange($event)"
    showFirstLastButtons
  ></mat-paginator>
</div>
