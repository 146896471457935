import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { GmailAuthorizationCallbackComponent } from './gmail-authorization-callback/gmail-authorization-callback.component';
import { IntegrationRoutingModule } from './integration-routing.module';
import { MymoidMotoErrorComponent } from './mymoid/mymoid-moto-error/mymoid-moto-error.component';
import { MymoidMotoIFrameRedirectComponent } from './mymoid/mymoid-moto-iframe-redirect/mymoid-moto-iframe-redirect.component';
import { MymoidMotoSuccessComponent } from './mymoid/mymoid-moto-success/mymoid-moto-success.component';

@NgModule({
  imports:      [
    SharedModule,
    IntegrationRoutingModule,
  ],
  declarations: [
    GmailAuthorizationCallbackComponent,
    MymoidMotoIFrameRedirectComponent,
    MymoidMotoSuccessComponent,
    MymoidMotoErrorComponent,
  ],
})
export class IntegrationModule {
}
