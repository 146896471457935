import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { EntityAdministrator } from '../../../../../../_base-shared/models/Entity/EntityAdministrator';
import { Notary } from '../../../../../../_base-shared/models/Entity/Notary';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AdministratorsService extends MainBaseApiService {

  index(data): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/administrators', {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  show(id: number): Observable<LaravelResourceResponse<EntityAdministrator>> {
    return this.http.get<LaravelResourceResponse<EntityAdministrator>>(this.apiUrl + '/administrators/' + id)
      .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResourceResponse<EntityAdministrator>> {
    return this.http.post<LaravelResourceResponse<EntityAdministrator>>(this.apiUrl + '/administrators', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(id: number, data): Observable<LaravelResourceResponse<EntityAdministrator>> {
    return this.http.put<LaravelResourceResponse<EntityAdministrator>>(this.apiUrl + '/administrators/' + id, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  delete(id: number): Observable<LaravelResourceResponse<EntityAdministrator>> {
    return this.http.delete<LaravelResourceResponse<EntityAdministrator>>(this.apiUrl + '/administrators/' + id)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendEmailToAdministrator(caseId, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse<any>>(`${ this.apiUrl }/cases/${ caseId }/email-proposal-to-administrator`,
      data)
      .pipe(catchError(response => this.handleError(response)));
  }

  saveAdministrator(caseId, administratorId: number): Observable<LaravelResourceResponse<Notary>> {
    return this.http.post<LaravelResourceResponse<Notary>>(this.apiUrl + `/cases/${ caseId }/save-administrator`,
      {administrator_id: administratorId})
      .pipe(catchError(response => this.handleError(response)));
  }
}
