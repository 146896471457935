import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SharedXsrfInterceptorService } from '../../../../../_base-shared/interceptors/shared-xsrf-interceptor.service';
import { environment } from '../../../environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class MainXsrfInterceptorService extends SharedXsrfInterceptorService {
  protected apiDomain: string;

  constructor(@Inject(PLATFORM_ID) protected platformId) {
    super(platformId);
    this.apiDomain = environment.API_DOMAIN;
  }
}
