<h2 class="pl-4">{{'ADDRESS-BOOK.add-solicitor' | translate}}</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="formActive">
  <div class="card shadow mb-5">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="col" appearance="outline" color="primary">
            <mat-label>{{ 'SHARED.model.name' | translate }}</mat-label>
            <input matInput type="text" formControlName="name">
            <mat-error *ngIf="form.get('name').touched && form.get('name').invalid">
              {{"SHARED.field-required" | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="col" appearance="outline" color="primary">
            <mat-label>{{"ADDRESS-BOOK.address" | translate}}</mat-label>
            <input matInput type="text" formControlName="address">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="col" appearance="outline" color="primary">
            <mat-label>{{"ADDRESS-BOOK.email" | translate}}</mat-label>
            <input matInput type="text" formControlName="email">
            <mat-error *ngIf="form.get('email').touched && form.get('email').errors?.email" class="pl-2">
              {{'SHARED.invalid-email' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="col" appearance="outline" color="primary">
            <mat-label>{{"ADDRESS-BOOK.phone" | translate}}</mat-label>
            <input matInput type="text" formControlName="phone">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="col" appearance="outline" color="primary">
            <mat-label>{{"ADDRESS-BOOK.city" | translate}}</mat-label>
            <input matInput type="text" formControlName="city">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="col" appearance="outline" color="primary">
            <mat-label>{{"ADDRESS-BOOK.region" | translate}}</mat-label>
            <input matInput type="text" formControlName="region">
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex">
        <app-spinner-btn [class]="'ml-3'" [loading]="loading" [name]="'SHARED.submit' | translate"></app-spinner-btn>
      </div>
    </div>
  </div>
</form>
