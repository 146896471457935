<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>
          <span *ngIf="editorType === 'edit'">{{ 'PACKAGER.editor.heading_edit' | translate }}</span>
          <span *ngIf="editorType === 'create'">{{ 'PACKAGER.editor.heading_create' | translate }}</span>
        </h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div *ngIf="serverResponse" class="col-12">
          <app-server-response [response]="serverResponse"></app-server-response>
        </div>
      </div>
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
        <div class="row">
          <!-- Name En -->
          <div class="col">
            <app-input formControlName="name_en" [label]="'PACKAGER.editor.form.name_en' | translate" [fullWidth]="true">
            </app-input>
          </div>
          <!-- Name Es -->
          <div class="col">
            <app-input formControlName="name_es" [label]="'PACKAGER.editor.form.name_es' | translate" [fullWidth]="true">
            </app-input>
          </div>
        </div>
        <div class="row">
          <!-- Users -->
          <div class="col">
            <app-input type="select" formControlName="user_ids" appearance="outline" multiple
                       [label]="'PACKAGER.relations.users' | translate" class="full-width"
                       [selectOptions]="agents" [searchable]="true" [showClear]="true" [fullWidth]="true"
                       [selectLabel]="['first_name', 'last_name']" [selectValue]="'id'">
            </app-input>
          </div>
        </div>
        <!-- Submit -->
        <div class="d-flex justify-content-end">
          <app-spinner-btn type="submit" [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
          </app-spinner-btn>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
