import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SolicitorsComponent } from './solicitors.component';
import { EditorComponent } from './editor/editor.component';

const routes: Routes = [
  {
    path    : 'solicitors',
    children: [
      {path: '', component: SolicitorsComponent},
      {path: 'create', component: EditorComponent, data: {editorType: 'create'}},
      {path: ':id/edit', component: EditorComponent, data: {editorType: 'edit'}},
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class SolicitorsRoutingModule {
}
