<div class="d-flex row" [ngClass]="{'align-items-center': flexClass === 'align-items-center', 'align-items-baseline': flexClass === 'align-items-baseline'}">
  <div class="col" *ngIf="parentFormGroup && showExtraLabel">
    <mat-label>{{ label }}</mat-label>
  </div>
  <div class="col">
    <mat-form-field *ngIf="parentFormGroup" color="primary" [appearance]="appearance" style="display: block"
                    [formGroup]="parentFormGroup">
      <mat-label *ngIf="showLabel">{{ label }}</mat-label>
      <mat-select #customDropdown
                  [formControlName]="parentFormControlName"
                  [placeholder]="placeholder"
                  [multiple]="isMultiple"
                  (selectionChange)="selectChange ? onSelectionChange($event) : null">
        <mat-option>
<!--          <ngx-mat-select-search noEntriesFoundLabel="No items found" placeholderLabel="Search"-->
<!--                                 [formControl]="filterCtrl">-->
<!--          </ngx-mat-select-search>-->
        </mat-option>
        <ng-container *ngIf="selectAll">
          <mat-checkbox class="select-all-checkbox" color="primary"
                        [formControl]="selectAllForm">Select all
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="optionGroup">
          <mat-optgroup *ngFor="let group of filteredData | async" [label]="group[optionGroupLabel]">
            <mat-option *ngFor="let option of group[optionGroupData]" [value]="option[optionGroupDataValue]">
              {{ option[optionGroupDataLabel] }}
            </mat-option>
          </mat-optgroup>
        </ng-container>

        <ng-container *ngIf="!optionGroup">
          <mat-option *ngFor="let data of filteredData  | async" [value]="matOptionValue ? data[matOptionValue] : data">
            {{ data[matOptionLabel] }}
          </mat-option>
        </ng-container>
      </mat-select>


        <button *ngIf="!isMultiple  ?
            (parentFormGroup.get(parentFormControlName) && parentFormGroup.get(parentFormControlName).value && !parentFormGroup.get(parentFormControlName).disabled) && showRemoveButton :
            (parentFormGroup.get(parentFormControlName) && parentFormGroup.get(parentFormControlName).value.length && !parentFormGroup.get(parentFormControlName).disabled) && showRemoveButton"
                mat-button matSuffix
                [disabled]="parentFormGroup.get(parentFormControlName).disabled" mat-icon-button aria-label="Clear"
                (click)="onClearSelect($event, parentFormGroup.get(parentFormControlName))">
          <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!parentFormGroup" class="d-flex row" [ngClass]="{'align-items-center': flexClass === 'align-items-center', 'align-items-baseline': flexClass === 'align-items-baseline'}">
  <div class="col" *ngIf="showExtraLabel">
    <mat-label>{{ label }}</mat-label>
  </div>
  <div class="col">
    <mat-form-field color="primary" [appearance]="appearance" style="display: block">
      <mat-label *ngIf="showLabel">{{ label }}</mat-label>
      <mat-select
          #customDropdown
          [formControl]="parentFormControl"
          [placeholder]="placeholder"
          [multiple]="isMultiple"
          (selectionChange)="onSelectionChange($event)">
<!--        <mat-option>-->
<!--          <ngx-mat-select-search noEntriesFoundLabel="No items found" placeholderLabel="Search"-->
<!--                                 [formControl]="filterCtrl"></ngx-mat-select-search>-->
<!--        </mat-option>-->
        <ng-container *ngIf="selectAll">
          <mat-checkbox class="select-all-checkbox" color="primary"
                        [formControl]="selectAllForm">Select all
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="optionGroup">
          <mat-optgroup *ngFor="let group of filteredData | async" [label]="group[optionGroupLabel]">
            <mat-option *ngFor="let option of group[optionGroupData]" [value]="option[optionGroupDataValue]">
              {{ option[optionGroupDataLabel] }}
            </mat-option>
          </mat-optgroup>
        </ng-container>

        <ng-container *ngIf="!optionGroup">
          <mat-option *ngFor="let data of filteredData | async" [value]="matOptionValue ? data[matOptionValue] : data">
            {{ data[matOptionLabel] }}
          </mat-option>
        </ng-container>
      </mat-select>
      <button *ngIf="isMultiple ?
      (parentFormControl && parentFormControl.value?.length && !parentFormControl.disabled && showRemoveButton) :
      (parentFormControl && parentFormControl.value && !parentFormControl.disabled && showRemoveButton)"
              mat-button matSuffix
              [disabled]="parentFormControl.disabled" mat-icon-button aria-label="Clear"
              (click)="onClearSelect($event, parentFormControl)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
