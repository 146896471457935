import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MiDashboardComponent } from './mi-dashboard.component';

const routes: Routes = [
  { path: 'mi-dash', component: MiDashboardComponent, data: {version: 1} },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class MiDashboardRoutingModule {
}
