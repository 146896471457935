<h2 class="pl-4">{{'ADDRESS-BOOK.add-administrator' | translate}}</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="formActive">
  <div class="card shadow mb-5">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <label class="pl-2">{{ 'SHARED.model.name' | translate }}</label>
          <input type="text" formControlName="name" class="form-control" [placeholder]="'SHARED.model.name' | translate">
          <mat-error *ngIf="form.get('name').touched && form.get('name').invalid">
            {{"SHARED.field-required" | translate}}
          </mat-error>
        </div>
        <div class="col-md-4">
          <div><label class="pl-2">{{"ADDRESS-BOOK.status" | translate}}</label></div>
          <div>
            <mat-radio-group formControlName="approved" aria-label="Select an option">
              <mat-radio-button value="1">Active</mat-radio-button>
              <mat-radio-button value="0">Inactive</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-md-4">
          <div><label class="pl-2">{{"ADDRESS-BOOK.preferred-submission" | translate}}</label></div>
          <div>
            <mat-radio-group formControlName="submission_method" aria-label="Select an option">
              <mat-radio-button value="email">Email</mat-radio-button>
              <mat-radio-button value="upload_documents">Upload documents</mat-radio-button>
              <mat-radio-button value="post">Post</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div [class]="componentName === 'administrator' ? 'col-md-3' : 'col-md-4'">
          <label class="pl-2">{{"ADDRESS-BOOK.address" | translate}}</label>
          <input type="text" formControlName="address" class="form-control" [placeholder]="'ADDRESS-BOOK.address' | translate">
          <mat-error *ngIf="form.get('address').touched && form.get('address').invalid">
            {{"SHARED.field-required" | translate}}
          </mat-error>
        </div>
        <div class="col-md-3" *ngIf="componentName === 'administrator'">
          <label class="pl-2">{{"ADDRESS-BOOK.province" | translate}}</label>
          <input type="text" formControlName="province" class="form-control" [placeholder]="'ADDRESS-BOOK.province' | translate">
        </div>
        <div [class]="componentName === 'administrator' ? 'col-md-3' : 'col-md-4'">
          <label class="pl-2">{{"ADDRESS-BOOK.general-email" | translate}}</label>
          <input type="text" formControlName="email_general" class="form-control" [placeholder]="'ADDRESS-BOOK.general-email' | translate">
          <mat-error *ngIf="form.get('email_general').touched && form.get('email_general').errors?.required" class="pl-2">
            {{"SHARED.field-required" | translate}}
          </mat-error>
          <mat-error *ngIf="form.get('email_general').touched && form.get('email_general').errors?.email" class="pl-2">
            {{ "SHARED.invalid-email" | translate }}
          </mat-error>
        </div>
        <div [class]="componentName === 'administrator' ? 'col-md-3' : 'col-md-4'">
          <label class="pl-2">{{"ADDRESS-BOOK.documents-email" | translate}}</label>
          <input type="text" formControlName="email_documents" class="form-control" [placeholder]="'ADDRESS-BOOK.documents-email' | translate">
          <mat-error *ngIf="form.get('email_documents').touched && form.get('email_documents').errors?.required" class="pl-2">
            {{"SHARED.field-required" | translate}}
          </mat-error>
          <mat-error *ngIf="form.get('email_documents').touched && form.get('email_documents').errors?.email" class="pl-2">
            {{ "SHARED.invalid-email" | translate }}
          </mat-error>
        </div>
      </div>

      <div class="row pt-3">
        <div [class]="componentName === 'court' ? 'col-md-6' : 'col-md-12'">
          <label class="pl-2">{{"ADDRESS-BOOK.company-name" | translate}}</label>
          <input type="text" formControlName="company_name" class="form-control" [placeholder]="'ADDRESS-BOOK.company-name' | translate">
          <mat-error *ngIf="form.get('company_name').touched && form.get('company_name').errors?.required" class="pl-2">
            {{"SHARED.field-required" | translate}}
          </mat-error>
        </div>
        <div *ngIf="componentName === 'court'" class="col-md-6">
          <label class="pl-2">{{"ADDRESS-BOOK.province" | translate}}</label>
          <input type="text" formControlName="province" class="form-control" [placeholder]="'ADDRESS-BOOK.province' | translate">
          <mat-error *ngIf="form.get('province').touched && form.get('province').errors?.required" class="pl-2">
            {{"SHARED.field-required" | translate}}
          </mat-error>
        </div>
      </div>

      <div *ngIf="componentName === 'notary'">
        <div class="row pt-3">
          <div class="col-md-3">
            <label class="pl-2">{{"ADDRESS-BOOK.province" | translate}}</label>
            <input type="text" formControlName="province" class="form-control" [placeholder]="'ADDRESS-BOOK.province' | translate">
          </div>
          <div class="col-md-3">
            <label class="pl-2">{{"ADDRESS-BOOK.cp" | translate}}</label>
            <input type="text" formControlName="cp" class="form-control" [placeholder]="'ADDRESS-BOOK.cp' | translate">
          </div>
          <div class="col-md-3">
            <label class="pl-2">{{"ADDRESS-BOOK.city" | translate}}</label>
            <input type="text" formControlName="city" class="form-control" [placeholder]="'ADDRESS-BOOK.city' | translate">
          </div>
          <div class="col-md-3">
            <label class="pl-2">{{"ADDRESS-BOOK.commune" | translate}}</label>
            <input type="text" formControlName="commune" class="form-control" [placeholder]="'ADDRESS-BOOK.commune' | translate">
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-4">
            <label class="pl-2">{{"ADDRESS-BOOK.web" | translate}}</label>
            <input type="text" formControlName="web" class="form-control" [placeholder]="'ADDRESS-BOOK.web' | translate">
          </div>
          <div class="col-md-4">
            <label class="pl-2">{{"ADDRESS-BOOK.phone" | translate}}</label>
            <input type="text" formControlName="phone" class="form-control" [placeholder]="'ADDRESS-BOOK.phone' | translate">
          </div>
          <div class="col-md-4">
            <label class="pl-2">{{"ADDRESS-BOOK.fax" | translate}}</label>
            <input type="text" formControlName="fax" class="form-control" [placeholder]="'ADDRESS-BOOK.fax' | translate">
          </div>
        </div>
      </div>

      <div class="pt-6">
        <h3>Contacts</h3>
      </div>
      <div class="row pt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label class="pl-2">{{"ADDRESS-BOOK.contact-1-name" | translate}}</label>
            <input type="text" formControlName="contact_1_name" class="form-control"
                   [placeholder]="'ADDRESS-BOOK.contact-1-name' | translate">
          </div>

          <div class="form-group">
            <label class="pl-2">{{"ADDRESS-BOOK.contact-1-phone" | translate}}</label>
            <input type="text" formControlName="contact_1_phone" class="form-control"
                   [placeholder]="'ADDRESS-BOOK.contact-1-phone' | translate">
          </div>

          <div class="form-group">
            <label class="pl-2">{{"ADDRESS-BOOK.contact-1-email" | translate}}</label>
            <input type="text" formControlName="contact_1_email" class="form-control"
                   [placeholder]="'ADDRESS-BOOK.contact-1-email' | translate">
            <mat-error *ngIf="form.get('contact_1_email').touched && form.get('contact_1_email').errors?.email" class="pl-2">
              {{ "SHARED.invalid-email" | translate }}
            </mat-error>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="pl-2">{{"ADDRESS-BOOK.contact-2-name" | translate}}</label>
            <input type="text" formControlName="contact_2_name" class="form-control"
                   [placeholder]="'ADDRESS-BOOK.contact-2-name' | translate">
          </div>

          <div class="form-group">
            <label class="pl-2">{{"ADDRESS-BOOK.contact-2-phone" | translate}}</label>
            <input type="text" formControlName="contact_2_phone" class="form-control"
                   [placeholder]="'ADDRESS-BOOK.contact-2-phone' | translate">
          </div>

          <div class="form-group">
            <label class="pl-2">{{"ADDRESS-BOOK.contact-2-email" | translate}}</label>
            <input type="text" formControlName="contact_2_email" class="form-control"
                   [placeholder]="'ADDRESS-BOOK.contact-2-email' | translate">
            <mat-error *ngIf="form.get('contact_2_email').touched && form.get('contact_2_email').errors?.email" class="pl-2">
              {{ "SHARED.invalid-email" | translate }}
            </mat-error>
          </div>
        </div>
      </div>


      <div class="row pt-5">
        <app-spinner-btn [class]="'ml-3'" [loading]="loading" [name]="'SHARED.submit' | translate"></app-spinner-btn>
      </div>
    </div>
  </div>
</form>
