import { NgModule } from '@angular/core';
import { SharedModule } from '../../../_shared/shared.module';
import { SystemEventEditorComponent } from './system-event-editor/system-event-editor.component';
import { SystemEventRoutingModule } from './system-event-routing.module';

@NgModule({
  declarations: [SystemEventEditorComponent],
  imports:      [
    SharedModule,
    SystemEventRoutingModule,
  ],
})
export class SystemEventModule {
}
