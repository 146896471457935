import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SolicitorsService } from './solicitors.service';
import { Solicitor } from '../../../../../../_base-shared/models/Entity/Solicitor';

@Component({
  selector: 'app-solicitors',
  templateUrl: './solicitors.component.html',
  styleUrls: ['./solicitors.component.scss']
})
export class SolicitorsComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public dataSource: MatTableDataSource<any>;
  public search              = new UntypedFormControl('');
  public searchFocus         = false;
  displayedColumns: string[] = ['id', 'address', 'name', 'email', 'phone', 'city', 'region', 'actions'];
  public paginatorConfig     = {
    pageIndex: 0,
    pageSize : 10,
    length   : 1
  };
  public isLoading           = 0;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private solicitorsService: SolicitorsService) {
  }

  ngOnInit(): void {
    this.fetchNotaries();

    this.search.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe(res => {
        this.fetchNotaries();
      });
  }

  fetchNotaries() {
    this.dataSource = new MatTableDataSource<any>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page    : this.paginatorConfig.pageIndex + 1,
      search  : this.search.value
    };

    this.solicitorsService.index(data).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.dataSource             = new MatTableDataSource<Solicitor>(res.data);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = res.data.total;
    });
  }

  toggleFocus(setFocus: boolean) {
    this.searchFocus = setFocus;
  }

  openDeleteDialog(id, $event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('ADDRESS-BOOK.delete-notary-note'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteNotary(id);
      }
    });
  }

  public paginatorChange($event: any) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchNotaries();
  }

  private deleteNotary(caseId: number) {
    this.solicitorsService.delete(caseId).subscribe(
      res => {
        this.fetchNotaries();
        this.toastr.success(this.translate.instant('ADDRESS-BOOK.delete-notary-success'),
          this.translate.instant('SHARED.success'));
      },
      error => {
        this.toastr.error(error.error?.message, this.translate.instant('ADDRESS-BOOK.delete-notary-error'),
          this.translate.instant('SHARED.error'));
      },
    );
  }

}
