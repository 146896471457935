<nav mat-tab-nav-bar>
  <a *ngIf="componentType === 'admin'" mat-tab-link [routerLink]="['/distribution', 'cases']" routerLinkActive
     #rla="routerLinkActive" [active]="rla.isActive">
    {{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_cases' | translate }}
  </a>
  <a mat-tab-link [routerLink]="['/distribution', 'batches']" routerLinkActive
     #rla2="routerLinkActive" [active]="rla2.isActive">
    {{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_batches' | translate }}
  </a>
  <a *ngIf="componentType === 'admin'" mat-tab-link [routerLink]="['/distribution', 'providers']" routerLinkActive
     #rla3="routerLinkActive" [active]="rla3.isActive">
    {{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_providers' | translate }}
  </a>
  <a *ngIf="componentType === 'admin'" mat-tab-link [routerLink]="['/distribution', 'reports']" routerLinkActive
     #rla4="routerLinkActive" [active]="rla4.isActive">
    {{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_reports' | translate }}
  </a>
</nav>
<div class="mt-2">
  <router-outlet></router-outlet>
</div>
