import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { AuthService } from '../auth.service';

@Component({
  selector:    'app-new-password',
  templateUrl: './new-password.component.html',
  styles:      [],
})
export class NewPasswordComponent implements OnInit {
  @Input() user: User;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public sendingRequest: boolean;
  public serverResponse: LaravelResourceResponse;
  public temporaryPasswordUpdate: boolean;
  private urlToken: string;
  private urlIdCard: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              public translateService: TranslateService,
              private globalEventsService: MainGlobalEventService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.urlToken = this.route.snapshot.queryParamMap.get('token');
    this.urlIdCard = this.route.snapshot.queryParamMap.get('id_card');

    if (this.urlToken && this.urlIdCard) {
      this.temporaryPasswordUpdate = false;
      this.buildForm(false, this.urlIdCard, this.urlToken);
    } else {
      if (!this.user) {
        this.globalEventsService.authUser$.subscribe(user => {
          if (user) {
            this.temporaryPasswordUpdate = user.temporary_password;
            this.user                    = user;
            this.ngOnInit();
          }
        });
      } else {
        this.buildForm(true);
      }
    }
  }

  private buildForm(temporaryPassword: boolean, idCard: string = null, verificationToken: string = null) {
    const currentPassValidators = temporaryPassword ?
      [] :
      [Validators.required, Validators.minLength(8)];

    this.form = this.fb.group({
      token:                 [verificationToken, temporaryPassword ? [] : [Validators.required]],
      id_card:               [idCard, temporaryPassword ? [] : [Validators.required]],
      current_password:      [null, currentPassValidators],
      password:              [null, [Validators.required, Validators.minLength(8)]],
      password_confirmation: [null, [Validators.required, Validators.minLength(8)]],
    });
  }

  public submitForm(form: any) {
    this.serverResponse = null;
    if (form.invalid) {
      this.formSubmitted = true;
    }

    this.sendingRequest = true;
    this.authService.newPassword(form.value).pipe(finalize(() => this.sendingRequest = false)).subscribe(
      result => {
        this.router.navigateByUrl('/');
        this.toastr.success(this.translateService.instant('AUTH.new-password.form.response.success'));
      },
      err => {
        this.serverResponse = err.error;
        this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
      },
    );
  }
}
