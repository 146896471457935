import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { AffiliateUserModule } from '../../affiliate-user/affiliate-user.module';
import { AffiliateDetailComponent } from './affiliate-detail/affiliate-detail.component';
import { AffiliateEditorComponent } from './affiliate-editor/affiliate-editor.component';
import { AffiliateListComponent } from './affiliate-list/affiliate-list.component';
import { AffiliateRoutingModule } from './affiliate-routing.module';

@NgModule({
  declarations: [
    AffiliateListComponent,
    AffiliateDetailComponent,
    AffiliateEditorComponent,
  ],
  imports:      [
    SharedModule,
    AffiliateUserModule,
    AffiliateRoutingModule,
  ],
})
export class AffiliateModule {
}
