<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>Packager Profile</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <nav *ngIf="packagerId" mat-tab-nav-bar dynamicHeight>
        <a mat-tab-link [routerLink]="['/packagers', packagerId, 'details', 'general']" routerLinkActive
           #rla="routerLinkActive" [active]="rla.isActive">
          General
        </a>
        <a mat-tab-link [routerLink]="['/packagers', packagerId, 'details', 'configuration']" routerLinkActive
           #rla1="routerLinkActive" [active]="rla1.isActive">
          Configuration
        </a>
      </nav>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
</div>
