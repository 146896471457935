import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { NotificationFromEvent } from '../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

// @Deprecated
@Injectable({
  providedIn: 'root',
})
export class NotificationEventService extends MainBaseApiService {

  index(data = {}): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/notification-events`, {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }

  get(notificationId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/notification-events/${ notificationId }`)
        .pipe(catchError(response => this.handleError(response)));
  }

  store(notification: NotificationFromEvent): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/notification-events`, notification)
        .pipe(catchError(response => this.handleError(response)));
  }

  update(notification: NotificationFromEvent): Observable<LaravelResourceResponse> {
    return this.http.put<LaravelResourceResponse>(`${ this.apiUrl }/notification-events/${ notification.id }`, notification)
        .pipe(catchError(response => this.handleError(response)));
  }

  delete(notificationId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/notification-events/${ notificationId }`)
        .pipe(catchError(response => this.handleError(response)));
  }

}
