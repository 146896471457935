import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles:      ['.mat-radio-button ~ .mat-radio-button { margin-left: 16px;}'],
})
export class DashboardComponent implements OnInit {
  public authUser: User;

  constructor(private router: Router,
              private globalEventService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
      if (!this.authUser?.packager.master) {
        this.router.navigate(['/case-dashboard', 'cases']);
      }
    });
  }

}
