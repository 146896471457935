import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeamEditorComponent } from './team-editor/team-editor.component';
import { TeamListComponent } from './team-list/team-list.component';

const routes: Routes = [
  {path: 'teams', component: TeamListComponent},
  {path: 'teams/create', component: TeamEditorComponent, data: {editorType: 'create'}},
  // {path: 'teams/:id', component: TeamDetailComponent},
  {path: 'teams/:id/edit', component: TeamEditorComponent, data: {editorType: 'edit'}},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class TeamRoutingModule {
}
