import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../../_shared/guards/main-permission-guard.service';
import { SmsEditorComponent } from './sms-editor/sms-editor.component';
import { SmsListComponent } from './sms-list/sms-list.component';

const routes: Routes = [
  {
    path:     'sms',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: SmsListComponent},
      {path: 'create', canActivate: [MainPermissionGuard], component: SmsEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [MainPermissionGuard], component: SmsEditorComponent, data: {editor: 'edit'}},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class SmsRoutingModule {
}
