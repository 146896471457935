import { PaymentPlan } from './PaymentPlan';

export class PaymentPlanPhase {
  id?: number;
  payment_plan_id?: number;
  active_on_build?: boolean | 0 | 1;
  phase_order?: number;
  amount?: number;
  installment_amount?: number;
  installment_duration?: number;
  installment_duration_unit?: 'day' | 'week' | 'month';
  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  payment_plan?: PaymentPlan;
}

export class CasePaymentPlanPhase {
  start_from: string | Date;
  amount: number;
  split_amount_type: 'equally' | 'with_remainder';
  installment_amount: number; // applicable when split_amount_type 'with_remainder' is used
  installments_count: number; // applicable when split_amount_type 'equally' is used
  interval: number;
  interval_unit: string;
  active_on_build: boolean | 0 | 1;
}
