import { Component, Input, OnInit } from '@angular/core';
import { Task } from '../../../../../../_base-shared/models/Task/Task';

@Component({
  selector:    'app-task-preview',
  templateUrl: './task-preview.component.html',
  styleUrls:      ['./task-preview.component.scss'],
})
export class TaskPreviewComponent implements OnInit {
  @Input() public task: Task;

  constructor() {
  }

  ngOnInit(): void {
  }

}
