import { TownHall } from '../Entity/TownHall';
import { User } from '../User/User';

export class Address {
  id?: number;
  addressable_type?: 'user';
  addressable_id?: number;

  country?: string;
  county?: string;
  region?: string;
  city?: string;
  municipality?: string;
  postcode?: string;
  address_1?: string;
  address_2?: string;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Accessors
  street_address?: string;

  // Relations
  addressable?: User | TownHall;
}
