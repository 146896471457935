import { Injectable } from '@angular/core';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { Observable } from 'rxjs';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SmsService extends MainBaseApiService {

  index(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/sms-templates`, {params: data})
            .pipe(catchError(response => this.handleError(response)));
  }

  get(smsId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/sms-templates/${smsId}`)
            .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/sms-templates`, data)
            .pipe(catchError(response => this.handleError(response)));
  }

  update(data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(`${this.apiUrl}/sms-templates/${data.smsId}`, data.sms)
            .pipe(catchError(response => this.handleError(response)));
  }

  delete(smsId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${this.apiUrl}/sms-templates/${smsId}`)
            .pipe(catchError(response => this.handleError(response)));
  }
}
