import { Case } from '../Case/Case';
import { Department } from '../Department/Department';
import { User } from '../User/User';
import { ScheduledTaskNotification } from './ScheduledTaskNotification';
import { TaskTemplate } from './TaskTemplate';
import { TemplateNotification } from './TemplateNotification';

export class Task {
  id?: number;
  authorable_type: 'user' | 'task_template';
  authorable_id: number;
  task_template_id?: number;
  case_id: number;
  completed_by_id: number;

  name: string;
  notes: string;
  completion_note: string;
  require_completion_note: boolean | 0 | 1;
  completed_at: null | string | Date;
  due_date: string | Date;
  notify_on_completion_via: Array<string>;

  created_at: string | Date;
  updated_at: string | Date;

  authorable?: User | TaskTemplate;
  completed_by?: User | TaskTemplate;
  task_template?: TaskTemplate;
  case?: Case;
  assigned_users?: Array<User>;
  assigned_departments?: Array<Department>;
  template_notifications?: Array<TemplateNotification>;
  scheduled_task_notifications?: Array<ScheduledTaskNotification>;
  teams?: Array<string>;
}
