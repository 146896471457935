<h1 mat-dialog-title>{{'CASES.single.edit-payment-history' | translate}}</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="saveEdits()">

    <mat-form-field color="primary" appearance="outline" class="block-picker d-block">
      <mat-label>{{"CASES.single.payment-date" | translate}}</mat-label>
      <input matInput [matDatepicker]="paymentDate" formControlName="payment_date">
      <mat-datepicker-toggle matSuffix [for]="paymentDate"></mat-datepicker-toggle>
      <mat-datepicker #paymentDate color="primary"></mat-datepicker>
    </mat-form-field>
    <mat-error *ngIf="form.touched && form.controls.date.invalid">{{'SHARED.field-required' | translate}}</mat-error>

    <label for="amount">{{'CASES.single.enter-amount' | translate}} </label>
    <input type="number" id="amount" [placeholder]="('CASES.single.amount' | translate)" class="form-control" formControlName="amount">
    <mat-error *ngIf="form.touched && form.controls.amount.invalid">{{'SHARED.field-required' | translate}}</mat-error>

    <label class="pt-2" for="payment_method">{{'CASES.single.payment-method' | translate}}</label>
    <select class="form-control" name="payment_method" id="payment_method" formControlName="payment_method">
      <option value="sepa">{{'CASES.single.sepa' | translate}}</option>
      <option value="wire">{{'CASES.single.wire' | translate}}</option>
      <option value="card">{{'CASES.single.card' | translate}}</option>
    </select>
    <mat-error *ngIf="form.touched && form.controls.payment_method.invalid">{{'SHARED.field-required' | translate}}</mat-error>

    <label class="pt-2" for="note">{{'CASES.single.payment-note' | translate}}</label>
    <quill-editor
      id="note"
      formControlName="note"
      [modules]="quillModules"
      [styles]="{height: '150px'}">
    </quill-editor>

    <div *ngIf="!isSavingEdits" class="pt-4 text-right">
      <button type="button" mat-button color="primary" class="mr-3" (click)="onCancel()">{{"SHARED.cancel" | translate}}</button>
      <button type="submit" mat-raised-button color="primary" style="min-width: 120px">{{"SHARED.save" | translate}}</button>
    </div>
    <div *ngIf="isSavingEdits" class="pt-4 text-right">
      <mat-spinner class="ml-auto" color="primary" [diameter]="30"></mat-spinner>
    </div>
  </form>
</div>
