import { User } from './User';

export class Team {
  id: number;
  name_en: string;
  name_es: string;
  slug: string;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Accessors
  name: string;

  // Relations
  leaders?: Array<User>;
  members?: Array<User>;

  // Counts
  leaders_count?: number;
  members_count?: number;
}
