import { CasePublicDebt } from '../Case/CasePublicDebt';
import { Address } from '../Location/Address';

export class TownHall {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;

  created_at: string | Date;
  updated_at: string | Date;

  // Relations
  address?: Address;
  public_debts?: Array<CasePublicDebt>;
}
