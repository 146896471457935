import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector:    'app-redirect-to-client-app-dynamic',
  templateUrl: './redirect-to-client-app-dynamic.component.html',
  styles:      [],
})
export class RedirectToClientAppDynamicComponent implements OnInit {
  private clientUrl = environment.CLIENT_APP_URL;
  private caseUuid: string;
  private userUuid: string;
  private urlLocation: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      if (data.routeUrl === 'relation-validation'){
        this.caseUuid = this.route.snapshot.paramMap.get('caseUuid');
        this.userUuid = this.route.snapshot.paramMap.get('userUuid');
        this.urlLocation = this.clientUrl + '/onboarding/cases/' + this.caseUuid + '/users/' + this.userUuid +
          '/' +  data.routeUrl;
      }
      else if (data.routeUrl === 'profile') {
        this.caseUuid = this.route.snapshot.paramMap.get('caseUuid');
        this.urlLocation = this.clientUrl + '/' + data.routeUrl + '/cases/' + this.caseUuid + '/profile';
      }
      else if (data.routeUrl === 'your-case') {
        this.caseUuid = this.route.snapshot.paramMap.get('caseUuid');
        this.userUuid = this.route.snapshot.paramMap.get('userUuid');
        this.urlLocation = this.clientUrl + '/onboarding/cases/' + this.caseUuid + '/users/' + this.userUuid +
          '/' +  data.routeUrl;
      }
      else if (data.routeUrl === 'documents') {
        this.caseUuid = this.route.snapshot.paramMap.get('caseUuid');
        this.userUuid = this.route.snapshot.paramMap.get('userUuid');
        this.urlLocation = this.clientUrl + '/' + data.routeUrl  + '/cases/' + this.caseUuid + '/users/' + this.userUuid +
          '/document-upload';
      }
      window.location.href = this.urlLocation;
    });
  }
}
