import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import {LaravelResourceResponse} from '../../../../../../_base-shared/contracts/laravel-response.interface';
import {Packager} from '../../../../../../_base-shared/models/Packager/Packager';
import {User} from '../../../../../../_base-shared/models/User/User';
import {UserService} from '../../user/user.service';
import {AdminPackagerService} from '../admin-packager.service';

@Component({
  selector: 'app-packager-editor',
  templateUrl: './packager-editor.component.html',
  styles: [],
})
export class PackagerEditorComponent implements OnInit {
  public editorType: 'create' | 'edit';
  public isLoading = 0;
  public isSubmitting: boolean;
  public packager: Packager;
  public serverResponse: LaravelResourceResponse;
  public form: FormGroup;
  public agents: Array<User> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private translate: TranslateService,
              private toastr: ToastrService,
              private packagerService: AdminPackagerService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      this.fetchAgents();
      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          const packagerId = +params.get('id');
          this.fetchPackager(packagerId, true);
        });
      } else {
        this.buildForm(new Packager());
      }
    });
  }

  public submitForm(form: FormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    const observable = this.editorType === 'create' ?
      this.packagerService.store(form.value) :
      this.packagerService.update(this.packager.id, form.value);

    this.isSubmitting = true;
    observable.pipe(finalize(() => this.isSubmitting = false)).subscribe(
      result => {
        this.packager = result.data;
        this.editorType === 'create' ?
          this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
            {model: this.translate.instant('PACKAGER.model_name.singular')})) :
          this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
            {model: this.translate.instant('PACKAGER.model_name.singular')}));
        this.router.navigate(['/packagers']);
      },
      error => {
        this.serverResponse = error.error;
        this.editorType === 'create' ?
          this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
            {model: this.translate.instant('PACKAGER.model_name.singular')})) :
          this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
            {model: this.translate.instant('PACKAGER.model_name.singular')}));
      },
    );

  }

  private fetchPackager(packagerId: number, buildForm: boolean = false) {
    this.isLoading++;
    this.packagerService.show(packagerId, ['users']).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.packager = result.data;
        if (buildForm) {
          this.buildForm(this.packager);
        }
      },
      () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private buildForm(packager: Packager) {
    this.form = this.fb.group({
      name_en: [packager.name_en, [Validators.required]],
      name_es: [packager.name_es, [Validators.required]],
      user_ids: [packager.users ? packager.users.map(user => user.id) : []],
    });
  }

  private fetchAgents() {
    this.isLoading++;
    this.userService.index({select_all: 1, all_packager_users: 1, role_slugs: ['packager']})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.agents = result.data);
  }

}
