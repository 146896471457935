import { NgModule } from '@angular/core';
import {
  DistributionBatchDetailComponent,
} from '../admin/distribution/distribution-batch/distribution-batch-detail/distribution-batch-detail.component';
import { DistributionRoutingModule } from './distribution-routing.module';
import { SharedDistributionModule } from './shared-distribution/shared-distribution.module';

@NgModule({
  imports:      [
    SharedDistributionModule,
    DistributionRoutingModule,
  ],
  declarations: [
    DistributionBatchDetailComponent,
  ],
})
export class DistributionModule {
}
