import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { PaymentPlanType } from '../../../../../_base-shared/models/Product/PaymentPlanType';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentPlanTypeService extends MainBaseApiService {
  public index(data = {}) {
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');

    return this.http.get<LaravelResourceResponse<Array<PaymentPlanType>>>(this.apiUrl + '/payment-plan-types', {params})
      .pipe(catchError(response => this.handleError(response)));
  }
}
