import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { UserService } from '../../user/user.service';

@Component({
  selector:    'app-gmail-authorization-callback',
  templateUrl: './gmail-authorization-callback.component.html',
  styles:      [],
})
export class GmailAuthorizationCallbackComponent implements OnInit, OnDestroy {
  public authUser: User;
  public isLoading                           = 1;
  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.route.queryParams.subscribe(result => {
        this.isLoading = 0;

        const stateParams = new URLSearchParams(result.state);
        const clientId    = +stateParams.get('clientId');
        if (result.hasOwnProperty('error')) {
          this.toastr.error('Failed to connect');
          // this.router.navigate(['/user-settings']); // TODO: enable
        } else {
          if (this.authUser) {
            this.confirmAuthorization(this.authUser.id, clientId, result.code);
          } else {
            console.error('no auth user');
            // TODO: remove else block when component guarded with Auth
            this.confirmAuthorization(1, clientId, result.code);
          }
        }
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private confirmAuthorization(authUserId: number, serviceId: number, authorizationCode: string) {
    this.isLoading++;
    this.userService.confirmAuthorization(authUserId, serviceId, authorizationCode)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            res => {
              this.toastr.success('Service connected');
              this.router.navigate(['user-settings']);
            },
            error => {
              this.toastr.error('Could not connect with the service');
              // this.router.navigate(['user-settings']); // TODO: enable
            },
        );
  }
}
