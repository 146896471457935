import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Packager } from '../../../../../../_base-shared/models/Packager/Packager';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { AdminPackagerService } from '../admin-packager.service';

@Component({
  selector:    'app-packager-list',
  templateUrl: './packager-list.component.html',
  styles:      [
    `
      mat-table {
        display: table;
        width: 100%;
      }

      mat-table > tr > td {
        padding-top: 10px;
      }
    `,
  ],
})
export class PackagerListComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public isLoading                               = 0;
  public packagers: MatTableDataSource<Packager> = new MatTableDataSource<Packager>([]);
  public displayedColumns: Array<string>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;
  public currentLanguage: string;

  private requestFilters: any;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              private packagerService: AdminPackagerService) {
  }

  ngOnInit(): void {
    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';
    this.translate.onLangChange.subscribe(next => {
      this.currentLanguage = next.lang;
    });
    this.displayedColumns       = ['id', 'name', 'users', 'actions'];
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.requestFilters         = {select_all: 0};
    this.fetchPackagers();
  }

  ngAfterViewInit(): void {
    this.packagers.sort = this.sort;
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.requestFilters.page     = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchPackagers();
  }

  public sortData(sort) {
    this.requestFilters.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.requestFilters.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchPackagers();
  }

  private fetchPackagers() {
    const requestData = MainBaseApiService.convertFiltersForRequest(this.requestFilters, 'get');

    this.isLoading++;
    this.packagerService.index(requestData, ['users']).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.packagers              = new MatTableDataSource<Packager>(result.data);
        this.packagers.sort         = this.sort;
        this.paginatorConfig.length = result.meta.total;
        this.totalResults           = result.meta.total;
        this.totalPages             = result.meta.last_page;
      },
      error => console.error(error),
    );
  }

  public deletePackager(packagerId: number) {
    Swal.fire({
      text:               this.translate.instant('SHARED.action.delete_confirmation',
        {model: this.translate.instant('PACKAGER.model_name.singular')}),
      icon:               'warning',
      showCancelButton:   false,
      confirmButtonText:  'OK',
      confirmButtonColor: '#4267b2',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.packagerService.delete(packagerId).pipe(finalize(() => this.isLoading--)).subscribe(
          () => {
            const localPackagers     = this.packagers.data;
            const localPackagerIndex = localPackagers.findIndex(sPackager => sPackager.id === packagerId);
            if (localPackagerIndex >= 0) {
              localPackagers.splice(localPackagerIndex, 1);
            } else {
              console.error('Deleted packager not in list');
            }

            this.packagers = new MatTableDataSource<Packager>(localPackagers);
            this.toastr.success(
              this.translate.instant('SHARED.submit_result.delete.success',
                {model: this.translate.instant('PACKAGER.model_name.singular')},
              ),
            );
          },
          () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
      }
    });
  }

  private resetPagination(): void {
    this.paginatorConfig         = this.defaultPaginatorConfig;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.requestFilters.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.requestFilters.sort_by = this.defaultSort.active;
    this.requestFilters.order   = this.defaultSort.direction;
  }

}
