import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { PackagerService } from '../../../packager/packager.service';
import { CaseService } from '../case.service';

@Component({
  selector: 'app-case-snapshot',
  templateUrl: './case-snapshot.component.html',
  styleUrls: ['./case-snapshot.component.scss']
})
export class CaseSnapshotComponent implements OnInit, OnDestroy{
  public case: Case;
  public packagerSnapshotData: any;
  public isLoading = 0;
  public serverResponse: LaravelResourceResponse;
  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private caseService: CaseService,
              private packagerService: PackagerService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.subscriptions.push(
        this.caseService.get(caseId, ['packager']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.case = result.data;
          this.getPackagerSnapShotData();
        }, error => {
          this.serverResponse = error.error;
        }),
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private getPackagerSnapShotData(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.packagerService.getPackagerSnapshotData(this.case.uuid).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.packagerSnapshotData = result.data;
      }, error => {
        this.serverResponse = error.error;
      }),
    );
  }
}
