<div class="d-flex flex-column h-100" style="max-height: 90vh">
  <div class="d-flex align-items-start flex-column">
    <h2>{{ 'CASES.single.draft.appoint_court.heading' | translate }}</h2>
    <h3 *ngIf="data.type === 'case_status'">{{'CASES.single.draft.appoint_court.subheading' | translate}}</h3>
  </div>
  <div class="d-flex justify-content-end">
    <app-input type="text" [formControl]="searchControl" [appearance]="'standard'"
               [label]="'SHARED.search' | translate" [extraLabel]="false" prefixType="icon" prefix="search">
    </app-input>
  </div>
  <div class="mat-elevation-z8">
    <mat-error *ngIf="courtIdControl.touched && courtIdControl.errors?.required" class="pl-2">
      {{ 'CASES.single.draft.appoint_court.no_court_selected' | translate }}
    </mat-error>

    <table mat-table [dataSource]="courts" style="width: 100%">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'active': selection.isSelected(row)}"
          (click)="selectCourt(row)">
      </tr>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" [checked]="selection.isSelected(row)"
                        (change)="selectCourt(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.name' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
      </ng-container>
      <!-- Address Column -->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADDRESS.model_name.singular' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{ element.address }} </td>
      </ng-container>
    </table>
    <!-- No Data -->
    <div *ngIf="isLoading < 1 && courts && courts.data.length < 1" style="padding: 50px">
      <h3 class="text-center">{{ 'SHARED.no_data' | translate:{models: 'LEGAL_ENTITY.court.model_name.plural' | translate} }}</h3>
    </div>
    <div *ngIf="isLoading" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                   [pageSizeOptions]="[5, 10, 20, 50]" [pageIndex]="paginatorConfig.pageIndex"
                   (page)="paginatorChange($event)">
    </mat-paginator>
  </div>
  <div class="text-right mt-5 pb-5 d-flex justify-content-end align-items-center">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeModal()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <app-spinner-btn type="button" color="primary" [loading]="isSubmitting" (click)="submitSelectedCourt()">
      {{ 'SHARED.select' | translate }}
    </app-spinner-btn>
    <mat-spinner *ngIf="isSubmitting" diameter="20" color="accent"></mat-spinner>
  </div>
</div>
