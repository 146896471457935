import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { AffiliateCaseListComponent } from '../../affiliate-user/affiliate-case-list/affiliate-case-list.component';
import { AffiliateDetailComponent } from './affiliate-detail/affiliate-detail.component';
import { AffiliateEditorComponent } from './affiliate-editor/affiliate-editor.component';
import { AffiliateListComponent } from './affiliate-list/affiliate-list.component';

const routes: Routes = [
  {
    path:     'affiliates',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: AffiliateListComponent},
      {
        path:        'create',
        canActivate: [MainPermissionGuard],
        component:   AffiliateEditorComponent,
        data:        {editorType: 'create'},
      },
      {
        path:        ':id/edit',
        canActivate: [MainPermissionGuard],
        component:   AffiliateEditorComponent,
        data:        {editorType: 'edit'},
      },
      {path: ':id', canActivate: [MainPermissionGuard], component: AffiliateDetailComponent},
      {path: ':id/cases', component: AffiliateCaseListComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class AffiliateRoutingModule {
}
