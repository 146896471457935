import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { BankAccountEditorComponent } from './payment/bank-account/bank-account-editor/bank-account-editor.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserEditorComponent } from './user-editor/user-editor.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserRoutingModule } from './user-routing.module';
import { UserSettingsComponent } from './user-settings/user-settings.component';

@NgModule({
  imports:      [
    SharedModule,
    UserRoutingModule,
  ],
  declarations: [
    UserListComponent,
    UserDetailComponent,
    UserEditorComponent,
    UserSettingsComponent,
    BankAccountEditorComponent,
  ],
  exports:      [
    BankAccountEditorComponent,
  ],
})
export class UserModule {
}
