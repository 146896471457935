<form [formGroup]="form">
  <h4 class="modal-title">{{ "PAYMENTS.change-payment-date" | translate }}</h4>
  <div class="form-group">
    <label class="pl-2">{{ "PAYMENTS.select-new-date" | translate }}</label>
    <div class="pb-3">
      <mat-form-field color="primary" appearance="fill">
        <mat-label>{{ "PAYMENTS.new-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="newDate" formControlName="newDate">
        <mat-datepicker-toggle matSuffix [for]="newDate"></mat-datepicker-toggle>
        <mat-datepicker #newDate color="primary"></mat-datepicker>
      </mat-form-field>
      <mat-error *ngIf="form.get('newDate').touched && form.get('newDate').errors?.required">
        {{"SHARED.field-required" | translate}}
      </mat-error>
    </div>
    <div>
      <label class="pl-2">{{ "PAYMENTS.change-date-for" | translate }}</label>
    </div>
    <mat-radio-group formControlName="newDateRadio" aria-label="Select an option" (change)="dateRadioChange($event.value)">
      <mat-radio-button class="mr-3" value="this_payment" >{{ "PAYMENTS.this-payment" | translate }}</mat-radio-button>
      <mat-radio-button class="mr-3" value="all_payments">{{ "PAYMENTS.all-payments" | translate }}</mat-radio-button>
      <mat-radio-button *ngIf="showDeferOption" value="defer_payment">{{ "PAYMENTS.defer-payment" | translate }}</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="showReason" class="mt-3">
      <label for="reason">{{ "PAYMENTS.reason" | translate }}</label>
      <select name="reason" id="reason" class="form-control" formControlName="reason">
        <option value="aplazado">{{ "PAYMENTS.defer-payment-options.postponed" | translate }}</option>
        <option value="paga falta">{{ "PAYMENTS.defer-payment-options.missed-payment" | translate }}</option>
        <option value="restitucion">{{ "PAYMENTS.defer-payment-options.refunded" | translate }}</option>
        <option value="pago tarde">{{ "PAYMENTS.defer-payment-options.late-payment" | translate }}</option>
      </select>
    </div>

    <div class="pt-3">
      <label class=" pl-2 pt-2" for="note">{{'CASES.single.note' | translate}}</label>
      <quill-editor
        id="note"
        formControlName="note"
        [modules]="quillModules"
        [styles]="{height: '150px'}">
      </quill-editor>
      <mat-error *ngIf="form.get('note').touched && form.get('note').invalid">
        {{'SHARED.field-required' | translate}}
      </mat-error>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="onNoClick()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="!form.value.newDate"
            data-dismiss="modal" (click)="changeDate()">{{ "PAYMENTS.change-date" | translate }}
    </button>
  </div>

</form>
