import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Team } from '../../../../../../_base-shared/models/User/Team';
import { User } from '../../../../../../_base-shared/models/User/User';
import { UserService } from '../../user/user.service';
import { TeamService } from '../team.service';

@Component({
  selector:    'app-team-editor',
  templateUrl: './team-editor.component.html',
  styles:      [],
})
export class TeamEditorComponent implements OnInit {
  public editorType: 'create' | 'edit';
  public isLoading           = 0;
  public isSubmitting: boolean;
  public team: Team;
  public serverResponse: LaravelResourceResponse;
  public form: UntypedFormGroup;
  public agents: Array<User> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private translate: TranslateService,
              private toastr: ToastrService,
              private teamService: TeamService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      this.fetchAgents();
      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          const teamId = +params.get('id');
          this.fetchTeam(teamId, true);
        });
      } else {
        this.buildForm(new Team());
      }
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    const observable = this.editorType === 'create' ?
        this.teamService.store(form.value) :
        this.teamService.update(this.team.id, form.value);

    this.isSubmitting = true;
    observable.pipe(finalize(() => this.isSubmitting = false)).subscribe(
        result => {
          this.team = result.data;
          this.editorType === 'create' ?
              this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                  {model: this.translate.instant('TEAM.model_name.singular')})) :
              this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
                  {model: this.translate.instant('TEAM.model_name.singular')}));
          this.router.navigate(['/teams']);
        },
        error => {
          this.serverResponse = error.error;
          this.editorType === 'create' ?
              this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                  {model: this.translate.instant('TEAM.model_name.singular')})) :
              this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
                  {model: this.translate.instant('TEAM.model_name.singular')}));
        },
    );

  }

  private fetchTeam(teamId: number, buildForm: boolean = false) {
    this.isLoading++;
    this.teamService.show(teamId, ['leaders', 'members']).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.team = result.data;
          if (buildForm) {
            this.buildForm(this.team);
          }
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private buildForm(team: Team) {
    this.form = this.fb.group({
      name_en:    [team.name_en, [Validators.required]],
      name_es:    [team.name_es, [Validators.required]],
      leader_ids: [team.leaders ? team.leaders.map(leader => leader.id) : []],
      member_ids: [team.members ? team.members.map(member => member.id) : []],
    });
  }

  private fetchAgents() {
    this.isLoading++;
    this.userService.index({select_all: 1, is_staff: 1}).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.agents = result.data.filter(user => user.role.is_staff);
    });
  }
}
