<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1>{{ 'LEGAL_ENTITY.town_hall.list.heading' | translate }}</h1>
    </mat-card-title>
    <div class="card-header-actions">
      <a mat-raised-button color="primary" [routerLink]="['/town-halls', 'create']">
        + {{ "SHARED.create" | translate }}
      </a>
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    <div class="d-flex justify-content-end">
      <!-- Search -->
      <div class="col-4">
        <app-input type="text" [formControl]="searchFilter" appearance="standard" multiple fullWidth="false"
                   [label]="'SHARED.search' | translate" prefix="search" prefixType="icon">
        </app-input>
      </div>
    </div>
    <mat-table [dataSource]="townHalls" matSort (matSortChange)="sortData($event)">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.id' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <!-- Email -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.email' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>
      <!-- Phone -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.phone' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
      </ng-container>
      <!-- County -->
      <ng-container matColumnDef="address_county">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADDRESS.county' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.address.county }}</td>
      </ng-container>
      <!-- Region -->
      <ng-container matColumnDef="address_region">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADDRESS.region' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.address.region }}</td>
      </ng-container>
      <!-- City -->
      <ng-container matColumnDef="address_city">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADDRESS.city' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.address.city }}</td>
      </ng-container>
      <!-- Postcode -->
      <ng-container matColumnDef="address_postcode">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADDRESS.postcode' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.address.postcode }}</td>
      </ng-container>
      <!-- Street Address -->
      <ng-container matColumnDef="address_street_address">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADDRESS.street_address' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.address.address_1 + (element.address.address_2 ? ', ' + element.address.address_2 : '') }}
        </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex">
            <a class="pr-3" [routerLink]="[element.id, 'edit']">{{ 'SHARED.edit' | translate }}</a>
            <!--<a class="pr-3" (click)="deleteTownHall(element.id)">{{ 'SHARED.delete' | translate }}</a>-->
          </div>
        </td>
      </ng-container>
    </mat-table>
    <!-- No Data -->
    <div *ngIf="isLoading < 1 && townHalls && townHalls.data.length < 1" style="padding: 50px">
      <h3 class="text-center">
        {{ 'SHARED.no_data' | translate: {models: 'LEGAL_ENTITY.town_hall.model_name.plural' | translate} }}
      </h3>
    </div>
    <div *ngIf="isLoading" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                   [pageSizeOptions]="[5, 10, 20]" [pageIndex]="paginatorConfig.pageIndex"
                   (page)="paginatorChange($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
