import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentService } from '../app-document/app-document.service';

@Component({
  selector:    'app-document',
  templateUrl: './document.component.html',
  styleUrls:   ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
  public documentUuid: string;
  public appDocument: AppDocument;
  public isSubmitting: boolean;
  public isLoading: boolean;

  constructor(private route: ActivatedRoute,
              private documentService: AppDocumentService,
              private toast: ToastrService) {
  }

  ngOnInit(): void {
    this.documentUuid = this.route.snapshot.queryParamMap.get('uuid');
    this.isLoading    = true;
    if (this.documentUuid) {
      this.documentService.getAppDocument(this.documentUuid, ['files']).subscribe(res => {
        this.isLoading   = false;
        this.appDocument = res.data;
      });
    }
  }

  public downloadDocument() {
    this.documentService.download(this.documentUuid).pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        const fileName = '_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.' + this.appDocument.files[0].extension;
        this.toast.success('Downloaded document');
        saveAs(result.body, fileName);
      }, err => {
        this.toast.error('Failed to generate document');
      });
  }
}
