import { Observable } from 'rxjs';
import { PackagerStatus } from '../../../../../../_base-shared/models/Status/PackagerStatus';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Status } from '../../../../../../_base-shared/models/Status/Status';

@Injectable({
  providedIn: 'root',
})

export class PackagerStatusService extends MainBaseApiService {
  index(data = {}): Observable<LaravelResourceResponse<PackagerStatus[]>> {
    return this.http.get<LaravelResourceResponse<Array<PackagerStatus>>>(`${ this.apiUrl }/packager-statuses`)
      .pipe(catchError(response => this.handleError(response)));
  }

  get(packagerStatusId: number): Observable<LaravelResourceResponse<PackagerStatus>> {
    return this.http.get<LaravelResourceResponse<PackagerStatus>>(`${ this.apiUrl }/packager-statuses/${ packagerStatusId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  store(status: Status): Observable<LaravelResourceResponse<PackagerStatus>> {
    return this.http.post<LaravelResourceResponse<PackagerStatus>>(`${ this.apiUrl }/packager-statuses`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(statusId: number, status: Status): Observable<LaravelResourceResponse<PackagerStatus>> {
    return this.http.put<LaravelResourceResponse<PackagerStatus>>(`${ this.apiUrl }/packager-statuses/${ statusId }`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  delete(statusId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/packager-statuses/${ statusId }`)
      .pipe(catchError(response => this.handleError(response)));
  }
}
