import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentTypeCategory } from '../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AppDocumentService extends MainBaseApiService {

  public index(caseId: number) {
    return this.http.get<LaravelResourceResponse<Array<AppDocumentTypeCategory>>>(
      this.apiUrl + '/cases/' + caseId + '/app-document-types',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public requestDocuments(caseId: number, formValue) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/app-document-requests', formValue,
    ).pipe(catchError(response => this.handleError(response)));
  }

  // TODO: move to client service
  public listClientDocumentRequests(caseUuid: string, userUuid: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/app-document-requests',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getAppDocument(documentUuid: string, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<AppDocument>>(
      this.apiUrl + '/app-documents/' + documentUuid, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public download(documentUuid: string, params?): Observable<HttpResponse<Blob>> {
    return this.http.get(this.apiUrl + '/app-documents/' + documentUuid + '/download', {
      params, observe: 'response', responseType: 'blob',
    }).pipe(catchError(response => this.handleError(response)));
  }
}
