import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../../_shared/guards/main-permission-guard.service';
import { DripCampaignEditorComponent } from './drip-campaign-editor/drip-campaign-editor.component';
import { DripCampaignListComponent } from './drip-campaign-list/drip-campaign-list.component';

const routes: Routes = [
  {
    path:     'drip-campaigns',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: DripCampaignListComponent},
      {
        path:        'create',
        canActivate: [MainPermissionGuard],
        component:   DripCampaignEditorComponent,
        data:        {type: 'create'},
      },
      {
        path:        ':id/edit',
        canActivate: [MainPermissionGuard],
        component:   DripCampaignEditorComponent,
        data:        {type: 'edit'},
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class DripCampaignRoutingModule {
}
