<form [formGroup]="form" (ngSubmit)="submitForm(form)">
  <h4 class="modal-title">{{ 'CASES.single.payments.update_amount_editor.change_payment_value' | translate }}</h4>
  <div class="form-group">
    <div class="pb-3">
      <app-input type="number" formControlName="amount" [label]="'PAYMENTS.change-value-new' |  translate"
                 [extraLabel]="false" [fullWidth]="true">
      </app-input>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeModal()">{{ "SHARED.close" | translate }}</button>
    <app-spinner-btn type="submit" [loading]="isSubmitting"
                     [name]="'CASES.single.payments.update_amount_editor.submit' | translate">
    </app-spinner-btn>
  </div>
</form>
