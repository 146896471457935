import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { PaymentService } from '../../../admin/payment/payment.service';
import { UploadService } from '../../../admin/app-file/upload.service';

@Component({
  selector:    'app-change-amount-modal',
  templateUrl: './change-amount-modal.component.html',
  styleUrls:   ['./change-amount-modal.component.scss'],
})
export class ChangeAmountModalComponent implements OnInit {
  public isLoading    = false;
  public form: UntypedFormGroup;
  public quillModules = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };

  constructor(private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<ChangeAmountModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toastr: ToastrService,
              private paymentService: PaymentService,
              private uploadService: UploadService,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      newValue: new UntypedFormControl(
          this.data.term.amount,
          [Validators.required, Validators.min(this.data.term.amount_paid), Validators.max(5000)]),
      split:    new UntypedFormControl('next_payment'),
      note:     new UntypedFormControl('', Validators.required),
    });
  }

  uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public changeValue() {
    if (this.form.valid) {
      const data     = {
        amount: this.form.value.newValue,
        type:   this.form.value.split,
        note:   this.form.value.note,
      };
      this.isLoading = true;
      this.paymentService.changeValue(this.data.term.id, data)
          .pipe(finalize(() => {
            this.isLoading = false;
          }))
          .subscribe(
              res => {
                this.toastr.success('Payment amount changed successfully', 'Change date success.');
                this.dialogRef.close(res);  //  Close dialog modal
              }, error => {
                this.toastr.error('Error changing amount. Please try again later.', 'Change date error.');
              });
    } else {
      //  If form is not valid
      //  trigger 'touched' event on all fields
      this.form.markAllAsTouched();
    }
  }
}
