import { NgModule } from '@angular/core';
import { CaseModule } from '../admin/case/case.module';
import { SharedAffiliateModule } from './_shared-affiliate/shared-affiliate.module';
import { AffiliateDashboardComponent } from './affiliate-dashboard/affiliate-dashboard.component';
import {  AffiliateCaseListComponent } from './affiliate-case-list/affiliate-case-list.component';
import { AffiliateUserRoutingModule } from './affiliate-user-routing.module';
import { AffiliateCaseListFiltersComponent } from './affiliate-case-list-filters/affiliate-case-list-filters.component';
import { AffiliateCreateLeadComponent } from './affiliate-create-lead/affiliate-create-lead.component';

@NgModule({
  imports:      [
    SharedAffiliateModule,
    CaseModule,
    AffiliateUserRoutingModule,
  ],
  declarations: [
    AffiliateDashboardComponent,
    AffiliateCaseListComponent,
    AffiliateCaseListFiltersComponent,
    AffiliateCreateLeadComponent,
  ],
  exports:      [
    // AffiliateDashboardComponent,
    AffiliateCaseListComponent
  ],
})
export class AffiliateUserModule {
}
