<div class="row">
  <div class="col-12">
    <h2>{{ 'CASES.single.creditors.heading' | translate }}</h2>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      + {{"CREDITORS.add-creditor" | translate}}
    </button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5 pb-3">
    <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
      <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-right-0">
              <i class="fal fa-search"></i>
          </span>
      </div>
      <input [formControl]="search"
             type="text"
             class="form-control border-left-0 bg-transparent pl-0"
             (focus)="toggleFocus(true)"
             (blur)="toggleFocus(false)"
             (change)="onChange()"
             [placeholder]="'SHARED.search' | translate">
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id, 'edit']"></tr>

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.name' | translate }}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.status" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element">
        <p class="m-0" *ngIf="element.active === 1">{{"AFFILIATES.active" | translate}}</p>
        <p class="m-0" *ngIf="element.active !== 1">{{"AFFILIATES.inactive" | translate}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="approved">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.approved" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element">
        <mat-icon class="{{ element.approved  ? 'text-success' : 'text-danger' }} mat-icon">
          {{ element.approved ? 'check' : 'clear' }}
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'USERS.model.email' | translate }}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone_1">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.phone-1" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.phone_1}} </td>
    </ng-container>

    <ng-container matColumnDef="phone_2">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.phone-2" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.phone_2}} </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.address" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="contact_1">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.contact-1" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.contact_person_1}} </td>
    </ng-container>

    <ng-container matColumnDef="contact_2">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.contact-2" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.contact_person_2}} </td>
    </ng-container>

    <ng-container matColumnDef="company_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CREDITORS.company" | translate}}</th>
      <td mat-cell class="cell" *matCellDef="let element"> {{element.company_name}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td class="cell" mat-cell *matCellDef="let element">
        <div style="display: flex">
          <a class="pr-3" routerLink="{{element.id}}/edit">{{"SHARED.edit" | translate}}</a>
          <a class="pr-3" href="#" (click)="openDeleteDialog(element, $event)">{{"SHARED.delete" | translate}}</a>
        </div>
      </td>
    </ng-container>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <mat-paginator
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="paginatorChange($event)"
    showFirstLastButtons
  ></mat-paginator>
</div>
