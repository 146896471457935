import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { DistributionService } from '../distribution.service';

@Component({
  selector:    'app-distribution-case-detail',
  templateUrl: './distribution-case-detail.component.html',
  styles:      [],
})
export class DistributionCaseDetailComponent implements OnInit {
  public case: Case;
  public isLoading = 0;

  constructor(private route: ActivatedRoute,
              private distributionService: DistributionService,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const caseUuid = params.get('uuid');
      this.distributionService.showCase(caseUuid).subscribe(result => {
        this.case = result.data;
      });
    });
  }

}
