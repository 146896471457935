import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import {
  PackagerConfigurationComponent
} from '../../packager/packager-detail/packager-configuration/packager-configuration.component';
import { PackagerDetailComponent } from '../../packager/packager-detail/packager-detail.component';
import {
  PackagerGeneralEditorComponent
} from '../../packager/packager-detail/packager-general-editor/packager-general-editor.component';
import { PackagerEditorComponent } from './packager-editor/packager-editor.component';
import { PackagerListComponent } from './packager-list/packager-list.component';

const routes: Routes = [
  {
    path: 'packagers', children: [
      {path: '', component: PackagerListComponent, canActivate: [MainAuthGuard]},
      {
        path:        'create',
        component:   PackagerEditorComponent,
        data:        {editorType: 'create'},
        canActivate: [MainAuthGuard]
      },
      {
        path:        ':id/edit',
        component:   PackagerEditorComponent,
        data:        {editorType: 'edit'},
        canActivate: [MainAuthGuard]
      },
      {
        path:        ':id/details',
        component:   PackagerDetailComponent,
        canActivate: [MainAuthGuard],
        children:    [
          {path: 'general', component: PackagerGeneralEditorComponent, canActivate: [MainAuthGuard]},
          {path: 'configuration', component: PackagerConfigurationComponent, canActivate: [MainAuthGuard]}
        ]
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminPackagerRoutingModule {
}
