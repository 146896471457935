import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { TownHall } from '../../../../../../../_base-shared/models/Entity/TownHall';
import { Address } from '../../../../../../../_base-shared/models/Location/Address';
import { TownHallService } from '../../town-hall.service';

@Component({
  selector:    'app-town-hall-editor',
  templateUrl: './town-hall-editor.component.html',
  styles:      [],
})
export class TownHallEditorComponent implements OnInit {
  public editorType: 'create' | 'edit';
  public isLoading = 0;
  public isSubmitting: boolean;
  public townHall: TownHall;
  public serverResponse: LaravelResourceResponse;
  public form: UntypedFormGroup;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private translate: TranslateService,
              private toastr: ToastrService,
              private townHallService: TownHallService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          const townHallId = +params.get('id');
          this.fetchTownHall(townHallId, true);
        });
      } else {
        const townHall = this.getDefaultTownHall();
        this.buildForm(townHall);
      }
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    const observable = this.editorType === 'edit' ?
        this.townHallService.update(this.townHall.id, form.value) :
        this.townHallService.store(form.value);

    this.isSubmitting = true;
    observable.pipe(finalize(() => this.isSubmitting = false)).subscribe(
        result => {
          this.townHall = result.data;
          this.editorType === 'create' ?
              this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                  {model: this.translate.instant('LEGAL_ENTITY.town_hall.model_name.singular')})) :
              this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
                  {model: this.translate.instant('LEGAL_ENTITY.town_hall.model_name.singular')}));
          this.router.navigate(['/town-halls']);
        },
        error => {
          this.serverResponse = error.error;
          this.editorType === 'create' ?
              this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
                  {model: this.translate.instant('LEGAL_ENTITY.town_hall.model_name.singular')})) :
              this.toastr.success(this.translate.instant('SHARED.submit_result.update.error',
                  {model: this.translate.instant('LEGAL_ENTITY.town_hall.model_name.singular')}));
        },
    );

  }

  private fetchTownHall(townHallId: number, buildForm: boolean = false) {
    this.isLoading++;
    this.townHallService.show(townHallId, ['address']).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.townHall = result.data;
          if (buildForm) {
            this.buildForm(this.townHall);
          }
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private buildForm(townHall: TownHall) {
    this.form = this.fb.group({
      name:    [townHall.name, [Validators.required]],
      email:   [townHall.email],
      phone:   [
        townHall.phone, [
          Validators.minLength(9),
          Validators.maxLength(9),
          Validators.pattern('(6|7|9)([0-9])\\w+'),
        ]],
      address: this.fb.group({
        country:      [townHall.address?.country],
        county:       [townHall.address?.county],
        region:       [townHall.address?.region],
        city:         [townHall.address?.city],
        municipality: [townHall.address?.municipality],
        postcode:     [townHall.address?.postcode],
        address_1:    [townHall.address?.address_1],
        address_2:    [townHall.address?.address_2],
      }),
    });
  }

  private getDefaultTownHall(): TownHall {
    const townHall           = new TownHall();
    townHall.name            = '';
    townHall.email           = '';
    townHall.phone           = '';
    townHall.address         = new Address();
    townHall.address.country = 'España';

    return townHall;
  }
}
