<h1 mat-dialog-title>{{'CASES.single.quick-note' | translate}} </h1>
<div mat-dialog-content class="custom-card-wrap">
  <form [formGroup]="form">
    <div class="form-group">
      <label for="type">{{ "CASES.single.select-quick-note" | translate }}</label>
      <select id="type" class="custom-select form-control" formControlName="type">
        <option value="note">{{ "CASES.single.notes" | translate }}</option>
        <option value="payment">{{ "CASES.single.payment-notes" | translate }}</option>
        <option value="draft">{{ "CASES.single.draft-notes" | translate }}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{"CONFIG.sms-template.content" | translate}}</label>
      <quill-editor
        formControlName="content"
        [modules]="quillModules"
        [styles]="{height: '350px'}">
      </quill-editor>
      <mat-error *ngIf="form.get('content').touched && form.get('content').errors?.required">
        {{"SHARED.field-required" | translate}}
      </mat-error>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <div class="mt-3">
    <button mat-button color="primary" class="mr-3" *ngIf="!executingPayment && !paymentResponse"
            (click)="onNoClick({reFetch: false})">{{'SHARED.cancel' | translate}}</button>
    <button mat-raised-button class="pay-button" color="primary" *ngIf="!executingPayment && !paymentResponse"
            (click)="onSubmit()">{{'SHARED.submit' | translate}}
    </button>
  </div>
</div>
