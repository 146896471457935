import { Component, OnInit } from '@angular/core';
import { AppBuild } from '../../../../../../_base-shared/models/AppBuild/AppBuild';

@Component({
  selector:    'app-changelog-list',
  templateUrl: './changelog-list.component.html',
  styles:      [],
})
export class ChangelogListComponent implements OnInit {
  public isLoading = 0;
  public appBuilds: Array<AppBuild>;

  constructor() {
  }

  ngOnInit(): void {
    this.fetchChangelog();
  }

  private fetchChangelog() {
    this.isLoading++;
    this.appBuilds = [
      {
        release_date: '2020-11-24 18:50',
        version:      '1.4.0',
        changelogs:   [
          {
            feature:       true,
            bugfix:        false,
            visible_agent: true,
            title:         'Feature: DEU-465 - Creditors section in Case Details',
          },
          {
            title:       'Feature: DEU-480 - Signature verification',
            description: 'When the Contract/Mandate is signed, and the DNI is uploaded, the agent needs to verify the signature is similar to the one on the DNI. There is a button on the Case Details > Document tab that allows this. If not verified, once the agent attempts to VERIFY & SEND MANDATE from the Creditors tab, he will be prompted to first verify the signature is correct. This action is needed only once.',
          },
          {
            title:       'Feature: DEU-481 - Email for mandate sending',
            description: 'When the Mandate email is sent - we send the Client DNI (both sides), the signed Mandate, and three static documents (Luis Miugel DNI, Authorisation and Incristo Registro).',
          },
          {
            title: 'Feature: DEU-468 - Add filters in Case Cetails > Creditors',
          },
          {
            title:       'Feature: DEU-482 - Additional doc for COFIDIS creditor',
            description: 'Additional attachment document that accompanies the other attachments when the mandate email is sent - only related to the debts with COFIDIS creditor.',
          },
          {
            title: 'Feature: DEU-501 - Add prompt for emails for public debts on sending mandates',
          },
          {
            title: 'Feature: DEU-467 - Add additional email fields for Creditors (Payments / Mandates / Proposals) and map the existing to General email.',
          },
          {
            title: 'Feature: DEU-496 - Add "Product" to Case Details with options "LSO" and "DM"',
          },
          {
            title: 'Feature: DEU-496 - Add "Product" column in Cases view',
          },
          {
            title: 'Feature: DEU-501 - Add prompt for emails for public debts on sending mandates',
          },
          {
            title: 'Feature: DEU-506 - Update all cases in predefined statuses to have "LSO" set as their Product',
          },
          {
            title: 'Feature: DEU-464 - Add Changelog',
          },
          {
            title: 'Change: DEU-499 - Update navbar item name from Creditor > Book Value to Creditor > Debt Value',
          },
          {
            title: 'Fix: DEU-466 - Change select options to Client 1/Client 2 in Creditor Ownership',
          },
          {
            title: 'Fix: DEU-489 - Show total amount charge on payment page fixes',
          },
          {
            title: 'Fix: DEU-498 - Sales leaderboard not removing canceled cases',
          },
          {
            title: 'Fix: DEU-497 - Creditor report to show active cases only',
          },
        ],
      },
    ];
    this.isLoading--;
  }
}
