import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from '../auth/forgot-password/forgot-password.component';
import { AffiliateCaseListComponent } from './affiliate-case-list/affiliate-case-list.component';
import { AffiliateCreateLeadComponent } from './affiliate-create-lead/affiliate-create-lead.component';
import { AffiliateDashboardComponent } from './affiliate-dashboard/affiliate-dashboard.component';

const routes: Routes = [
  {
    path:     'affiliate',
    children: [
      {path: 'dashboard', component: AffiliateDashboardComponent},
      {path: 'cases', component: AffiliateCaseListComponent, data: {filterType: 'affiliate'}},
      {path: 'leads/create', component: AffiliateCreateLeadComponent},
      //  Shared Auth Components
      {path: 'forgot-password', component: ForgotPasswordComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AffiliateUserRoutingModule {
}
