import { NgModule } from '@angular/core';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { SharedModule } from '../../_shared/shared.module';
import { TaskTemplateEditorComponent } from './task-template-editor/task-template-editor.component';
import { TaskTemplateListComponent } from './task-template-list/task-template-list.component';

import { TaskTemplateRoutingModule } from './task-template-routing.module';

@NgModule({
  declarations: [
    TaskTemplateListComponent,
    TaskTemplateEditorComponent,
  ],
  imports:      [
    SharedModule,
    MatPaginatorModule,
    TaskTemplateRoutingModule,
  ],
})
export class TaskTemplateModule {
}
