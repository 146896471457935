<h2 class="text-center">{{ 'NOTIFICATION.detail.title' | translate }}</h2>
<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<div *ngIf="!isLoading && notification">
  <div>
    <div class="task-card-background mt-2 mb-2">
      <h4>{{ 'NOTIFICATION.notification-modal.form.subject' | translate }}<b>: {{ notification.data.subject }}</b></h4>
      <h4>{{ 'NOTIFICATION.notification-modal.form.body' | translate }}:</h4>
      <div [innerHTML]="notification.data.message"></div>
    </div>
  </div>
  <div class="task-actions text-right">
    <button *ngIf="!notification.read_at" mat-raised-button color="primary" mat-dialog-close
            (click)="markAsRead(notification)">
      {{ 'NOTIFICATION.detail.actions.mark-as-read' | translate }}
    </button>
    <small *ngIf="notification.read_at">
      {{ "NOTIFICATION.list.table.read_at" | translate }} {{ notification.read_at | date:'dd/MM/yy HH:mm' }}
    </small>
  </div>
</div>
