import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DialerList } from '../../../../../../_base-shared/models/Dialer/DialerList';
import { Packager } from '../../../../../../_base-shared/models/Packager/Packager';
import { DialerService } from '../dialer.service';

@Component({
  selector:    'app-dialer-lists',
  templateUrl: './dialer-lists.component.html',
  styles:      ['.table th, .table td{padding: 0}'],
})
export class DialerListsComponent implements OnInit {
  public isLoading                  = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public packagers: Array<Packager> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialerService: DialerService,
  ) {
  }

  ngOnInit(): void {
    this.dialerService.indexLists({select_all: 1}).subscribe(result => {
      this.buildForm(result.data);
    });
  }

  private buildForm(dialerLists: Array<DialerList>) {
    this.form       = this.fb.group({
      dialer_lists: this.fb.array([]),
    });
    const formArray = this.getDialerListsArray();

    dialerLists.forEach(dialerList => {
      formArray.push(this.dialerListFormGroup(dialerList));
    });
  }

  public dialerListFormGroup(dialerList: DialerList): UntypedFormGroup {
    return this.fb.group({
      id:          [dialerList.id],
      name:        [dialerList.name, [Validators.required]],
      list_id:     [dialerList.list_id, [Validators.required]],
    });
  }

  public getDialerListsArray() {
    return this.form.get('dialer_lists') as UntypedFormArray;
  }

  public addNewDialerLIst() {
    const dialerList   = new DialerList();
    dialerList.name    = null;
    dialerList.list_id = null;

    const formArray = this.getDialerListsArray();
    formArray.push(this.dialerListFormGroup(dialerList));
  }

  public removeList(index) {
    this.getDialerListsArray().removeAt(index);
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.dialerService.upsertLists(form.getRawValue())
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        res => this.toastr.success('Dialer list saved'),
        err => {
          this.serverResponse = err.error;
          this.toastr.error('Dialer list could not save');
        },
      );
  }
}

