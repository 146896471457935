<div class="floating-note-body" *ngIf="showfloatingNote">
  <div class="note_toolbar">
    <button mat-icon-button (click)="toggleMinimizeNoteBody()">
      <mat-icon class="white-icon">{{ showNoteBody ? 'expand_more' : 'expand_less'}}</mat-icon>
    </button>
  </div>
  <div class="note-content" *ngIf="showNoteBody">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <form [formGroup]="form" class="form w-100" (ngSubmit)="submitForm(form)">
        <label class="form-label" for="note">
          {{ 'CASES.single.general.relation_list.notes.actions.create' | translate }}
        </label>
        <quill-editor id="note"  [modules]="quillModules" formControlName="note" [styles]="{height: '250px'}"></quill-editor>
        <app-input type="select" formControlName="type" appearance="standard"
                   [label]="'CASES.single.general.relation_list.notes.note_type' | translate"
                   [fullWidth]="true" style="z-index: 10" [selectOptions]="noteTypeOptions" [selectLabel]="'label'"
                   [selectValue]="'value'">
        </app-input>
        <div class="d-flex flex-row justify-content-end align-items-end">
          <button mat-button color="primary" class="mr-3"
                  (click)="cancelNote()">{{ 'SHARED.cancel' | translate }}</button>
          <app-spinner-btn type="submit" [loading]="isSubmitting" class="mr-2"
                           [name]="'SHARED.save' | translate">
          </app-spinner-btn>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="floating-button-container">
  <button mat-fab color="primary" (click)="toggleShowFloatingNote()">
    <mat-icon>add</mat-icon>
  </button>
</div>
