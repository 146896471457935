import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../_base-shared/contracts/laravel-response.interface';
import { CaseDistribution } from '../../../../_base-shared/models/Distribution/CaseDistribution';
import { DistributionBatch } from '../../../../_base-shared/models/Distribution/DistributionBatch';
import { PaymentStatus } from '../../../../_base-shared/models/Status/PaymentStatus';
import { MainBaseApiService } from '../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DistributionService extends MainBaseApiService {

  public indexPaymentStatuses() {
    return this.http.get<LaravelResourceResponse<Array<PaymentStatus>>>(
        this.apiUrl + '/distribution-provider/payment-statuses',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public showBatch(batchId: number, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations, with_count: relationsCount}, 'get');

    return this.http.get<LaravelResourceResponse<DistributionBatch>>(
        this.apiUrl + '/distribution-provider/distribution-batches/' + batchId, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexBatchDistributions(batchId: number, data: any, relations: Array<string> = [],
                                 relationsCount: Array<string>                        = []) {
    const params = MainBaseApiService.convertFiltersForRequest(
        {...data, with: relations, with_count: relationsCount}, 'get',
    );

    return this.http.get<LaravelResourceResponse<Array<CaseDistribution>>>(
        this.apiUrl + '/distribution-provider/distribution-batches/' + batchId + '/case-distributions', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

}
