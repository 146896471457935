<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-lg-6">
      <mat-card>
        <mat-card-header>
          <div class="text-center w-100">
            <h2>{{ "UNSUBSCRIBE.unsubscribe" | translate }}</h2>
          </div>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="isSubmitting" class="text-center mt-5">
            <mat-spinner class="m-0 m-auto" diameter="100" color="accent"></mat-spinner>
          </div>
          <form *ngIf="form && !unsubscribed" [hidden]="isSubmitting" [formGroup]="form"
                (ngSubmit)="submitForm(form)">
            <div class="row">
              <!--<div class="col-4">-->
              <!--  <mat-form-field appearance="outline" style="width: 95%">-->
              <!--    <mat-select formControlName="notification_channel_slug" (ngModelChange)="updateChannel($event)">-->
              <!--      <mat-option value="email">-->
              <!--        {{ 'UNSUBSCRIBE.notification_channel.options.email' | translate }}-->
              <!--      </mat-option>-->
              <!--      <mat-option value="sms">-->
              <!--        {{ 'UNSUBSCRIBE.notification_channel.options.sms' | translate }}-->
              <!--      </mat-option>-->
              <!--    </mat-select>-->
              <!--  </mat-form-field>-->
              <!--</div>-->
              <div class="col">
                <app-input *ngIf="form.get('notification_channel_slug').value === 'email'" type="email"
                           formControlName="email" appearance="outline" [label]="'UNSUBSCRIBE.email' | translate">
                </app-input>
                <app-input *ngIf="form.get('notification_channel_slug').value === 'sms'" type="number"
                           formControlName="phone" appearance="outline"
                           [label]="'UNSUBSCRIBE.phone' | translate" prefix="+34"
                           [patternError]="'CASES.single.mobile-invalid' | translate">
                </app-input>
              </div>
            </div>
            <!-- Submit -->
            <div class="text-center">
              <app-spinner-btn [loading]="isSubmitting" [name]="'UNSUBSCRIBE.unsubscribe' | translate">
              </app-spinner-btn>
            </div>
          </form>
          <div *ngIf="unsubscribed" class="text-center">
            {{ 'UNSUBSCRIBE.opt-out-success' | translate }}
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
