import { NgModule } from '@angular/core';
import { SharedModule } from '../../../_shared/shared.module';
import { DripCampaignEditorComponent } from './drip-campaign-editor/drip-campaign-editor.component';
import { DripCampaignListComponent } from './drip-campaign-list/drip-campaign-list.component';
import { DripCampaignRoutingModule } from './drip-campaign-routing.module';

@NgModule({
  declarations: [
    DripCampaignListComponent,
    DripCampaignEditorComponent,
  ],
  imports:      [
    SharedModule,
    DripCampaignRoutingModule,
  ],
})
export class DripCampaignModule {
}
