<div class="d-flex flex-row justify-content-between align-items-center">
  <h2>{{ 'CASES.single.general.relation_list.tasks.heading' | translate }}</h2>
  <button mat-raised-button color="primary" (click)="addTask(case)">
    + {{ 'CASES.single.general.relation_list.tasks.actions.create' | translate }}
  </button>
</div>
<div class="pt-4 pl-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !caseTasks?.length"></app-empty-dataset>
<div [hidden]="isLoading" class="case-tasks-list">
  <!--  <h2 class="text-center">{{ viewDate | toDateObject | date: 'medium' }}</h2>-->
  <div class="row">
    <div *ngFor="let task of caseTasks" class="col-lg-6 col-xl-4 position-relative" style="margin-bottom: 24px">
      <div class="task-card-background" (click)="openTask(task)" style="position: relative;min-height: 368px;">
        <app-task-preview [task]="task"></app-task-preview>
      </div>
      <div class="task-actions" style="position: absolute; bottom: 7px; right: 20px; z-index: 9">
        <button *ngIf="!task.completed_at" class="mr-1" mat-raised-button color="primary" (click)="confirmCompleteTask(task)">
          {{ 'TASK.detail.actions.confirm-complete.confirm-button' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="editTask(case, task)">
          {{ 'CASES.single.general.relation_list.tasks.actions.edit' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
