import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministratorsComponent } from './administrators.component';
import { CreateFormComponent } from '../_shared/create-form/create-form.component';

const routes: Routes = [
  {
    path    : 'administrators',
    children: [
      {path: '', component: AdministratorsComponent},
      {path: 'create', component: CreateFormComponent, data: {editorType: 'create', componentName: 'administrator'}},
      {path: ':id/edit', component: CreateFormComponent, data: {editorType: 'edit', componentName: 'administrator'}},
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class AdministratorsRoutingModule {
}
