<!-- Auto Task Templates -->
<div class="row">
  <div class="col-8">
    <h2>{{ 'TASK-TEMPLATE.list.header.auto' | translate }}</h2>
  </div>
  <div class="col-md-4 text-right">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      + {{ 'TASK-TEMPLATE.list.add-new' | translate }}
    </button>
  </div>
</div>
<!-- Table -->
<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="autoTaskTemplateSource">
    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header
          *matHeaderCellDef>{{ 'TASK-TEMPLATE.list.table-head.id' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.id }} </td>
    </ng-container>
    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }} </td>
    </ng-container>
    <!-- Active -->
    <ng-container matColumnDef="active">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.active' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.active" class="badge badge-primary">
          {{ 'TASK-TEMPLATE.list.table-data.active.true' | translate }}
        </span>
        <span *ngIf="!element.active" class="badge badge-danger">
          {{ 'TASK-TEMPLATE.list.table-data.active.false' | translate }}
        </span>
      </td>
    </ng-container>
    <!-- Statusable Type -->
    <ng-container matColumnDef="statusable_type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.statusable_type' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.statusable_type === 'status'">
          {{ 'TASK-TEMPLATE.list.table-data.statusable_type.status' | translate }}
        </span>
        <span *ngIf="element.statusable_type === 'payment_status'">
          {{ 'TASK-TEMPLATE.list.table-data.statusable_type.payment_status' | translate }}
        </span>
      </td>
    </ng-container>
    <!-- Statusable Name -->
    <ng-container matColumnDef="statusable">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.statusable' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary">{{ element.statusable?.name }}</span>
      </td>
    </ng-container>
    <!-- Assigned Users -->
    <ng-container matColumnDef="assigned_users">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.assigned_users' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.assigned_users?.length }}
      </td>
    </ng-container>
    <!-- Assigned Departments -->
    <ng-container matColumnDef="assigned_departments">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.assigned_departments' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.assigned_departments?.length }}
      </td>
    </ng-container>
    <!-- Delay -->
    <ng-container matColumnDef="delay">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.delay' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary">{{ element.delay }} {{ element.delay_unit }}</span>
      </td>
    </ng-container>
    <!-- Tasks Count -->
    <ng-container matColumnDef="tasks_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.tasks_count' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.tasks_count }}</span>
      </td>
    </ng-container>
    <!-- Task Template Notifications Count -->
    <ng-container matColumnDef="template_notifications_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.template_notifications_count' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.template_notifications_count }}</span>
      </td>
    </ng-container>
    <!-- Created At-->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.created_at | toDateObject | date }}</td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex">
          <p *ngFor="let action of actions" style="margin: 0; padding-right: 10px">
            <a *ngIf="action === 'Delete'" (click)="openDeleteDialog(element.id, $event)">
              {{ "SHARED.delete" | translate }}
            </a>
          </p>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        routerLink="{{'/task-templates/' + row.id + '/edit'}}"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">{{ "SHARED.loading" | translate }}...</span>
    </div>
  </div>

  <div *ngIf="!isLoading && autoTaskTemplateSource && !autoTaskTemplateSource.data.length" style="padding: 50px">
    <h3 class="text-center">{{ "TASK-TEMPLATE.list.no-data" | translate }}</h3>
  </div>

  <mat-paginator [length]="autoTaskTemplatePaginatorConfig.length" [pageSize]="autoTaskTemplatePaginatorConfig.pageSize"
                 [pageSizeOptions]="[5, 10, 20]"
                 (page)="paginatorChange($event, false)" showFirstLastButtons>
  </mat-paginator>
</div>

<!-- User Assisted Task Templates -->
<div class="row mt-5">
  <div class="col-8">
    <h2>{{ 'TASK-TEMPLATE.list.header.assisted' | translate }}</h2>
  </div>
  <div class="col-md-4 text-right">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      + {{ 'TASK-TEMPLATE.list.add-new' | translate }}
    </button>
  </div>
</div>
<!-- Table -->
<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="assistedTaskTemplateSource">
    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header
          *matHeaderCellDef>{{ 'TASK-TEMPLATE.list.table-head.id' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.id }} </td>
    </ng-container>
    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }} </td>
    </ng-container>
    <!-- Active -->
    <ng-container matColumnDef="active">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.active' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.active" class="badge badge-primary">
          {{ 'TASK-TEMPLATE.list.table-data.active.true' | translate }}
        </span>
        <span *ngIf="!element.active" class="badge badge-danger">
          {{ 'TASK-TEMPLATE.list.table-data.active.false' | translate }}
        </span>
      </td>
    </ng-container>
    <!-- Statusable Type -->
    <ng-container matColumnDef="statusable_type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.statusable_type' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.statusable_type === 'status'">
          {{ 'TASK-TEMPLATE.list.table-data.statusable_type.status' | translate }}
        </span>
        <span *ngIf="element.statusable_type === 'payment_status'">
          {{ 'TASK-TEMPLATE.list.table-data.statusable_type.payment_status' | translate }}
        </span>
      </td>
    </ng-container>
    <!-- Statusable Name -->
    <ng-container matColumnDef="statusable">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.statusable' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary">{{ element.statusable?.name }}</span>
      </td>
    </ng-container>
    <!-- Assigned Users -->
    <ng-container matColumnDef="assigned_users">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.assigned_users' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.assigned_users?.length }}
      </td>
    </ng-container>
    <!-- Assigned Departments -->
    <ng-container matColumnDef="assigned_departments">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.assigned_departments' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.assigned_departments?.length }}
      </td>
    </ng-container>
    <!-- Delay -->
    <ng-container matColumnDef="delay">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.delay' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary">{{ element.delay }} {{ element.delay_unit }}</span>
      </td>
    </ng-container>
    <!-- Tasks Count -->
    <ng-container matColumnDef="tasks_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.tasks_count' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.tasks_count }}</span>
      </td>
    </ng-container>
    <!-- Task Template Notifications Count -->
    <ng-container matColumnDef="template_notifications_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'TASK-TEMPLATE.list.table-head.template_notifications_count' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.template_notifications_count }}</span>
      </td>
    </ng-container>
    <!-- Created At-->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.created_at | toDateObject | date }}</td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex">
          <p *ngFor="let action of actions" style="margin: 0; padding-right: 10px">
            <a *ngIf="action === 'Delete'" (click)="openDeleteDialog(element.id, $event)">
              {{ "SHARED.delete" | translate }}
            </a>
          </p>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        routerLink="{{'/task-templates/' + row.id + '/edit'}}"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">{{ "SHARED.loading" | translate }}...</span>
    </div>
  </div>

  <div *ngIf="!isLoading && assistedTaskTemplateSource && !assistedTaskTemplateSource.data.length"
       style="padding: 50px">
    <h3 class="text-center">{{ "TASK-TEMPLATE.list.no-data" | translate }}</h3>
  </div>

  <mat-paginator [length]="assistedTaskTemplatePaginatorConfig.length" [pageSizeOptions]="[5, 10, 20]"
                 [pageSize]="assistedTaskTemplatePaginatorConfig.pageSize"
                 (page)="paginatorChange($event, true)" showFirstLastButtons>
  </mat-paginator>
</div>
