import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { PaymentTerm } from '../../../../../../../../_base-shared/models/Payment/PaymentTerm';
import { PaymentService } from '../../../../payment/payment.service';

@Component({
  selector:    'app-case-installment-amount-editor',
  templateUrl: './case-installment-amount-editor.component.html',
  styles:      [],
})
export class CaseInstallmentAmountEditorComponent implements OnInit {
  public form: UntypedFormGroup;
  public isSubmitting = false;
  public installment: PaymentTerm;

  constructor(private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<CaseInstallmentAmountEditorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toastr: ToastrService,
              private translate: TranslateService,
              private paymentService: PaymentService,
  ) {
  }

  ngOnInit(): void {
    this.installment = this.data.installment;
    this.buildForm(this.installment);
  }

  public buildForm(installment: PaymentTerm) {
    this.form = this.fb.group({
      amount: new UntypedFormControl(
        installment.amount,
        [Validators.required, Validators.min(installment.amount_paid), Validators.max(5000)]),
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.paymentService.changeAdditionalFeeValue(this.installment.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        response => {
          this.toastr.success(this.translate.instant('CASES.single.payments.update_amount_editor.response.success'));
          this.dialogRef.close(response.data);
        },
        () => this.toastr.error(
          this.translate.instant('CASES.single.payments.update_amount_editor.response.error')),
      );
  }

}
