<div class="d-flex justify-content-center ml-3">
  <a [attr.data-count]="unreadCount" [matMenuTriggerFor]="notificationsMenu"
     class="clickable nav-link notification d-flex align-items-center justify-content-center"
     [class.notify]="notify" [class.show-count]="showCount"></a>

  <mat-menu #notificationsMenu="matMenu" class="notificationsMenu">
    <div class="dropdown-header d-flex flex-row px-2 px-md-4 py-4 rounded-top justify-content-between">
      <div class="info-card-text">
        <div class="fs-lg text-truncate text-truncate-lg" style="color: #000000">Notifications</div>
      </div>
      <div class="mr-0 mr-md-2" *ngIf="notifications.length">
        <button mat-button (click)="markAllAsRead($event)">Mark all as read</button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <ng-container *ngFor="let notification of notifications">
      <button mat-menu-item (click)="openModal($event, notification)">
        <div>
          <p class="m-0">{{ notification.data.subject }}</p>
        </div>
        <button mat-icon-button class="clickable" (click)="markAsRead($event, notification)">
          <mat-icon>done</mat-icon>
        </button>
      </button>
    </ng-container>

    <!-- No notifications -->
    <div *ngIf="!notifications.length">
      <p class="m-0 text-center p-2">No unread notifications</p>
    </div>

    <mat-divider></mat-divider>

    <div class="text-center p-2">
      <a routerLink="/case-dashboard/notifications">See all notifications</a>
    </div>
  </mat-menu>

  <audio #sound>
    <source src="/assets/img/swiftly-610.mp3" type="audio/mpeg"/>
  </audio>

</div>
