<h2 class="text-center">{{ 'TASK.detail.title' | translate }}</h2>
<div class="text-right">
  <button mat-raised-button color="primary" (click)="editTask(task)">
    {{ "SHARED.edit" | translate }}
  </button>
</div>
<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<div *ngIf="!isLoading && task">
  <div>
    <div class="task-card-background mt-2 mb-2">
      <app-task-preview [task]="task"></app-task-preview>
    </div>
    <h4 *ngIf="task.notes">{{ 'TASK.detail.notes' | translate }}</h4>
    <div [innerHTML]="task.notes"></div>
    <form *ngIf="form" [formGroup]="form">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'TASK-TEMPLATE.editor.completion_note' | translate }}</mat-label>
        <textarea matInput formControlName="completion_note" cdkTextareaAutosize rows="5" style="max-height: 358px"></textarea>
        <div *ngIf="form.get('completion_note').errors && (form.get('completion_note').touched || formSubmitted)">
          <mat-error [hidden]="!form.get('completion_note').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
        <small *ngIf="task.require_completion_note">{{ 'TASK.detail.require_completion_note_info' | translate }}</small>
      </mat-form-field>
    </form>
  </div>
  <div class="task-actions text-right">
    <button *ngIf="!task.completed_at" mat-raised-button color="primary" (click)="confirmCompleteTask(task, form)">
      {{ 'TASK.detail.actions.confirm-complete.confirm-button' | translate }}
    </button>
    <button class="ml-4" mat-raised-button color="primary" mat-dialog-close (click)="viewCase(task)">
      {{ 'TASK.detail.actions.view-case' | translate }}
    </button>
  </div>
</div>
