import { NgModule } from '@angular/core';
import { SharedModule } from '../../../_shared/shared.module';
import { NotificationConfiguratorComponent } from './notification-configurator/notification-configurator.component';
import { NotificationEditorComponent } from './notification-editor/notification-editor.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationTriggersListComponent } from './notification-triggers-list/notification-triggers-list.component';

@NgModule({
  declarations: [
    NotificationListComponent,
    NotificationEditorComponent,
    NotificationConfiguratorComponent,
    NotificationTriggersListComponent,
  ],
  imports:      [
    SharedModule,
    NotificationRoutingModule,
  ],
})
export class NotificationModule {
}
