import { Component, OnInit } from '@angular/core';
import { NotificationFromEvent } from '../../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NotificationEventService } from '../notification-event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification-editor',
  templateUrl: './notification-editor.component.html',
  styleUrls: ['./notification-editor.component.scss']
})
export class NotificationEditorComponent implements OnInit {

  public notificationForm;
  public loading                    = true;
  public notificationId                      = 0;
  public notification: NotificationFromEvent = null;
  public newNotification                     = true;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private notificationService: NotificationEventService,
    private translate: TranslateService,
  ) {
    this.notificationForm = this.formBuilder.group({
      title: '',
      body: '',
      type: 'info',
      sends_via: 'email'
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.notificationId = +params.get('id');
      this.fetchNotification();
    });
  }

  fetchNotification() {
    if (this.notificationId === 0) {
      this.loading = false;
      return;
    }
    this.newNotification = false;
    this.notificationService.get(this.notificationId)
      .subscribe(res => {
        this.loading      = false;
        this.notification = res.data;
        this.populateForm(res.data);
      });
  }

  populateForm(data) {
    this.notificationForm.setValue({
      title: data.title,
      body: data.body,
      type: data.type,
    });
  }

  onSubmit() {
    if (this.newNotification) {
      const newAffiliate: NotificationFromEvent = {
        title: this.notificationForm.value.title,
        body: this.notificationForm.value.body,
        type: this.notificationForm.value.type,
        sends_via: this.notificationForm.value.sends_via,
      };
      this.notificationService.store(newAffiliate)
        .subscribe(
          res => {
            if (res.code === 200) {
              this.router.navigateByUrl('/notification');
              this.toastr.success(
                this.translate.instant('CONFIG.notifications.add-notification-success'),
                this.translate.instant('SHARED.success'));
            }
          },
          error => {
            console.log(error);
            this.toastr.error(
              this.translate.instant('CONFIG.notifications.add-notification-error'),
              this.translate.instant('SHARED.error'));
          });
    } else {
      this.notification.title = this.notificationForm.value.title;
      this.notification.body  = this.notificationForm.value.body;
      this.notification.type  = this.notificationForm.value.type;
      this.notification.sends_via  = this.notificationForm.value.sends_via;
      this.notificationService.update(this.notification)
        .subscribe(
          res => {
            if (res.code === 200) {
              this.router.navigateByUrl('/notification');
              this.toastr.success(
                this.translate.instant('CONFIG.notifications.edit-notification-success'),
                this.translate.instant('SHARED.success'));
            }
          },
          error => {
            this.toastr.error(
              this.translate.instant('CONFIG.notifications.edit-notification-error'),
              this.translate.instant('SHARED.error'));
          });
    }
  }

}
