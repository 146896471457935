import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { ProfileEditorComponent } from './user-profile-settings/profile-editor/profile-editor.component';
import { UserConnectionsComponent } from './user-profile-settings/user-connections/user-connections.component';
import { UserProfileSettingsComponent } from './user-profile-settings/user-profile-settings.component';
import { UserSecurityComponent } from './user-profile-settings/user-security/user-security.component';
import { UserRoutingModule } from './user-routing.module';
import { ProfileDetailsComponent } from './user-profile-settings/profile-details/profile-details.component';

@NgModule({
  imports: [
    SharedModule,
    UserRoutingModule,
    AuthModule,
  ],
  declarations: [
    UserProfileSettingsComponent,
    ProfileEditorComponent,
    UserSecurityComponent,
    UserConnectionsComponent,
    ProfileDetailsComponent,
  ],
})
export class UserModule {
}
