import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../user/user.service';
import {finalize} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-case-user-password-modal',
  templateUrl: './case-user-password-modal.component.html',
  styleUrls: ['./case-user-password-modal.component.scss']
})
export class CaseUserPasswordModalComponent implements OnInit {
  public isLoading = 0;
  public form: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private userService: UserService,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<CaseUserPasswordModalComponent>
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      password: new UntypedFormControl('', Validators.required)
    });
  }

  public submit() {
    if (this.form.invalid) {
      return;
    }

    this.isLoading++;
    this.userService.changePassword(this.data.userId, this.data.caseId, this.form.value.password)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.toastr.success(this.translate.instant('CLIENT.password-changed'));
          this.dialogRef.close(res);
        }, err => {
          this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        }
      );
  }
}
