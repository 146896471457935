import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../_shared/services/main-global-event.service';

@Component({
  selector:    'app-dashboard-wrapper',
  templateUrl: './dashboard-wrapper.component.html',
  styles:      [],
})
export class DashboardWrapperComponent implements OnInit {
  public authUser: User;

  constructor(private router: Router,
              private globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.authUser?.role.slug === 'distribution-provider') {
        this.router.navigate(['/distribution-provider', 'batches']);
      }
    });
  }
}
