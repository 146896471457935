import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../../_shared/guards/main-permission-guard.service';
import { SystemEventEditorComponent } from './system-event-editor/system-event-editor.component';

const routes: Routes = [
  {
    path:     'system-event',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: SystemEventEditorComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class SystemEventRoutingModule {
}
