import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PackagerStatusEditorComponent } from './packager-status-editor/packager-status-editor.component';
import { PackagerStatusListComponent } from './packager-status-list/packager-status-list.component';

const routes: Routes = [
  {
    path: 'packager-statuses',
    children: [
      {path: '', canActivate: [], component: PackagerStatusListComponent},
      {path: 'create', canActivate: [], component: PackagerStatusEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [], component: PackagerStatusEditorComponent, data: {editor: 'edit'}}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackagerStatusRoutingModule { }
