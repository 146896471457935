import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { AepDocument } from '../../../../../../../../../_base-shared/models/Case/AepDocument';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-case-aep-list',
  templateUrl: './case-aep-list.component.html',
  styles:      [],
})
export class CaseAepListComponent implements OnInit {
  @Input() case: Case;
  public caseAepDocuments: Array<AepDocument> = [];
  public isLoading                            = 0;
  public storageUrl                           = environment.STORAGE_URL + '/';

  constructor(private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.loadCaseAeps(this.case);
  }

  private loadCaseAeps(clientCase: Case) {
    this.isLoading++;
    this.caseService.getAepHistory(clientCase.id).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.caseAepDocuments = result.data);
  }
}
