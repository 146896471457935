import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
  ],
  imports:      [
    SharedModule,
    AuthRoutingModule,
  ],
  exports:      [
    NewPasswordComponent,
    LoginComponent,
    ForgotPasswordComponent,
  ],
})

export class AuthModule {
}
