import { Department } from '../Department/Department';
import { AppFile } from '../File/AppFile';
import { User } from '../User/User';
import { DripCampaign } from './DripCampaign';

export class DripNotification {
  id?: number;
  drip_campaign_id?: number;
  fromable_type?: string;
  fromable_id?: number;
  sendgrid_template_id?: string;

  bcc: Array<string>;
  subject?: string;
  body: string;
  channel: 'email' | 'sms';
  delay: number;
  delay_unit: 'second' | 'minute' | 'hour' | 'day';

  created_at: string | Date;
  updated_at: string | Date;

  drip_campaign?: DripCampaign;
  fromable?: User | Department;
  attachments?: Array<AppFile>;
}
