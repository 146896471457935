import { NgModule } from '@angular/core';
import { SharedModule } from '../../../_shared/shared.module';
import { PackagerStatusEditorComponent } from './packager-status-editor/packager-status-editor.component';
import { PackagerStatusListComponent } from './packager-status-list/packager-status-list.component';

import { PackagerStatusRoutingModule } from './packager-status-routing.module';


@NgModule({
  declarations: [
    PackagerStatusListComponent,
    PackagerStatusEditorComponent
  ],
  imports:      [
    SharedModule,
    PackagerStatusRoutingModule
  ]
})
export class PackagerStatusModule {
}
