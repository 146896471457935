import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TaskEditorComponent } from './task-editor/task-editor.component';
import { TaskListComponent } from './task-list/task-list.component';

const routes: Routes = [
  {path: 'tasks', component: TaskListComponent},
  {path: 'tasks/create', component: TaskEditorComponent, data: {editorType: 'create'}},
  {path: 'tasks/:id', component: TaskDetailComponent},
  {path: 'tasks/:id/edit', component: TaskEditorComponent, data: {editorType: 'edit'}},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class TaskRoutingModule {
}
