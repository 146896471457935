import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Packager } from '../../../../../_base-shared/models/Packager/Packager';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminPackagerService extends MainBaseApiService {
  public index(requestData, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData, with: relations, with_count: relationsCount}, 'get',
    );
    return this.http.get<LaravelResourceResponse<Array<Packager>>>(this.apiUrl + '/packagers', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public show(modelId: number, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations, with_count: relationsCount}, 'get');

    return this.http.get<LaravelResourceResponse<Packager>>(this.apiUrl + '/packagers/' + modelId, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public store(data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.post<LaravelResourceResponse<Packager>>(this.apiUrl + '/packagers', data, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public update(modelId: number, data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.put<LaravelResourceResponse<Packager>>(this.apiUrl + '/packagers/' + modelId, data, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public delete(modelId: number) {
    return this.http.delete<LaravelResourceResponse<Packager>>(this.apiUrl + '/packagers/' + modelId)
      .pipe(catchError(response => this.handleError(response)));
  }
}
