<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h3>{{clientRole === 'client' ?
          ('CASES.case-snapshot.client.client' | translate) :
          ('CASES.case-snapshot.partner' | translate)}}</h3>
      </div>
    </div>
  </div>
  <div class="card-body" style="padding-top: 0;padding-bottom: 0px;">
    <div class="row h-100">
      <ng-container>
        <div class="col" style="border-right: 1px solid #e9e9e9;">
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.first_name' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.first_name}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.last_name' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.last_name}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.email' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.email}}</p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'CASES.single.address' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.address}}</p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.phone' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.phone_country_code}} {{clientSnapshot.phone}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.home_phone' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.home_phone}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.work_phone' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.work_phone}}
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.id_card' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.id_card}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.date_of_birth' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.date_of_birth}}
              </p>
            </div>
          </div>
        </div>
        <div class="col" style="border-right: 1px solid #e9e9e9;">
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.employment.label' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{"USERS.model.employment.options." +
              clientSnapshot.employment | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.home_owner.label' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{"USERS.model.home_owner.options." +
              clientSnapshot.home_owner | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.nationality' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.nationality}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.debt_aware' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{(clientSnapshot.debt_aware === true ?
                'SHARED.yes' :
                'SHARED.no') | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.marriage_city' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.marriage_city}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.vulnerability.label' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{"USERS.model.vulnerability.options." +
              clientSnapshot.vulnerability | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.marital_status.label' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{"USERS.model.marital_status.options." +
              clientSnapshot.marital_status | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.economic_regime.label' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{"USERS.model.economic_regime.options." +
              clientSnapshot.economic_regime | translate}}
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'CASES.case-snapshot.client.time_to_contact' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.time_to_contact}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'CASES.case-snapshot.client.phone_country_code' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.phone_country_code}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.number_of_dependent' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.number_of_dependent}}
              </p>
            </div>
          </div>

          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'USERS.model.relation_to_applicant.label' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{clientSnapshot.relation_to_applicant}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'CASES.model.lso_qualifying_questions.lso_in_five_years' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{(clientSnapshot.lso_in_five_years === true ?
                'SHARED.yes' :
                'SHARED.no') | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'CASES.model.lso_qualifying_questions.spanish_resident' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{(clientSnapshot.spanish_resident === true ?
                'SHARED.yes' :
                'SHARED.no') | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'CASES.model.lso_qualifying_questions.public_sanctions' | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{(clientSnapshot.public_sanctions === true ?
                'SHARED.yes' :
                'SHARED.no') | translate}}
              </p>
            </div>
          </div>
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{"CASES.case-snapshot.client.derivation_of_responsibility" | translate }}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{(clientSnapshot.derivation_of_responsibility === true ?
                'SHARED.yes' :
                'SHARED.no') | translate}}
              </p>
            </div>
          </div>

          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{'CASES.model.lso_qualifying_questions.criminal_record' | translate }}
            </mat-label>
            <div class="col-6">
              <p class="text-right"
                 style="padding-top: 7px;">{{(clientSnapshot.criminal_record === true ?
                'SHARED.yes' :
                'SHARED.no') | translate}}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
