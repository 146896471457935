import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckIfComponentIsDirty } from '../../../_base-shared/guards/check-if-comopnent-dirty.guard';
import { MainAuthGuard } from './_shared/guards/main-auth-guard.service';
import { MainPermissionGuard } from './_shared/guards/main-permission-guard.service';
import { UserSettingsComponent } from './admin/user/user-settings/user-settings.component';
import { DashboardWrapperComponent } from './dashboard-wrapper/dashboard-wrapper.component';

const routes: Routes = [
  // { path: '**', component: PageNotFoundComponent }
  {path: '', canActivate: [MainAuthGuard], component: DashboardWrapperComponent},
  {path: 'user-settings', component: UserSettingsComponent}, // TODO: move from root
];

@NgModule({
  imports:   [RouterModule.forRoot(routes, {})],
  exports:   [RouterModule],
  providers: [MainAuthGuard, MainPermissionGuard, CheckIfComponentIsDirty],
})
export class AppRoutingModule {
}
