import { NgModule } from '@angular/core';
import { SharedModule as BaseSharedModule } from '../../_shared/shared.module';

@NgModule({
  imports:      [
    BaseSharedModule,
  ],
  declarations: [],
  exports:      [
    BaseSharedModule,
  ],
})
export class SharedPublicModule {
}
