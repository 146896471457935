import { Component, OnInit } from '@angular/core';

@Component({
  selector:    'app-dialer',
  templateUrl: './dialer.component.html',
  styles:      [],
})
export class DialerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
