import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { BankAccount } from '../../../../../../../../_base-shared/models/Payment/BankAccount';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { UserPaymentService } from '../../../user-payment.service';

@Component({
  selector:    'app-bank-account-editor',
  templateUrl: './bank-account-editor.component.html',
  styles:      [],
})
export class BankAccountEditorComponent implements OnInit {
  @Input() user: User;

  public isLoading = 0;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public userBankAccounts: Array<BankAccount>;
  public submitting: boolean;

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private userPaymentService: UserPaymentService,
  ) {
  }

  ngOnInit(): void {
    this.fetchBankAccounts(this.user.id);
  }

  private buildForm(bankAccount: BankAccount) {
    this.form = this.fb.group({
      id:           [{value: bankAccount.id, disabled: true}],
      iban:         [
        bankAccount.iban,
        [Validators.required, Validators.minLength(24), Validators.maxLength(24)],
      ],
      bank_name:    [bankAccount.bank_name, [Validators.required]],
      bank_address: [bankAccount.bank_address],
      default:      [1, [Validators.required]],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    this.formSubmitted = true;
    if (form.invalid) {
      return;
    }

    const bankAccountId = this.form.get('id').value;
    let observable: Observable<LaravelResourceResponse<BankAccount>>;

    if (!bankAccountId) {
      observable = this.userPaymentService.storeBankAccount(this.user.id, form.value);
    } else {
      observable = this.userPaymentService.updateBankAccount(this.user.id, bankAccountId, form.value);
    }

    this.submitting = true;
    observable.pipe(finalize(() => this.submitting = false)).subscribe(result => {
      const index                  = this.userBankAccounts.findIndex(bankAccount => !!bankAccount.default === true);
      this.userBankAccounts[index] = result.data;
      this.toastr.success('Updated bank account');
    });
  }

  private fetchBankAccounts(userId: number) {
    this.isLoading++;
    this.userPaymentService.indexBankAccounts(userId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.userBankAccounts = result.data;
      let defaultAccount    = this.userBankAccounts.find(bankAccount => !!bankAccount.default === true);
      defaultAccount        = defaultAccount ? defaultAccount : new BankAccount();
      this.buildForm(defaultAccount);
    });
  }
}
