import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { TownHall } from '../../../../../_base-shared/models/Entity/TownHall';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TownHallService extends MainBaseApiService {
  public index(requestData, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<TownHall>>>(this.apiUrl + '/town-halls', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public show(townHallId: number, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<TownHall>>(this.apiUrl + '/town-halls/' + townHallId, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public store(data) {
    return this.http.post<LaravelResourceResponse<TownHall>>(this.apiUrl + '/town-halls', data)
        .pipe(catchError(response => this.handleError(response)));
  }

  public update(townHallId: number, data) {
    return this.http.put<LaravelResourceResponse<TownHall>>(this.apiUrl + '/town-halls/' + townHallId, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  public delete(townHallId: number) {
    return this.http.delete<LaravelResourceResponse<TownHall>>(this.apiUrl + '/town-halls/' + townHallId)
        .pipe(catchError(response => this.handleError(response)));
  }
}
