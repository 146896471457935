<h2>{{"NAVBAR.missing-documents" | translate}}</h2>
<div [formGroup]="form" class="row">
  <div class="col-4">
    <mat-form-field class="example-form-field" style="width: 100%">
      <mat-label>{{"MISSING-DOCS.min-amount" | translate}}</mat-label>
      <input matInput type="number" formControlName="value">
      <button mat-button *ngIf="form.value.value" matSuffix mat-icon-button aria-label="Clear" (click)="resetValue()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="offset-4"></div>
  <div class="col-md-4 text-right">
    <button type="button" mat-raised-button color="primary" class="mr-2" (click)="getSimpleExport()">
      {{ "REPORTS.simple-export" | translate }}
      <span *ngIf="simpleExportLoading" class="spinner-border spinner-border-sm text-white" role="status">
        <span class="sr-only">Loading...</span>
      </span>
    </button>
    <button type="button" mat-raised-button color="primary" (click)="getAdvancedExport()">
      {{ "REPORTS.advanced-export" | translate }}
      <span *ngIf="exportLoading" class="spinner-border spinner-border-sm text-white" role="status">
        <span class="sr-only">Loading...</span>
      </span>
    </button>
  </div>
</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" style="width: 100%;">
    <ng-container matColumnDef="ref_number">
      <th mat-header-cell *matHeaderCellDef>DF-REF</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/cases/{{element.id}}"> {{element.ref_number}} </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>{{ "MISSING-DOCS.name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>{{ "MISSING-DOCS.surname" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
    </ng-container>

    <ng-container matColumnDef="missing_files">
      <th mat-header-cell *matHeaderCellDef>{{ "MISSING-DOCS.num-doc-missing" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.missing_files}} </td>
    </ng-container>

    <ng-container matColumnDef="pending_files">
      <th mat-header-cell *matHeaderCellDef>{{ "MISSING-DOCS.num-doc-pending" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.pending_files}} </td>
    </ng-container>

    <ng-container matColumnDef="status_name">
      <th mat-header-cell *matHeaderCellDef>{{ "MISSING-DOCS.sub-status" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.status_name}} </td>
    </ng-container>

    <ng-container matColumnDef="payment_status_name">
      <th mat-header-cell *matHeaderCellDef>{{ "MISSING-DOCS.payment-status" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.payment_status_name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading && !dataSource.data.length" style="padding: 50px">
    <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
  </div>

  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize"
                 [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)" showFirstLastButtons>
  </mat-paginator>
</div>
