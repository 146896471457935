import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GmailAuthorizationCallbackComponent } from './gmail-authorization-callback/gmail-authorization-callback.component';
import { MymoidMotoErrorComponent } from './mymoid/mymoid-moto-error/mymoid-moto-error.component';
import { MymoidMotoIFrameRedirectComponent } from './mymoid/mymoid-moto-iframe-redirect/mymoid-moto-iframe-redirect.component';
import { MymoidMotoSuccessComponent } from './mymoid/mymoid-moto-success/mymoid-moto-success.component';

const routes: Routes = [
  {
    path:     'authorization-callbacks',
    children: [
      {path: 'gmail', canActivate: [], component: GmailAuthorizationCallbackComponent},
      {
        path: 'mymoid-moto', children: [
          {path: 'success', component: MymoidMotoSuccessComponent},
          {path: 'error', component: MymoidMotoErrorComponent},
          {
            path: 'iframe', children: [
              {path: 'success', component: MymoidMotoIFrameRedirectComponent, data: {success: true}},
              {path: 'error', component: MymoidMotoIFrameRedirectComponent, data: {success: false}},
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class IntegrationRoutingModule {
}
