import { NgModule } from '@angular/core';
import { SharedModule } from '../../../_shared/shared.module';
import { TownHallListComponent } from './town-hall-list/town-hall-list.component';
import { TownHallRoutingModule } from './town-hall-routing.module';
import { TownHallEditorComponent } from './town-hall-editor/town-hall-editor.component';

@NgModule({
  imports:      [
    SharedModule,
    TownHallRoutingModule,
  ],
  declarations: [
    TownHallListComponent,
    TownHallEditorComponent,
  ],
})
export class TownHallModule {
}
