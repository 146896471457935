import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DatabaseNotification } from '../../../../../../_base-shared/models/Notification/DatabaseNotification';
import { NotificationService } from '../notification.service';

@Component({
  selector:    'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styles:      [],
})
export class NotificationDetailComponent implements OnInit {
  public notification: DatabaseNotification;
  public isLoading = 0;

  constructor(private router: Router,
              private route: ActivatedRoute,
              // public dialogRef: MatDialogRef<TaskDetailComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private translateService: TranslateService,
              private toastr: ToastrService,
              private notificationService: NotificationService) {
    this.data = data ? data : null;
  }

  ngOnInit(): void {
    if (this.data && this.data.notification) {
      // TODO: check if relations are missing and load if so
      this.notification = this.data.notification;
    } else {
      this.route.paramMap.subscribe(params => this.fetchNotification(params.get('id')));
    }
  }

  private fetchNotification(notificationId: string) {
    this.isLoading++;
    this.notificationService.show(notificationId).pipe(finalize(() => this.isLoading--))
        .subscribe(res => this.notification = res.data);
  }

  public markAsRead(notification: DatabaseNotification) {
    this.notificationService.markAsRead({notification_ids: [notification.id]}).subscribe(
        result => {
          if (result.data && result.data.length === 1) {
            this.notification = result.data[0];
          } else {
            this.notification.read_at = new Date();
          }
        },
    );
  }
}
