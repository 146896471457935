import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TownHallModule } from './town-hall/town-hall.module';

@NgModule({
  imports:      [
    CommonModule,
    TownHallModule,
  ],
  declarations: [],
})
export class LegalEntityModule {
}
