import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../../_shared/guards/main-permission-guard.service';
import { EmailEditorComponent } from './email-editor/email-editor.component';
import { EmailListComponent } from './email-list/email-list.component';

const routes: Routes = [
  {
    path:     'email',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: EmailListComponent},
      {path: 'create', canActivate: [MainPermissionGuard], component: EmailEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [MainPermissionGuard], component: EmailEditorComponent, data: {editor: 'edit'}},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class EmailRoutingModule {
}
