<h1 mat-dialog-title>
  <span *ngIf="templatablesCount <= 1">
    {{ "NOTIFICATION.notification-modal.heading" | translate }}
  </span>
  <span *ngIf="templatablesCount > 1">
    {{ "NOTIFICATION.notification-modal.heading-plural" | translate:{count: templatablesCount} }}
  </span>
</h1>
<mat-dialog-content>
  <mat-spinner *ngIf="isLoading" diameter="40" class="m-0 m-auto"></mat-spinner>
  <form *ngIf="form" [formGroup]="form" #ngForm>
    <div class="row">
      <!-- Channels -->
      <div class="col">
        <mat-form-field color="primary" appearance="standard" class="input-full-width">
          <mat-label>{{ "NOTIFICATION.notification-modal.form.channels.label" | translate }}</mat-label>
          <mat-select formControlName="channels" multiple (selectionChange)="channelChanged($event)">
            <mat-option value="email">
              {{ "NOTIFICATION.notification-modal.form.channels.options.email" | translate }}
            </mat-option>
            <mat-option value="sms">
              {{ "NOTIFICATION.notification-modal.form.channels.options.sms" | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Templates -->
      <div class="col">
        <!-- No Templates -->
        <app-input *ngIf="!form.get('channels').value?.length || form.get('channels').value?.length === 2"
                   type="select" formControlName="template_id" appearance="standard"
                   (ngModelChange)="templateChanged($event, 'email_template')"
                   [fullWidth]="true"
                   [label]="'SHARED.custom' | translate ">
        </app-input>
        <!-- Email Templates -->
        <app-input *ngIf="form.get('channels').value?.length === 1 && form.get('channels').value[0] === 'email'"
                   type="select" formControlName="template_id" appearance="standard"
                   (ngModelChange)="templateChanged($event, 'email_template')" [showClear]="true"
                   [searchable]="true" [fullWidth]="true" [selectOptions]="emailTemplates"
                   [selectLabel]="'label'" [label]="'SHARED.custom' | translate " [selectValue]="'id'">
        </app-input>
        <!-- SMS Templates -->
        <app-input *ngIf="form.get('channels').value?.length === 1 && form.get('channels').value[0] === 'sms'"
                   type="select" formControlName="template_id" appearance="standard"
                   (ngModelChange)="templateChanged($event, 'sms_template')" [showClear]="true"
                   [searchable]="true" [fullWidth]="true" [selectOptions]="smsTemplates" [selectValue]="'id'"
                   [label]="'SHARED.custom' | translate ">
        </app-input>
        <div *ngIf="form.get('template_id').errors && (form.get('template_id').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('template_id').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <!-- Subject -->
      <div class="col-6">
        <!-- Subject Preview -->
        <ng-container *ngIf="previewNotification">
          <div class="p-2">
            <h3><b>{{ "NOTIFICATION.notification-modal.form.subject" | translate }}</b></h3>
            <mat-spinner *ngIf="loadingParsedTemplate" diameter="40" class="m-0 m-auto"></mat-spinner>
            <div [hidden]="loadingParsedTemplate" class="mt-2">{{ previewNotification.subject }}</div>
          </div>
        </ng-container>
        <!-- Custom Subject-->
        <ng-container *ngIf="!previewNotification">
          <mat-form-field appearance="standard" class="input-full-width">
            <mat-label>{{ "NOTIFICATION.notification-modal.form.subject" | translate }}</mat-label>
            <mat-spinner *ngIf="loadingParsedTemplate" diameter="40" class="m-0 m-auto"></mat-spinner>
            <input [hidden]="loadingParsedTemplate" matInput formControlName="subject" type="text">
            <div *ngIf="form.get('subject').errors && (form.get('subject').touched || ngForm.submitted)">
              <mat-error [hidden]="!form.get('subject').hasError('required')">
                {{ "SHARED.field-required" | translate }}
              </mat-error>
            </div>
          </mat-form-field>
        </ng-container>
      </div>
      <!-- Recipient -->
      <div class="col-6">
        <mat-form-field color="primary" appearance="standard" class="input-full-width">
          <mat-label>{{ "NOTIFICATION.notification-modal.form.recipient.label" | translate }}</mat-label>
          <mat-select formControlName="recipient">
            <mat-option value="client">
              {{ "NOTIFICATION.notification-modal.form.recipient.options.client" | translate }}
            </mat-option>
            <mat-option value="partner">
              {{ "NOTIFICATION.notification-modal.form.recipient.options.partner" | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Body -->
      <div class="col-12">
        <!-- Template Body Preview -->
        <ng-container *ngIf="previewNotification">
          <div class="p-2 border border-primary rounded">
            <h3><b>{{ "NOTIFICATION.notification-modal.form.body" | translate }}</b></h3>
            <mat-spinner *ngIf="loadingParsedTemplate" diameter="40" class="m-0 m-auto"></mat-spinner>
            <div [hidden]="loadingParsedTemplate" class="mt-5" [innerHTML]="previewNotification.body"></div>
          </div>
        </ng-container>
        <!-- Body Editor -->
        <ng-container *ngIf="!previewNotification">
          <div class="row">
            <!-- Label -->
            <div class="col-12">
              <mat-label>{{ "NOTIFICATION.notification-modal.form.body" | translate }}</mat-label>
            </div>
            <!-- Body Input -->
            <div class="col-8">
              <!-- SMS Body -->
              <ng-container *ngIf="form.get('channels').value?.length === 1 && form.get('channels').value[0] === 'sms'">
                <mat-form-field appearance="standard" class="input-full-width">
                  <!--<mat-label>{{ "NOTIFICATION.notification-modal.form.body" | translate }}</mat-label>-->
                  <mat-spinner *ngIf="loadingParsedTemplate" diameter="40" class="m-0 m-auto"></mat-spinner>
                  <textarea [hidden]="loadingParsedTemplate" matInput formControlName="body" cdkTextareaAutosize
                            rows="5">
                    </textarea>
                  <div *ngIf="form.get('body').errors && (form.get('body').touched || ngForm.submitted)">
                    <mat-error [hidden]="!form.get('body').hasError('required')">
                      {{ "SHARED.field-required" | translate }}
                    </mat-error>
                  </div>
                </mat-form-field>
              </ng-container>
              <!-- Email Body -->
              <ng-container
                      *ngIf="!form.get('channels').value?.length || form.get('channels').value.includes('email')">
                <div class="form-group input-full-width">
                  <!--<label for="body">{{ "NOTIFICATION.notification-modal.form.body" | translate }}</label>-->
                  <mat-spinner *ngIf="loadingParsedTemplate" diameter="40" class="m-0 m-auto"></mat-spinner>
                  <quill-editor [hidden]="loadingParsedTemplate" id="body" formControlName="body"
                                [modules]="quillModules"
                                [styles]="{height: '350px'}">
                  </quill-editor>
                  <div *ngIf="form.get('body').errors && (form.get('body').touched || ngForm.submitted)">
                    <mat-error [hidden]="!form.get('body').hasError('required')">
                      {{ "SHARED.field-required" | translate }}
                    </mat-error>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- Variables -->
            <div class="col-4">
              <mat-expansion-panel (opened)="variablesOpen = true" (closed)="variablesOpen = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ 'CONFIG.drip-campaign.editor.drip-notification.variables' | translate }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <!--{{ variablesOpen ? 'open' : 'closed' }}-->
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div style="max-height: 360px; overflow: auto">
                  <h3 class="text-center" *ngFor="let template of templateVariables">
                    <code (click)="addVariable($event)">{{ template.label }}</code>
                    <span class="small" *ngIf="template.description"> - {{ template.description }}</span>
                  </h3>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Submit -->
    <div class="row mt-5 mb-2">
      <div class="col-12 text-right">

      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <mat-spinner *ngIf="isSending" color="primary" diameter="20" class="ml-2"></mat-spinner>
  <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || isSending"
          (click)="sendNotification(form)">
    {{ "SHARED.send" | translate }}
  </button>
  <button *ngIf="templatablesCount === 1" mat-stroked-button color="primary"
          class="ml-2" (click)="parseNotificationTemplate($event, templatableType, selectedTemplate)">
    {{ "NOTIFICATION.notification-modal.form.preview" | translate }}
  </button>
  <button *ngIf="previewNotification" mat-stroked-button color="primary" class="ml-2"
          (click)="editTemplate($event, previewNotification)">
    {{ "SHARED.edit" | translate }}
  </button>
  <button mat-stroked-button mat-dialog-close color="primary" class="ml-2">
    {{ "SHARED.close" | translate }}
  </button>
</mat-dialog-actions>
