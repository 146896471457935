import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-snapshot-list',
  templateUrl: './general-snapshot-list.component.html',
  styleUrls: ['./general-snapshot-list.component.scss']
})
export class GeneralSnapshotListComponent implements OnInit{
  @Input() generalSnapshot: any;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
