import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { CustomNotificationToastComponent } from './custom-notification-toast/custom-notification-toast.component';
import { NotificationBellComponent } from './notification-bell/notification-bell.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { UserNotificationComponent } from './user-notification/user-notification.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        CustomNotificationToastComponent,
        NotificationMessageComponent,
        UserNotificationComponent,
        NotificationBellComponent,
        NotificationDetailComponent,
        NotificationListComponent,
    ],
    exports: [
        NotificationBellComponent,
    ]
})
export class NotificationModule {
}
