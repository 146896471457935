import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCallStatusLog } from '../../../../../../../../../_base-shared/models/Case/CaseCallStatusLog';
import { CaseService } from '../../../../case.service';

@Component({
  selector: 'app-case-call-status-log',
  templateUrl: './case-call-status-log.component.html',
  styleUrls: ['./case-call-status-log.component.scss']
})
export class CaseCallStatusLogComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  public isLoading                           = 0;
  public caseCallStatusLogs: Array<CaseCallStatusLog>;
  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.fetchLogs(this.case.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchLogs(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.getCallStatusLogs(caseId, {all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.caseCallStatusLogs = result.data,
      ),
    );
  }
}
