import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector:    'app-mymoid-moto-error',
  templateUrl: './mymoid-moto-error.component.html',
  styles:      [],
})
export class MymoidMotoErrorComponent implements OnInit {

  constructor(private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.toastr.error('Payment failed');
  }

}
