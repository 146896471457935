import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize, first } from 'rxjs/operators';
import { CaseService } from '../../case/case.service';

@Component({
  selector:    'app-run-off-export',
  templateUrl: './run-off-export.component.html',
  styles:   [],
})
export class RunOffExportComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = 0;

  constructor(private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private toastr: ToastrService,
              public dialogRef: MatDialogRef<RunOffExportComponent>) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  public sendRunOffReportData() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading++;
    this.caseService.exportRunOffReports(this.form.value).pipe(finalize(() => this.isLoading--), first()).subscribe((data) => {
          this.dialogRef.close(true);
          this.toastr.success(data.message);
        },
        err => console.error(err));
  }

  private buildForm() {
    this.form = this.fb.group({
      last_term_start_date: [null,  Validators.required],
      last_term_end_date:   [null,  Validators.required],
    });
  }
}
