import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector:    'app-email-preview',
  templateUrl: './email-preview.component.html',
  styles:      [
    `
        .mat-dialog-content {
            max-height: initial;
            margin: 0 auto;
            padding: 0;
            overflow: visible;
        }

        .email-header, .email-attachments {
            width: 600px;
            margin: 0 auto;
        }

        .document-page {
            outline: solid 1px grey !important;
        }
    `,
  ],
})
export class EmailPreviewComponent implements OnInit {
  @Input() title: string;
  @Input() emailHeader: { from: Array<string>, to: string, cc: Array<string>, subject: string };
  @Input() emailBody: string;
  @Input() documentBody: string;
  @Input() attachments: Array<{ name: string; slug: string; mime_type: string; content: string }>;
  @Input() previewType: 'email' | 'document';
  public activeAttachmentIndex: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EmailPreviewComponent>) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.title       = this.data.title;
      this.previewType = this.data.previewType;
      this.emailHeader = {
        from:    this.data.emailHeader.from,
        to:      this.data.emailHeader.to,
        cc:      this.data.emailHeader.cc,
        subject: this.data.emailHeader.subject,
      };
      this.emailBody   = this.data.emailBody;
      this.attachments = this.data.attachments;
    }
  }

  closePreview() {
    this.dialogRef.close();
  }

  previewAttachment(index: number) {
    this.documentBody          = this.attachments[index].content;
    this.activeAttachmentIndex = index;
    this.previewType           = 'document';
  }

  closeAttachmentPreview() {
    this.documentBody          = null;
    this.activeAttachmentIndex = null;
    this.previewType           = 'email';
  }

  saveAttachment(index: number) {
    const documentSlug = this.attachments[index].slug;
    this.closeAndRunAction('save-document', {type: documentSlug});
  }

  saveAndDownloadAttachment(index: number) {
    const documentSlug = this.attachments[index].slug;
    this.closeAndRunAction('save-and-download-document', {type: documentSlug});
  }

  closeAndRunAction(action: string, params = {}) {
    this.dialogRef.close({action, params});
  }
}
