import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { ClientService } from '../../client/client.service';

@Component({
  selector:    'app-public-unsubscribe',
  templateUrl: './public-unsubscribe.component.html',
  styles:      [],
})
export class PublicUnsubscribeComponent implements OnInit, AfterViewInit {
  public form: UntypedFormGroup;
  public isSubmitting: boolean;
  public unsubscribed: boolean;
  private generalSiteUrl = 'https://www.deudafix.es';

  constructor(private fb: UntypedFormBuilder,
              private clientService: ClientService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    this.translate.use('es'); // TODO: allow language change
  }

  private buildForm() {
    this.form = this.fb.group({
      notification_channel_slug: ['sms', [Validators.required]],
      phone_country_code:        ['+34', [Validators.required]],
      email:                     [{disabled: true, value: null}, [Validators.email]],
      phone:                     [
        null,
        [
          Validators.pattern('(6|7|9)([0-9])\\w+'),
          Validators.minLength(9),
          Validators.maxLength(9),
        ],
      ],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.clientService.optOutPublic(form.value).pipe(finalize(() => this.isSubmitting = false)).subscribe(result => {
      this.unsubscribed    = true;
      window.location.href = this.generalSiteUrl + '/optoutsuccess.php';
    });
  }

  public updateChannel($event: any) {
    if ($event === 'email') {
      this.form.get('email').enable();
      this.form.get('phone').disable();
      this.form.get('phone_country_code').disable();
    } else {
      this.form.get('email').disable();
      this.form.get('phone').enable();
      this.form.get('phone_country_code').enable();
    }
  }
}
