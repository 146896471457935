import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { EmailPreviewComponent } from './email-preview/email-preview.component';

@NgModule({
  declarations: [
    EmailPreviewComponent,
  ],
  imports:      [
    SharedModule,
  ],
  exports:      [
    EmailPreviewComponent,
  ],
})
export class AppDocumentModule {
}
