import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { ChangelogListComponent } from './changelog-list/changelog-list.component';
import { ChangelogRoutingModule } from './changelog-routing.module';

@NgModule({
  imports:      [
    SharedModule,
    ChangelogRoutingModule,
  ],
  declarations: [
    ChangelogListComponent,
  ],
})
export class ChangelogModule {
}
