import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitorsService } from '../solicitors.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector   : 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls  : ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  public form: UntypedFormGroup;
  public editorType: string;
  public formActive = false;
  public id;
  public loading    = 0;

  constructor(private route: ActivatedRoute,
              private solicitorService: SolicitorsService,
              private toast: ToastrService,
              private translate: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.editorType;

    if (this.editorType === 'edit') {
      this.fetchData();
    } else {
      this.buildForm();
    }
  }

  fetchData() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    this.solicitorService.show(this.id).subscribe(value => {
      this.buildForm(value.data);
    });
  }

  buildForm(data = null) {

    this.form = this.fb.group({
      name   : [data?.name || '', Validators.required],
      address: [data?.address || '', null],
      email  : [data?.email || '', [Validators.email]],
      phone  : [data?.phone || '', null],
      city   : [data?.city || '', null],
      region : [data?.region || '', null],
    });

    this.formActive = true;
  }

  submitSolicitor() {
    this.loading++;   // Start spinner
    if (this.editorType === 'edit') {
      this.solicitorService.update(this.id, this.form.value).pipe(finalize(() => this.loading--))
        .subscribe(
          value => {
            this.toast.success(this.translate.instant('ADDRESS-BOOK.solicitor-submit'));
            this.router.navigateByUrl('/solicitors');
          }, error => {
            this.toast.error(this.translate.instant('ADDRESS-BOOK.solicitor-submit-error'));
          }
        );
    }
    else {
      this.solicitorService.store(this.form.value).pipe(finalize(() => this.loading--))
        .subscribe(
          value => {
            this.toast.success(this.translate.instant('ADDRESS-BOOK.solicitor-submit'));
            this.router.navigateByUrl('/solicitors');
          },
          error => {
            this.toast.error(this.translate.instant('ADDRESS-BOOK.solicitor-submit-error'));
          }
        );
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitSolicitor();
    }
    else {
      this.form.markAllAsTouched();
    }
  }

}
