import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector:    'app-mymoid-moto-success',
  templateUrl: './mymoid-moto-success.component.html',
  styles:      [],
})
export class MymoidMotoSuccessComponent implements OnInit {

  constructor(private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.toastr.success('Payment completed');
  }

}
