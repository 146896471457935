import { DialerTrigger } from './DialerTrigger';

export class DialerList {
  id?: number;
  name: string;
  list_id: string;

  created_at: string | Date;
  updated_at: string | Date;

  // Relations
  dialer_triggers?: Array<DialerTrigger>;
}
