import { Case } from './Case';

export type CaseExpenseType =
    'essentials'
    | 'living_expenses'
    | 'phone'
    | 'transport'
    | 'housekeeping'
    | 'other'
    | 'special_expenses';

export class CaseExpense {
  id: number;
  case_id?: number;
  // Essentials
  essential_rent: number;
  essential_mortgage: number;
  life_insurance: number;
  pension_plan: number;
  ibi: number;
  home_insurance: number;
  school_expenses: number;
  essential_other: number;
  essential_local_tax: number;
  // Living Expenses
  essential_gas: number;
  essential_electricity: number;
  essential_water: number;
  // Phone
  phone_home: number;
  phone_mobile: number;
  // Transport
  travel_public_transport: number;
  travel_car_insurance: number;
  travel_vehicle_tax: number;
  travel_fuel: number;
  essential_hire_purchase: number;
  itv: number;
  // Housekeeping
  housekeeping_food_and_groceries: number;
  housekeeping_cleaning: number;
  housekeeping_newspapers: number;
  housekeeping_alcohol: number;
  housekeeping_laundry: number;
  housekeeping_clothing_footwear: number;
  // Other Expenditure
  other_hobbies_leisure_sport: number;
  other_other: number;
  pet_expenses: number;
  medical_expenses: number;
  medications: number;
  cosmetics_and_beauty: number;
  legal_fees: number;

  special_expenses: Array<{ label: string, value: number }>;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  case?: Case;
}
