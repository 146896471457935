<div *ngIf="isLoading" class="pt-5 text-center">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>

<form *ngIf="form && !isLoading" [formGroup]="form" (ngSubmit)="submitForm(form)">
  <!-- IBAN -->
  <div class="col-12">
    <app-input formControlName="iban" [label]="'USER.payment.bank_accounts.editor.iban' | translate"
               [submitted]="formSubmitted">
    </app-input>
  </div>
  <!-- Bank Name -->
  <div class="col-12">
    <app-input formControlName="bank_name"
               [label]="'USER.payment.bank_accounts.editor.custom_bank_name' | translate"
               [submitted]="formSubmitted">
    </app-input>
  </div>
  <!-- Bank Address -->
  <div class="col-12">
    <app-input formControlName="bank_address"
               [label]="'USER.payment.bank_accounts.editor.custom_bank_address' | translate"
               [submitted]="formSubmitted">
    </app-input>
  </div>
  <!-- Submit -->
  <div class="col-12">
    <div class="d-flex justify-content-end">
      <app-spinner-btn [loading]="submitting" [name]="'SHARED.submit' | translate"></app-spinner-btn>
    </div>
  </div>
</form>
