import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CourtService } from '../../court/court.service';
import { AdministratorsService } from '../../administrators/administrators.service';
import { NotariesService } from '../../notaries/notaries.service';

@Component({
  selector:    'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls:   ['./create-form.component.scss'],
})
export class CreateFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public editorType: string;
  public componentName: string;
  public formActive = false;
  public id;
  public loading    = 0;

  constructor(private route: ActivatedRoute,
              private courtService: CourtService,
              private notariesService: NotariesService,
              private administratorsService: AdministratorsService,
              private toast: ToastrService,
              private translate: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.editorType    = this.route.snapshot.data.editorType;
    this.componentName = this.route.snapshot.data.componentName;

    if (this.editorType === 'edit') {
      this.fetchData();
    } else {
      if (this.componentName === 'court') {
        this.buildForm();
      }
      if (this.componentName === 'notary') {
        this.buildNotaryForm();
      }
      if (this.componentName === 'administrator') {
        this.buildForm();
      }
    }
  }

  fetchData() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.componentName === 'court') {
      this.courtService.show(this.id).subscribe(value => {
        this.buildForm(value.data);
      });
    }
    if (this.componentName === 'notary') {
      this.notariesService.show(this.id).subscribe(value => {
        this.buildNotaryForm(value.data);
      });
    }
    if (this.componentName === 'administrator') {
      this.administratorsService.show(this.id).subscribe(value => {
        this.buildForm(value.data);
      });
    }
  }

  buildForm(data = null) {
    this.form = this.fb.group({
      name:              [data?.name || '', Validators.required],
      approved:          [data?.approved.toString() || '1', Validators.required],
      submission_method: [data?.submission_method || 'email', Validators.required],
      address:           [data?.address || '', Validators.required],
      email_general:     [data?.email_general || '', [Validators.required, Validators.email]],
      email_documents:   [data?.email_documents || '', [Validators.required, Validators.email]],
      company_name:      [data?.company_name || '', Validators.required],
      contact_1_name:    [data?.contact_1_name || '', null],
      contact_1_phone:   [data?.contact_1_phone || '', null],
      contact_1_email:   [data?.contact_1_email || '', Validators.email],
      contact_2_name:    [data?.contact_2_name || '', null],
      contact_2_phone:   [data?.contact_2_phone || '', null],
      contact_2_email:   [data?.contact_2_email || '', Validators.email],
      province:          [data?.province || '', null],
    });

    this.formActive = true;
  }

  buildNotaryForm(data = null) {
    this.form = this.fb.group({
      name:              [data?.name || '', Validators.required],
      approved:          [data?.approved.toString() || '1', Validators.required],
      submission_method: [data?.submission_method || 'email', Validators.required],
      address:           [data?.address || '', Validators.required],
      email_general:     [data?.email_general || '', [Validators.required, Validators.email]],
      email_documents:   [data?.email_documents || '', [Validators.required, Validators.email]],
      company_name:      [data?.company_name || '', Validators.required],
      contact_1_name:    [data?.contact_1_name || '', null],
      contact_1_phone:   [data?.contact_1_phone || '', null],
      contact_1_email:   [data?.contact_1_email || '', Validators.email],
      contact_2_name:    [data?.contact_2_name || '', null],
      contact_2_phone:   [data?.contact_2_phone || '', null],
      contact_2_email:   [data?.contact_2_email || '', Validators.email],
      province:          [data?.province || '', null],
      cp:                [data?.cp || '', null],
      city:              [data?.city || '', null],
      commune:           [data?.commune || '', null],
      web:               [data?.web || '', null],
      phone:             [data?.phone || '', null],
      fax:               [data?.fax || '', null],
    });

    this.formActive = true;
  }

  submitCourt() {
    this.loading++;   // Start spinner
    if (this.editorType === 'edit') {
      this.courtService.update(this.id, this.form.value).pipe(finalize(() => this.loading--)) //  Stop spinner
          .subscribe(
              value => {
                this.toast.success(this.translate.instant('ADDRESS-BOOK.court-submit'));
                this.router.navigateByUrl('/court');
              }, error => {
                this.toast.error(this.translate.instant('ADDRESS-BOOK.court-submit-error'));
              },
          );
    } else {
      this.courtService.store(this.form.value).pipe(finalize(() => this.loading--)) //  Stop spinner
          .subscribe(
              value => {
                this.toast.success(this.translate.instant('ADDRESS-BOOK.court-submit'));
                this.router.navigateByUrl('/court');
              },
              error => {
                this.toast.error(this.translate.instant('ADDRESS-BOOK.court-submit-error'));
              },
          );
    }
  }

  submitNotary() {
    this.loading++;   // Start spinner
    if (this.editorType === 'edit') {
      this.notariesService.update(this.id, this.form.value).pipe(finalize(() => this.loading--)) //  Stop spinner
          .subscribe(
              value => {
                this.toast.success(this.translate.instant('ADDRESS-BOOK.notary-submit'));
                this.router.navigateByUrl('/notaries');
              }, error => {
                this.toast.error(this.translate.instant('ADDRESS-BOOK.notary-submit-error'));
              },
          );
    } else {
      this.notariesService.store(this.form.value).pipe(finalize(() => this.loading--)) //  Stop spinner
          .subscribe(
              value => {
                this.toast.success(this.translate.instant('ADDRESS-BOOK.notary-submit'));
                this.router.navigateByUrl('/notaries');
              },
              error => {
                this.toast.error(this.translate.instant('ADDRESS-BOOK.notary-submit-error'));
              },
          );
    }
  }

  submitAdministrator() {
    this.loading++;   // Start spinner
    if (this.editorType === 'edit') {
      this.administratorsService.update(this.id, this.form.value).pipe(finalize(() => this.loading--)) //  Stop spinner
          .subscribe(
              value => {
                this.toast.success(this.translate.instant('ADDRESS-BOOK.administrator-submit'));
                this.router.navigateByUrl('/administrators');
              }, error => {
                this.toast.error(this.translate.instant('ADDRESS-BOOK.administrator-submit-error'));
              },
          );
    } else {
      this.administratorsService.store(this.form.value).pipe(finalize(() => this.loading--)) //  Stop spinner
          .subscribe(
              value => {
                this.toast.success(this.translate.instant('ADDRESS-BOOK.administrator-submit'));
                this.router.navigateByUrl('/administrators');
              },
              error => {
                this.toast.error(this.translate.instant('ADDRESS-BOOK.administrator-submit-error'));
              },
          );
    }
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.componentName === 'court') {
        this.submitCourt();
      }
      if (this.componentName === 'notary') {
        this.submitNotary();
      }
      if (this.componentName === 'administrator') {
        this.submitAdministrator();
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
}
