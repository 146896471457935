<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <!-- <h1>Packager General</h1>-->
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
           <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
      </div>
      <form *ngIf="form" [formGroup]="form" class="form-row" (ngSubmit)="submitForm(form)">
        <div class="col">
          <div class="uploaded-img-container mb-2">
            <h3 class="mb-2">Logo</h3>
            <img [src]="logoImgPath" style="width: 205px; height: 87px" alt="Packager logo">
          </div>
          <!-- Upload Logo -->
          <div class="col mb-2 d-flex flex-row justify-content-between align-items-end" style="padding: 0px;">
            <div>
              <div class="custom-file mr-4">
                <input type="file" class="custom-file-input" id="uploadFile1" (change)="onLogoUpload($event)">
                <label class="custom-file-label" for="uploadFile1">
                  {{ 'DOCUMENTS.choose-file' | translate}}
                </label>
              </div>
              <small>Recommended .png image of resolution: 1024 x 432</small>
            </div>
            <button mat-button type="button" color="primary" (click)="saveUploadedLogo()">Upload</button>
          </div>

          <div class="uploaded-signature-container mt-2">
            <h3 class="mt-5 mb-2">Signature</h3>
            <img [src]='signatureImgPath' style="width: 205px; height: 87px" alt="Packager signature">
          </div>
          <!-- Upload Signature -->
          <div class="col d-flex flex-row justify-content-between align-items-end mt-2 mb-3" style="padding: 0px;">
            <div>
              <div class="custom-file mr-4">
                <input type="file" class="custom-file-input" id="uploadFile2" (change)="onSignatureUpload($event)">
                <label class="custom-file-label" for="uploadFile2">
                  {{ 'DOCUMENTS.choose-file' | translate}}
                </label>
              </div>
              <small>Recommended .png image of resolution: 1024 x 432</small>
            </div>
            <button mat-button type="button" color="primary" (click)="saveUploadedSignature()">Upload</button>
          </div>
          <!-- Email -->
          <app-input formControlName="email" [fullWidth]="true"
                     [label]="'SHARED.model.email' | translate">
          </app-input>
          <!-- Bank Account -->
          <h3 class="mt-3">Bank Account</h3>
          <div formGroupName="bank_account">
            <!-- Bank Name -->
            <app-input formControlName="bank_name" [fullWidth]="true"
                       [label]="'USER.payment.bank_accounts.editor.custom_bank_name' | translate">
            </app-input>
            <!-- Bank Address -->
            <app-input formControlName="bank_address" [fullWidth]="true"
                       [label]="'USER.payment.bank_accounts.editor.custom_bank_address' | translate">
            </app-input>
            <!-- Bank Account Number -->
            <app-input formControlName="account_number" [fullWidth]="true"
                       [label]="'USER.payment.bank_accounts.editor.account_number' | translate">
            </app-input>
            <!-- Bank IBAN -->
            <app-input formControlName="iban" [fullWidth]="true"
                       [label]="'USER.payment.bank_accounts.editor.iban' | translate">
            </app-input>
          </div>
        </div>

        <div class="col">
          <!-- Address -->
          <div formGroupName="address">
            <h3 class="mt-5">Address</h3>
            <!-- Address 1 -->
            <app-input formControlName="address_1" [label]="'ADDRESS.address_1' | translate" [fullWidth]="true">
            </app-input>
            <!-- Address 2 -->
            <app-input formControlName="address_2" [label]="'ADDRESS.address_2' | translate" [fullWidth]="true">
            </app-input>
            <!-- Postcode -->
            <div class="d-flex justify-content-between align-items-baseline">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>{{'ADDRESS.postcode' | translate}}</mat-label>
                <input type="number" aria-label="Number" matInput formControlName="postcode"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <!--                <ng-container *ngIf="filteredOptionsClient">-->
                  <!--                  <mat-option *ngFor="let option of filteredOptionsClient | async" [value]="option?.postcode"-->
                  <!--                              (click)="selectedPostcodeClient(option)">-->
                  <!--                    {{option.postcode}}-->
                  <!--                  </mat-option>-->
                  <!--                </ng-container>-->
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <!-- Municipality -->
            <app-input formControlName="municipality" [label]="'ADDRESS.municipality' | translate"
                       [fullWidth]="true">
            </app-input>
            <!-- City -->
            <app-input formControlName="city" [label]="'ADDRESS.city' | translate"
                       [fullWidth]="true">
            </app-input>
            <!-- Region -->
            <app-input formControlName="region" [label]="'ADDRESS.region' | translate"
                       [fullWidth]="true">
            </app-input>
            <!-- County -->
            <app-input formControlName="county" [label]="'ADDRESS.county' | translate"
                       [fullWidth]="true">
            </app-input>
            <!-- Country -->
            <app-input formControlName="country" [label]="'ADDRESS.country' | translate"
                       [fullWidth]="true">
            </app-input>
          </div>
        </div>
        <!-- Submit-->
        <div class="col-12">
          <hr>
          <div class="d-flex justify-content-end">
            <app-spinner-btn class="ml-2 mt-1" type="submit" [loading]="isSubmitting"
                             [name]="'SHARED.save' | translate">
            </app-spinner-btn>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
