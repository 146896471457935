import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../_shared/guards/main-auth-guard.service';
import { MainPermissionGuard } from '../_shared/guards/main-permission-guard.service';
import {
  DistributionBatchListComponent,
} from '../admin/distribution/distribution-batch-list/distribution-batch-list.component';
import {
  DistributionBatchDetailComponent,
} from '../admin/distribution/distribution-batch/distribution-batch-detail/distribution-batch-detail.component';
import {
  DistributionCaseDetailComponent,
} from '../admin/distribution/distribution-case-detail/distribution-case-detail.component';
import {
  DistributionCasePaymentComponent,
} from '../admin/distribution/distribution-case-detail/distribution-case-payment/distribution-case-payment.component';
import { ForgotPasswordComponent } from '../auth/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path:     'distribution-provider',
    children: [
      {
        path:     'batches',
        children: [
          {path: '', component: DistributionBatchListComponent},
          {
            path: ':id/cases', children: [
              {path: '', component: DistributionBatchDetailComponent},
              {
                path:        ':uuid',
                component:   DistributionCaseDetailComponent,
                canActivate: [MainAuthGuard, MainPermissionGuard],
                children:    [
                  {path: 'payments', component: DistributionCasePaymentComponent},
                ],
              },
            ],
          },
        ],
      },
      //  Shared Auth Components
      {path: 'forgot-password', component: ForgotPasswordComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class DistributionRoutingModule {
}
