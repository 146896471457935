<div class="card">
  <div class="card-title"><h2 *ngIf="isLoading">{{ "INTEGRATION.gmail.spinner-text" | translate }}</h2></div>
  <div class="card-body">
    <div *ngIf="isLoading" class="row">
      <div class="col-12 pt-5 text-center">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
    </div>
  </div>
</div>
