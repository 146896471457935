<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>{{ 'CONFIG.dialer.list.header' | translate }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div *ngIf="serverResponse" class="col-12">
          <app-server-response [response]="serverResponse"></app-server-response>
        </div>
      </div>
      <form *ngIf="form" [formGroup]="form" style="width: 100%" (ngSubmit)="submitForm(form)">
        <div class="row">
          <div class="col-12">
            <mat-card>
              <mat-card-content>
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{'SHARED.model.name' | translate}}</th>
                    <th>{{ 'CONFIG.dialer.dialer_list_id' | translate }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container [formArrayName]="'dialer_lists'"
                                *ngFor="let formGroup of getDialerListsArray().controls let i = index">
                    <tr [formGroupName]="i" >
                      <!--Name-->
                      <td>
                        <app-input type="text" formControlName="name" [label]="'SHARED.model.name' | translate">
                        </app-input>
                      </td>
                      <!--Dialer List Id-->
                      <td>
                        <app-input type="textarea" formControlName="list_id" [fullWidth]="true"
                                   [label]="'CONFIG.dialer.dialer_list_id' | translate">
                        </app-input>
                      </td>
                      <td>
                        <button type="button" mat-icon-button color="warn" (click)="removeList(i)">
                          <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </mat-card-content>
              <mat-card-footer>
                <div class="d-flex justify-content-end">
                  <button type="button" class="mr-3 mb-3" mat-raised-button color="primary"
                          (click)="addNewDialerLIst()">
                    + {{ 'CONFIG.dialer.list.actions.add' | translate }}
                  </button>
                </div>
              </mat-card-footer>
            </mat-card>
          </div>
          <div class="col-12">
            <hr>
            <div class="d-flex justify-content-end">
              <app-spinner-btn class="ml-2 mt-1" type="submit" [loading]="isSubmitting"
                               [name]="'SHARED.save' | translate">
              </app-spinner-btn>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
