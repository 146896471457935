<div class="row">
  <div class="col-2">
    <div class="calendar">
      <div class="date">
        {{ task.due_date | date:'dd/MM' }}
      </div>
      <div class="time">
        {{ task.due_date | date:'HH:mm' }}
      </div>
    </div>
  </div>
  <div class="col-10">
    <h4>{{ 'TASK.detail.name' | translate }}: <b>{{ task.name }}</b></h4>
    <h4>{{ 'TASK.detail.case' | translate }}: <b>{{ task.case.ref_number }}</b></h4>

    <h4 *ngIf="task.authorable">{{ 'TASK.detail.author' | translate }}:
      <b>
        <span *ngIf="task.authorable_type === 'user'">{{ 'TASK.detail.authorable_type.user' | translate }} - </span>
        <span *ngIf="task.authorable_type === 'task_template'">
      {{ 'TASK.detail.authorable_type.task_template' | translate }} -
    </span>
        {{ task.authorable.name  }}
      </b>
    </h4>
    <h4 *ngIf="task.assigned_departments && task.assigned_departments.length">{{ 'TASK.detail.assigned_departments' | translate }}:
      <b>
        <span *ngFor="let assignedDepartment of task.assigned_departments" class="badge badge-primary mr-1">
          {{ assignedDepartment.name }}
        </span>
      </b>
    </h4>
    <h4 *ngIf="task.assigned_users && task.assigned_users.length">{{ 'TASK.detail.assigned_users' | translate }}
      <b>
        <span *ngFor="let assignedUser of task.assigned_users" class="badge badge-primary mr-1">
          {{ assignedUser.name }}
        </span>
      </b>
    </h4>
    <h4>{{ 'TASK.detail.created_at' | translate }}: <b>{{ task.created_at | date:'d MMM, y HH:mm' }}</b></h4>
    <h4>{{ 'TASK.detail.due_date' | translate }}: <b>{{ task.due_date | date:'d MMM, y HH:mm' }}</b></h4>
    <h4>{{ 'TASK.detail.completed_at.label' | translate }}:
      <b>
        <span *ngIf="task.completed_at">{{ 'TASK.detail.completed_at.options.true' | translate }}</span>
        <span *ngIf="!task.completed_at">{{ 'TASK.detail.completed_at.options.false' | translate }}</span>
      </b>
    </h4>
    <h4 *ngIf="task.completed_at && task.completed_by">
      {{ 'TASK.detail.completed_by' | translate }}:
      <b>{{ task.completed_by.name }} - <b>{{ task.completed_at | date:'d MMM, y HH:mm' }}</b></b>
    </h4>
  </div>
</div>
