<div style="display: block">
  <div class="row">
    <div class="col-11">
      <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
           [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
           style="padding-top: 10px; padding-bottom: 10px"
           [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>
    <div class="col-1">
      <button *ngIf="showCloseButton" class="close" type="button" (click)="closeToast()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-12 text-right">
      <button *ngFor="let customAction of customActions" mat-stroked-button type="button"
              (click)="action($event, customAction.value)" class="ml-1">
        <small>{{ customAction.label }}</small>
      </button>
      <button mat-stroked-button *ngIf="options.closeButton" type="button" (click)="remove()" class="ml-1">
        <small>{{ 'NOTIFICATION.toast.actions.close' | translate }}</small>
      </button>
    </div>
    <div class="col-12" *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  </div>
</div>
