import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TaskEditorComponent } from './task-editor/task-editor.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskPreviewComponent } from './task-preview/task-preview.component';
import { TaskRoutingModule } from './task-routing.module';

@NgModule({
  imports:      [
    SharedModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    TaskRoutingModule,
  ],
  declarations: [
    TaskDetailComponent,
    TaskEditorComponent,
    TaskPreviewComponent,
    TaskListComponent,
  ],
  exports:      [
    TaskPreviewComponent,
  ],
})
export class TaskModule {
}
