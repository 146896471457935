import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { BankAccount } from '../../../../../_base-shared/models/Payment/BankAccount';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserPaymentService extends MainBaseApiService {
  public indexBankAccounts(userId: number) {
    return this.http.get<LaravelResourceResponse<Array<BankAccount>>>(
        this.apiUrl + '/users/' + userId + '/bank-accounts',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public storeBankAccount(userId: number, accountData) {
    return this.http.post<LaravelResourceResponse<BankAccount>>(
        this.apiUrl + '/users/' + userId + '/bank-accounts', accountData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateBankAccount(userId: number, bankAccountId: number, accountData) {
    return this.http.patch<LaravelResourceResponse<BankAccount>>(
        this.apiUrl + '/users/' + userId + '/bank-accounts/' + bankAccountId, accountData,
    ).pipe(catchError(response => this.handleError(response)));
  }
}
