<div class="d-flex flex-row justify-content-between align-items-center">
  <h2>{{ "CASES.single.general.relation_list.creditor_notes.heading" | translate }}</h2>
</div>
<div class="scroll-notes">
  <div class="pt-4" *ngIf="isLoading">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
  <app-empty-dataset [show]="!isLoading && !allCreditors?.length"></app-empty-dataset>
  <div *ngFor="let creditor of allCreditors">
    <div class="card mb-5" *ngIf="creditor.pivot && creditor.pivot.notes">
      <div class="card-body">
        <div class="pb-4 d-flex flex-row justify-content-between align-items-center">
          <p class="m-0">{{ creditor.name }}</p>
        </div>
        <div>{{ creditor.pivot.notes }}</div>
        <div *ngIf="creditor.created_at" class="pt-4 d-flex flex-row justify-content-between align-items-center">
          <p class="m-0">{{ creditor.created_at | toDateObject | date: 'medium' }}</p>
        </div>
      </div>
    </div>
    <div class="card mb-5" *ngIf="!creditor.pivot && creditor.notes">
      <div class="card-body">
        <div class="pb-4 d-flex flex-row justify-content-between align-items-center">
          <p class="m-0">{{ creditor.public_organisation }}</p>
        </div>
        <div>{{ creditor.notes }}</div>
        <div *ngIf="creditor.created_at" class="pt-4 d-flex flex-row justify-content-between align-items-center">
          <p class="m-0">{{ creditor.created_at | toDateObject | date: 'medium' }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
