<mat-dialog-content>
  <!-- TODO: translate strings -->
  <div>
    <div class="d-flex flex-row align-items-center">

      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>

      <div class="row">
        <div class="col-6">
          <div><h2>Original image</h2></div>
          <div class="mb-3">
            <button mat-raised-button color="primary" class="mr-2" (click)="rotateLeft()">Rotate left</button>
            <button mat-raised-button color="primary" (click)="rotateRight()">Rotate right</button>
          </div>

          <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="false"
                  [containWithinAspectRatio]="false"
                  [imageURL]="storageUrl + data.document.location"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.padding]="0"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed($event)"
          ></image-cropper>

        </div>
        <div class="col-6">
          <div><h2 style="margin-bottom: 68px">Cropped image preview</h2></div>
          <img class="img-fluid" [src]="croppedImage" style="max-height: 650px"/>
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="onNoClick()">{{ "SHARED.close" | translate }}</button>
    <button type="submit" mat-raised-button color="primary" (click)="saveImg()">
      Save changes
    </button>
  </div>
</mat-dialog-content>
