import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { PackagerStatus } from '../../../../../../../_base-shared/models/Status/PackagerStatus';
import { PackagerStatusService } from '../packager-status.service';

@Component({
  selector:    'app-packager-status-editor',
  templateUrl: './packager-status-editor.component.html',
  styleUrls:   ['./packager-status-editor.component.scss']
})
export class PackagerStatusEditorComponent implements OnInit{
  public isLoading = 0;
  public form: UntypedFormGroup;
  public editorType: 'create' | 'edit';
  public packagerStatuses: PackagerStatus;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private packagerStatus: PackagerStatusService,
    private toastr: ToastrService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.editor;
    if (this.editorType === 'edit') {
      this.fetchStatus(+this.route.snapshot.paramMap.get('id'));
    } else {
      this.packagerStatuses = new PackagerStatus();
      this.buildForm();
    }
  }

  private fetchStatus(packagerStatusId: number): void {
    this.isLoading++;
    this.packagerStatus.get(packagerStatusId).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.packagerStatuses = result.data;
        this.buildForm();
      });
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name_es: [this.packagerStatuses.name_es, Validators.required],
      name_en: [this.packagerStatuses.name_en, Validators.required]
    });
  }

  public onSubmit(): void {
    if (this.editorType === 'create') {
      this.packagerStatus.store(this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/packager-status');
            this.toastr.success(
              `${ this.translateService.instant('PACKAGER.packager_status.model_name.singular') } ${ this.translateService.instant(
                'SHARED.item-added') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          });
    } else {
      this.packagerStatus.update(this.packagerStatuses.id, this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/packager-status');
            this.toastr.success(
              `${ this.translateService.instant('PACKAGER.packager_status.model_name.singular') } ${ this.translateService.instant(
                'SHARED.item-edited') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          },
        );
    }
  }

}
