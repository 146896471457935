import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AppFileService extends MainBaseApiService {

  public download(params) {
    return this.http.get(this.apiUrl + '/files/download', {
      observe: 'response', responseType: 'blob',
      params,
    }).pipe(catchError(response => this.handleError(response)));
  }

}
