import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { TaskTemplateEditorComponent } from './task-template-editor/task-template-editor.component';
import { TaskTemplateListComponent } from './task-template-list/task-template-list.component';

const routes: Routes = [
  {
    path:     'task-templates',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: TaskTemplateListComponent},
      {
        path:        'create',
        canActivate: [MainPermissionGuard],
        component:   TaskTemplateEditorComponent,
        data:        {type: 'create'},
      },
      {
        path:        ':id/edit',
        canActivate: [MainPermissionGuard],
        component:   TaskTemplateEditorComponent,
        data:        {type: 'edit'},
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskTemplateRoutingModule {
}
