import { Component, Input, OnInit } from '@angular/core';
import { CaseIncome } from '../../../../../../../_base-shared/models/Case/CaseIncome';

@Component({
  selector: 'app-incomes-snapshot-list',
  templateUrl: './incomes-snapshot-list.component.html',
  styleUrls: ['./incomes-snapshot-list.component.scss']
})
export class IncomesSnapshotListComponent implements OnInit{
  @Input() incomes: CaseIncome;

  constructor() {
  }

  ngOnInit(): void {
  }
}
