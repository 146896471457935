import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from '../_shared/shared.module';
import { NotificationModule } from '../admin/notification/notification.module';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './nav/nav.component';
import { NonStaffNavigationComponent } from './nav/non-staff-navigation/non-staff-navigation.component';
import { StaffNavigationComponent } from './nav/staff-navigation/staff-navigation.component';

@NgModule({
  imports:      [
    SharedModule,
    NotificationModule,
    MatToolbarModule,
  ],
  declarations: [
    NavComponent,
    HeaderComponent,
    StaffNavigationComponent,
    NonStaffNavigationComponent,
  ],
  exports:      [
    NavComponent,
    HeaderComponent,
  ],
})
export class LayoutModule {
}
