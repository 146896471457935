import { Injectable } from '@angular/core';
import { LaravelResourceResponse } from '../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../_shared/services/main-base-api.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AffiliateUserService extends MainBaseApiService {
  public storeLead(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/affiliate-cases`, data)
        .pipe(catchError(response => this.handleError(response)));
  }
}
