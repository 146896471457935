<h1 class="pb-5">{{ "REPORTS.creditor-reports" | translate }}</h1>
<div class="d-inline-block pb-2">
  <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)">
    <mat-radio-button value="secured">{{ "CASE_CREDITOR.model.type.options.secured" | translate }}</mat-radio-button>
    <mat-radio-button
            value="unsecured">{{ "CASE_CREDITOR.model.type.options.unsecured" | translate }}</mat-radio-button>
    <mat-radio-button
            value="public_debts">{{ "CASE_CREDITOR.model.type.options.public" | translate }}</mat-radio-button>
    <mat-radio-button checked
                      value="combined">{{ "CASE_CREDITOR.model.type.options.combined" | translate }}</mat-radio-button>
  </mat-radio-group>
</div>
<div class="row px-1">
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center d-flex justify-content-center align-items-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ "REPORTS.recoverable-debt" | translate }}:
        <span class="text-primary">{{optionSelected === 'public_debts' ?
            'N/A' :
            book_value_active_all | number: '1.2-2'}}€</span>
      </h4>
      <mat-icon class="ml-2" color="accent" style="cursor: pointer; font-size: 20px; width: 20px; height: 20px"
                [matTooltip]="'REPORTS.recoverable-debt-info'| translate"
                matTooltipClass="unapproved-tooltip">
        info
      </mat-icon>
    </div>
  </div>
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center d-flex justify-content-center align-items-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ "REPORTS.recorded-debt" | translate }}: <span class="text-primary">{{book_value_all | number: '1.2-2'}}
        €</span>
      </h4>
      <mat-icon class="ml-2" color="accent" style="cursor: pointer; font-size: 20px; width: 20px; height: 20px"
                [matTooltip]="'REPORTS.debt-recorder-info'| translate"
                matTooltipClass="unapproved-tooltip">
        info
      </mat-icon>
    </div>
  </div>
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center d-flex justify-content-center align-items-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ "REPORTS.total" | translate }}: <span class="text-primary">{{totalSumAll | number: '1.2-2'}}€</span>
      </h4>
      <mat-icon class="ml-2" color="accent" style="cursor: pointer; font-size: 20px; width: 20px; height: 20px"
                [matTooltip]="'REPORTS.debt-managed-info'| translate"
                matTooltipClass="unapproved-tooltip">
        info
      </mat-icon>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-3">
    <app-dropdown
            [data]="creditors"
            (selectChange)="onSelectCreditor($event)"
            (clearSelect)="onClearSelect($event)"
            [label]="'REPORTS.select-creditor' | translate"
            [parentFormControl]="creditorCtrl"
            [placeholder]="translateService.instant('REPORTS.creditor')"></app-dropdown>
  </div>
  <div class="col-md-3" [formGroup]="filterForm">
    <mat-form-field color="primary" appearance="standard" style="display: block">
      <mat-label>{{"CREDITORS.status" | translate}}</mat-label>
      <mat-select formControlName="status[]" multiple>
        <mat-option value="proposal_sent">{{"CREDITORS.proposal-sent" | translate}}</mat-option>
        <mat-option value="proposal_accepted">{{"CREDITORS.proposal-accepted" | translate}}</mat-option>
        <mat-option value="proposal_rejected">{{"CREDITORS.proposal-rejected" | translate}}</mat-option>
        <mat-option value="modification_required">{{"CREDITORS.modification-required" | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" style="width: 100%">
        <ng-container matColumnDef="bookValue">
          <th mat-header-cell *matHeaderCellDef>{{ "REPORTS.recoverable-debt" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{element.book_value_active | number: '1.2-2'}}€</td>
        </ng-container>
        <ng-container matColumnDef="noOfClients">
          <th style="text-align: right" mat-header-cell
              *matHeaderCellDef>{{ "REPORTS.active-clients" | translate }}</th>
          <td style="text-align: right" mat-cell *matCellDef="let element">{{element.number_of_clients_active}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div *ngIf="isLoading" class="text-center" style="padding: 20px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!creditorCtrl.value" style="padding: 20px">
        <h3 class="text-center">{{ "REPORTS.select-creditor" | translate }}</h3>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" style="width: 100%">
        <ng-container matColumnDef="bookValue">
          <th mat-header-cell *matHeaderCellDef>{{ "REPORTS.recorded-debt" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{element.book_value | number: '1.2-2'}}€</td>
        </ng-container>
        <ng-container matColumnDef="noOfClients">
          <th style="text-align: right" mat-header-cell
              *matHeaderCellDef>{{ "REPORTS.subscribed-clients" | translate }}</th>
          <td style="text-align: right" mat-cell *matCellDef="let element">{{element.number_of_clients}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div *ngIf="isLoading" class="text-center" style="padding: 20px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!creditorCtrl.value" style="padding: 20px">
        <h3 class="text-center">{{ "REPORTS.select-creditor" | translate }}</h3>
      </div>
    </div>
  </div>
</div>

<div *ngIf="creditorCtrl.value">
  <h1 class="text-center m-0" style="padding-top: 30px">
    <b>{{ "REPORTS.total" | translate }}: {{totalSum | number: '1.2-2'}}€</b>
  </h1>
</div>


<div *ngIf="creditorCtrl.value" class="d-flex justify-content-between align-items-center">
  <h2 class="pt-5">Case list</h2>
  <button mat-raised-button (click)="exportRecords()" color="primary" class="ml-2" type="button">
    {{ 'PAYMENTS.list.export.button-name' | translate }}
  </button>
</div>

<div [hidden]="!creditorCtrl.value" class="mat-elevation-z8" style="margin-bottom: 100px">
  <table mat-table [dataSource]="dataSourceList" style="width: 100%" matSort>
    <ng-container matColumnDef="Ref">
      <th mat-header-cell *matHeaderCellDef>REF</th>
      <td mat-cell *matCellDef="let element">{{element.RefNumber}}</td>
    </ng-container>
    <ng-container matColumnDef="Ownership">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.Ownership}}</td>
    </ng-container>
    <ng-container matColumnDef="CaseEmail">
      <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.email" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.CaseEmail}}</td>
    </ng-container>
    <ng-container matColumnDef="ApplicationType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "CASES.single.application-type" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.ApplicationType}}</td>
    </ng-container>
    <ng-container matColumnDef="CurrentBalance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{element.CurrentBalance | number: '1.2-2'}}
        {{element.CurrentBalance ? '€' : ''}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsList"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsList;" routerLink="/cases/{{row.CaseId}}"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 20px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!creditorCtrl.value" style="padding: 20px">
    <h3 class="text-center">{{ "REPORTS.select-creditor" | translate }}</h3>
  </div>
</div>
