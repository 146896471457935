import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedBaseApiService } from '../../../../../_base-shared/services/shared-base-api.service';
import { environment } from '../../../environments/environment';
import { MainGlobalEventService } from './main-global-event.service';

@Injectable({
  providedIn: 'root',
})
export class MainBaseApiService extends SharedBaseApiService {
  constructor(protected http: HttpClient,
              protected router: Router,
              protected globalEvents: MainGlobalEventService,
  ) {
    super(http, router, globalEvents, environment.API_URL, environment.API_DOMAIN, environment.API_SECURE_PROTOCOL);
  }
}
