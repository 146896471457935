import { NgModule } from '@angular/core';
import { SharedModule } from '../../../_shared/shared.module';
import { MiscConfigRoutingModule } from './misc-config-routing.module';
import { MiscConfigComponent } from './misc-config.component';

@NgModule({
  declarations: [MiscConfigComponent],
  imports:      [
    SharedModule,
    MiscConfigRoutingModule,
  ],
})
export class MiscConfigModule {
}
