import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { PackagerEditorComponent } from './packager-editor/packager-editor.component';
import { PackagerListComponent } from './packager-list/packager-list.component';
import { AdminPackagerRoutingModule } from './admin-packager-routing.module';

@NgModule({
  imports:      [
    SharedModule,
    AdminPackagerRoutingModule,
  ],
  declarations: [
    PackagerListComponent,
    PackagerEditorComponent,
  ],
})
export class AdminPackagerModule {
}
