import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { DialLeadListSelectorComponent } from './dial-lead-list-selector/dial-lead-list-selector.component';

@NgModule({
  declarations: [DialLeadListSelectorComponent],
  imports:      [
    SharedModule,
  ],
  exports:      [DialLeadListSelectorComponent],
})
export class LeadModule {
}
