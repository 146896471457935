<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
<!--        <h1>Packager Configuration</h1>-->
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div class="col-12">
          <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
            <mat-tab-group>
              <mat-tab *ngFor="let category of configurationCategories" [label]="category.name_en"
                       [formArrayName]="category.slug">
                <div class="row">
                  <ng-container *ngFor="let configGroup of configurationsArray(category.slug)?.controls; let i=index">
                    <div *ngIf="configGroup.get('visible').value" [formGroupName]="i" class="col-6">
                      <app-input formControlName="value" [label]="configGroup.get('name_en').value" [fullWidth]="true"
                                 [type]="configGroup.get('type').value === 'input' ? configGroup.get('input_type') : configGroup.get('type').value"
                                 [selectOptions]="configGroup.get('type').value === 'select' ? configGroup.get('available_options').value : []"
                                 selectLabel="label" selectValue="value">
                      </app-input>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
            </mat-tab-group>
            <!-- Submit-->
            <div class="col-12">
              <hr>
              <div class="d-flex justify-content-end">
                <app-spinner-btn class="ml-2 mt-1" type="submit" [loading]="isSubmitting"
                                 [name]="'SHARED.save' | translate">
                </app-spinner-btn>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
