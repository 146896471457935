export class Trigger {
  id?: number;
  on: string;
  status_id: number;
  status?: object;
  notification_id: number[];
  notification: object;
  receivers: {
    role: string;
    notification_to: string
  }[];
  role_id: number;
  role?: object;
  type: string;
  notification_to: string;
  days: number;
}
