<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    <h2>{{ "CASES.details.generate-proposal" | translate }}</h2>
    <a *ngIf="data.case.propuesta_modelo" mat-raised-button color="primary" target="_blank"
       href="{{storageUrl + data.case.propuesta_modelo.location}}" download>
      Download
    </a>
  </div>

  <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="formActive">
    <div class="form-group">
      <label for="total_income">{{"CASES.single.total-income" | translate}}</label>
      <input type="number" class="form-control" id="total_income" formControlName="total_income">
      <div *ngIf="form.get('total_income').touched && form.get('total_income').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="debt_level">{{"CASES.single.debt-level" | translate}}</label>
      <input type="number" class="form-control" id="debt_level" formControlName="debt_level">
      <div *ngIf="form.get('debt_level').touched && form.get('debt_level').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="total_expenses">{{"CASES.single.total-expenses" | translate}}</label>
      <input type="number" class="form-control" id="total_expenses" formControlName="total_expenses">
      <div *ngIf="form.get('total_expenses').touched && form.get('total_expenses').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="total_assets">{{"CASES.single.total-assets" | translate}}</label>
      <input type="number" class="form-control" id="total_assets" formControlName="total_assets">
      <div *ngIf="form.get('total_assets').touched && form.get('total_assets').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="disposable_income">{{ 'CASES.financial_overview.table.disposable_income' | translate }}</label>
      <input type="number" class="form-control" id="disposable_income" formControlName="disposable_income">
      <div *ngIf="form.get('disposable_income').touched && form.get('disposable_income').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="legal_fees">{{"CASES.single.legal-fees" | translate}}</label>
      <input type="number" class="form-control" id="legal_fees" formControlName="legal_fees">
      <div *ngIf="form.get('legal_fees').touched && form.get('legal_fees').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="offer_to_creditors">{{"CASES.details.offer-to-creditors" | translate}}</label>
      <input type="number" class="form-control" id="offer_to_creditors" formControlName="offer_to_creditors">
      <div *ngIf="form.get('offer_to_creditors').touched && form.get('offer_to_creditors').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="term">{{"CASES.details.term" | translate}}</label>
      <input type="number" class="form-control" id="term" formControlName="term">
      <div *ngIf="form.get('term').touched && form.get('term').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <button type="button" mat-stroked-button color="primary" (click)="stepPayment()" style="margin-bottom: 1.5rem">
      {{ (showStepPayment ? "CASES.details.step-payment-remove" : "CASES.details.step-payment-add") | translate }}
    </button>

    <div [@inOutAnimation] *ngIf="showStepPayment" class="form-group">
      <!--      <mat-divider></mat-divider>-->
      <div class="form-group">
        <label for="step_period">{{"CASES.details.step-payment-month" | translate}}</label>
        <input type="number" class="form-control" id="step_period" formControlName="step_period">
      </div>

      <div class="form-group">
        <label for="step_offer">{{"CASES.details.step-payment-offer" | translate}}</label>
        <input type="number" class="form-control" id="step_offer" formControlName="step_offer">
      </div>
      <mat-divider></mat-divider>
    </div>

    <div>
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "CASES.details.payment-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="paymentDate" formControlName="payment_date">
        <mat-datepicker-toggle matSuffix [for]="paymentDate"></mat-datepicker-toggle>
        <mat-datepicker #paymentDate color="primary"></mat-datepicker>
      </mat-form-field>
      <div *ngIf="form.get('payment_date').touched && form.get('payment_date').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="dividend">{{"CASES.details.dividend" | translate}} %</label>
      <input type="number" class="form-control" id="dividend" formControlName="dividend">
      <div *ngIf="form.get('dividend').touched && form.get('dividend').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group">
      <label for="creditor_payment_account">
        {{"CASES.details.creditor_payment_account" | translate}}
      </label>
      <input type="text" class="form-control" id="creditor_payment_account" formControlName="creditor_payment_account">
    </div>

    <div class="form-group">
      <label for="payment_reference">{{"CASES.details.payment_reference" | translate}}</label>
      <input type="text" class="form-control" id="payment_reference" formControlName="payment_reference">
    </div>

  </form>

  <div class="text-right mt-5 pb-5 d-flex justify-content-end align-items-center">

    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeModal(null, true)" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="submitForm()">
      {{"SHARED.generate" | translate}}
    </button>
    <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
  </div>
</div>
