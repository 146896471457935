import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector:    'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls:   ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  public userId;
  constructor( private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
  }

}
