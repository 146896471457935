import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-distribution',
  templateUrl: './distribution.component.html',
  styles:      [],
})
export class DistributionComponent implements OnInit {
  public authUser: User;
  public componentType: 'distribution' | 'admin';

  constructor(private globalEventService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => {
      if (user) {
        this.authUser      = user;
        this.componentType = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
      }
    });
  }

}
