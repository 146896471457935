import { NgModule } from '@angular/core';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { SharedModule } from '../../../_shared/shared.module';
import { SmsEditorComponent } from './sms-editor/sms-editor.component';
import { SmsListComponent } from './sms-list/sms-list.component';
import { SmsRoutingModule } from './sms-routing.module';

@NgModule({
  declarations: [SmsListComponent, SmsEditorComponent],
  imports:      [
    SharedModule,
    MatPaginatorModule,
    SmsRoutingModule,
  ],
})
export class SmsModule {
}
