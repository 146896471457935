import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-connections',
  templateUrl: './user-connections.component.html',
  styles: [
  ]
})
export class UserConnectionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
