import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector:    'app-mymoid-moto-iframe-redirect',
  templateUrl: './mymoid-moto-iframe-redirect.component.html',
  styles:      [],
})
export class MymoidMotoIFrameRedirectComponent implements OnInit {
  private appUrl: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.appUrl = environment.APP_URL;

    if (!!this.route.snapshot.data.success) {
      window.location.href = this.appUrl + '/authorization-callbacks/mymoid-moto/success';
    } else {
      window.location.href = this.appUrl + '/authorization-callbacks/mymoid-moto/error';
    }
  }

}
