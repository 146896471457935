import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { TeamListComponent } from './team-list/team-list.component';
import { TeamRoutingModule } from './team-routing.module';
import { TeamEditorComponent } from './team-editor/team-editor.component';

@NgModule({
  imports:      [
    SharedModule,
    TeamRoutingModule,
  ],
  declarations: [
    TeamListComponent,
    TeamEditorComponent,
  ],
})
export class TeamModule {
}
