import { Case } from '../Case/Case';
import { Department } from '../Department/Department';
import { PaymentStatus } from '../Status/PaymentStatus';
import { Status } from '../Status/Status';
import { User } from '../User/User';
import { Task } from './Task';
import { TemplateNotification } from './TemplateNotification';

export class TaskTemplate {
  id?: number;
  author_id: number;
  case_id?: number;
  statusable_type: 'status' | 'payment_status';
  statusable_id: number;

  name: string;
  notes?: string;
  active: boolean | 0 | 1;
  user_assisted: boolean | 0 | 1;
  force_task_creation: boolean | 0 | 1;
  require_completion_note: boolean | 0 | 1;
  delay: number;
  delay_unit: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month';
  notify_on_completion_via: Array<string>;

  created_at: string | Date;
  updated_at: string | Date;

  author?: User;
  watch_case?: Case;
  statusable?: Status | PaymentStatus;
  assigned_users?: Array<User>;
  assigned_departments?: Array<Department>;
  template_notifications?: Array<TemplateNotification>;
  authored_tasks?: Array<Task>;
  tasks?: Array<Task>;

  // Helpers
  assigned_users_ids?: Array<number>;
  assigned_departments_ids?: Array<number>;
}
