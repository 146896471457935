import { Component, Input, OnInit } from '@angular/core';
import { CaseIncome } from '../../../../../../../_base-shared/models/Case/CaseIncome';

@Component({
  selector: 'app-creditor-snapshot-list',
  templateUrl: './creditor-snapshot-list.component.html',
  styleUrls: ['./creditor-snapshot-list.component.scss']
})
export class CreditorSnapshotListComponent implements OnInit{
  @Input() creditors: any;
  @Input() publicDebts: any;
  public securedCreditors: any;
  public unsecuredCreditors: any;
  public claimCreditors: any;
  public displayedColumns: string[] = [];

  ngOnInit(): void {
    if (this.creditors){
      this.securedCreditors = this.creditors.secured;
      this.unsecuredCreditors = this.creditors.unsecured;
      this.claimCreditors = this.creditors.claims;
    }
    this.buildDisplayColumns();
  }

  private buildDisplayColumns(): void {
    this.displayedColumns = [
      'name',
      'ownership',
      'debt_type',
      'initial_balance',
      'current_balance',
      'monthly_payments',
      'reference_number',
      'response_received',
      'additional_partner',
      'making_payments',
      'azcarate'
    ];
    }
}

