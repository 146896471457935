<form [formGroup]="form">

  <h4 class="modal-title">{{ "PAYMENTS.change-payment-value" | translate }}</h4>
  <div class="form-group">
    <label for="new_value" class="pl-2">{{ "PAYMENTS.change-value-new" | translate }}</label>
    <div class="pb-3">
      <input formControlName="newValue" id="new_value" type="number" class="form-control">
      <mat-error
        *ngIf="(form.get('newValue').touched && !form.get('newValue').errors?.required) &&
             (form.get('newValue').invalid) "
        class="pl-2">
        {{"CASES.single.charge-amount-invalid-1" | translate}} {{data.term.amount_paid}}€
        {{"SHARED.and" | translate}} 5000€
      </mat-error>
      <mat-error *ngIf="form.get('newValue').touched && form.get('newValue').errors?.required">
        {{"SHARED.field-required" | translate}}
      </mat-error>
    </div>
    <div>
      <label>{{ "PAYMENTS.change-value-diff" | translate }}</label>
    </div>
    <mat-radio-group formControlName="split" aria-label="Add difference to:">
      <div>
        <mat-radio-button
          class="mr-3"
          value="insert_payment">{{ "PAYMENTS.insert-installment" | translate }}
        </mat-radio-button>
      </div>
      <div>
        <mat-radio-button
          class="mr-3"
          value="next_payment">{{ "PAYMENTS.next-payment" | translate }}
        </mat-radio-button>
      </div>
      <div>
        <mat-radio-button *ngIf="data.term.type === 'phase_one'" style="margin-right: 20px" value="add_to_end">{{ "PAYMENTS.last-payment" | translate }}</mat-radio-button>
        <mat-radio-button value="add_to_end_phase_one">{{ "PAYMENTS.last-payment-phase-one" | translate }}</mat-radio-button>
      </div>
      <div>
        <mat-radio-button style="margin-right: 20px" value="all_payments">{{ "PAYMENTS.split-payment" | translate }}</mat-radio-button>
        <mat-radio-button value="all_payments_phase_one">{{ "PAYMENTS.split-payment-phase-one" | translate }}</mat-radio-button>
      </div>
    </mat-radio-group>
    <div class="pt-3">
      <label class=" pl-2 pt-2" for="note">{{'CASES.single.note' | translate}}</label>
      <quill-editor
        id="note"
        formControlName="note"
        [modules]="quillModules"
        [styles]="{height: '150px'}">
      </quill-editor>
      <mat-error *ngIf="form.get('note').touched && form.get('note').invalid">
        {{'SHARED.field-required' | translate}}
      </mat-error>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="onNoClick()">{{ "SHARED.close" | translate }}</button>
    <button *ngIf="!isLoading" type="submit" mat-raised-button color="primary"
            [disabled]="!form.value.newValue"
            (click)="changeValue()">
      {{ "PAYMENTS.change-value" | translate }}
    </button>
    <div class="d-flex justify-content-center" *ngIf="isLoading" style="width: 131px; margin: 0">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>
</form>
