import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../../../../../_base-shared/models/User/User';

@Component({
  selector: 'app-client-snapshot-list',
  templateUrl: './client-snapshot-list.component.html',
  styleUrls: ['./client-snapshot-list.component.scss']
})
export class ClientSnapshotListComponent implements OnInit{
  @Input() clientSnapshot: User;
  @Input() clientRole: 'client' | 'partner';
  constructor() {
  }

  ngOnInit(): void {
  }
}
