import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path:     'packager',
    children: [
      // {path: 'login', component: RedirectToClientAppComponent, data: {routeUrl: 'auth/login'}}
      // {path: ':id/configurations', component: PackagerConfigurationComponent}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PackagerRoutingModule {
}
