import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector:    'app-button',
  templateUrl: './app-button.component.html',
  styles:      [],
})
export class AppButtonComponent implements OnInit {
  @Input() buttonText: string;
  @Input() stylesObj;
  @Input() buttonClasses: string;
  @Input() isLoading: number;
  @Output() buttonClickedEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  buttonClicked($event) {
    this.buttonClickedEvent.emit($event);
  }

}
