import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { FinancialOverview } from '../../../../../_base-shared/models/Payment/FinancialOverview';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class FinancialOverviewService extends MainBaseApiService {
  private financialOverviewSource = new BehaviorSubject<FinancialOverview>(null);
  public financialOverview$       = this.financialOverviewSource.asObservable();

  public updateFinancialOverviewLocally(overview: FinancialOverview) {
    this.financialOverviewSource.next(overview);
  }

  public fetchLatest(caseId: number) {
    return this.http.get<LaravelResourceResponse<FinancialOverview>>(
        this.apiUrl + '/cases/' + caseId + '/financial-overview',
    ).pipe(
        tap(response => this.updateFinancialOverviewLocally(response.data)),
        catchError(response => this.handleError(response)),
    );
  }
}
