import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MiscConfigComponent } from './misc-config.component';

const routes: Routes = [
  {
    path: 'misc-config',
    children: [
      {path: '', component: MiscConfigComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class MiscConfigRoutingModule {
}
