import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinner } from '@angular/material/legacy-progress-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../_shared/shared.module';
import {
  PackagerConfigurationComponent
} from './packager-detail/packager-configuration/packager-configuration.component';
import { PackagerDetailComponent } from './packager-detail/packager-detail.component';
import {
  PackagerGeneralEditorComponent
} from './packager-detail/packager-general-editor/packager-general-editor.component';
import { PackagerRoutingModule } from './packager-routing.module';


@NgModule({
  imports: [
    SharedModule,
    PackagerRoutingModule,
    MatLegacyAutocompleteModule
  ],
  declarations: [
    PackagerDetailComponent,
    PackagerConfigurationComponent,
    PackagerGeneralEditorComponent
  ]
})
export class PackagerModule {
}
