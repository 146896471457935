import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { AppFileHistorable } from '../../../../../../../../../_base-shared/models/File/AppFileHistorable';
import { CallHistory } from '../../../../../../../../../_base-shared/models/Notification/CallHistory';
import { EmailHistory, EmailHistoryRecipientType } from '../../../../../../../../../_base-shared/models/Notification/EmailHistory';
import { NotificationChannelSlug } from '../../../../../../../../../_base-shared/models/Notification/NotificationChannel';
import { SmsHistory } from '../../../../../../../../../_base-shared/models/Notification/SmsHistory';
import { MainBaseApiService } from '../../../../../../_shared/services/main-base-api.service';
import { AppFileService } from '../../../../../app-file/app-file.service';
import { NotificationService } from '../../../../../notification/notification.service';
import { NoteService } from '../../../../note.service';

type tabsType = Array<{ typeGroup: string; label: string; filter_types: Array<EmailHistoryRecipientType> }>;

@Component({
  selector:    'app-case-notification-list',
  templateUrl: './case-notification-list.component.html',
  styleUrls:   ['./case-notification-list.component.scss'],
})
export class CaseNotificationListComponent implements OnInit {
  @Input() case: Case;
  @Input() channel: NotificationChannelSlug;

  public notifications: Array<EmailHistory | SmsHistory | CallHistory> = [];
  public isLoading                                                     = 0;
  public tabs: tabsType                                                = [];
  public selectedTabIndex                                              = 0;
  private notificationRelations: Array<string>                         = [];

  constructor(private noteService: NoteService,
              private tr: TranslateService,
              private notificationService: NotificationService,
              private appFileService: AppFileService) {
  }

  ngOnInit(): void {
    this.buildTabs();
    this.selectTab(this.selectedTabIndex);
  }

  public tabChange($event) {
    this.selectedTabIndex = $event.index;
    this.selectTab($event.index);
  }

  private selectTab(index: number) {
    const selectedTab  = this.tabs[index];
    this.notifications = [];
    this.getNotifications(this.channel, selectedTab.filter_types, this.notificationRelations);
  }

  public downloadAttachment(attachment: AppFileHistorable) {
    const requestData = {
      app_file_id: attachment.app_file_id,
      disk:        attachment.disk,
      path:        attachment.path,
    };
    this.appFileService.download(requestData).subscribe(
        result => saveAs(result.body, attachment.name + '.' + attachment.extension),
        error => console.log(error),
    );
  }

  private getNotifications(channel: NotificationChannelSlug,
                           filterTypes: Array<EmailHistoryRecipientType>,
                           relations: Array<string>) {
    const data = MainBaseApiService.convertFiltersForRequest({
      channel,
      recipient_types: filterTypes,
      with:            relations,
      select_all:      1,
    }, 'get');

    this.isLoading++;
    this.notificationService.indexCaseNotifications(this.case.id, data).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.notifications = result.data;
        });
  }

  private buildTabs() {
    if (this.channel === 'email') {
      this.notificationRelations = ['author', 'attachments'];
      this.tabs                  = [
        {
          typeGroup:    'client',
          label:        this.tr.instant('CASES.single.general.relation_list.notification_list.recipient_type.client'),
          filter_types: ['client', 'client-gmail'],
        },
        {
          typeGroup:    'creditor',
          label:        this.tr.instant('CASES.single.general.relation_list.notification_list.recipient_type.creditor'),
          filter_types: ['creditor'],
        },
        {
          typeGroup:    'administrator',
          label:        this.tr.instant('CASES.single.general.relation_list.notification_list.recipient_type.mediator'),
          filter_types: ['administrator'],
        },
        {
          typeGroup:    'all',
          label:        this.tr.instant('CASES.single.general.relation_list.notification_list.recipient_type.all'),
          filter_types: [],
        },
      ];
    }
    if (this.channel === 'sms') {
      this.notificationRelations = ['author'];
      this.tabs                  = [
        {
          typeGroup:    'client',
          label:        this.tr.instant('CASES.single.general.relation_list.notification_list.recipient_type.client'),
          filter_types: ['client'],
        },
      ];
    }
    if (this.channel === 'call') {
      this.notificationRelations = ['notification_history.staff'];
      this.tabs                  = [
        {
          typeGroup:    'client',
          label:        this.tr.instant('CASES.single.general.relation_list.notification_list.recipient_type.client'),
          filter_types: [],
        },
      ];
    }
  }
}
